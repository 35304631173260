import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { refreshService } from '../../../services/refersh.service'
import { Constants } from "../../../../config/constants";

import { PatientService } from '../../../services/patient.service';
import { NewOnboardPatientComponent } from '../new-onboard-patient/new-onboard-patient.component';
import { MatDialog } from '@angular/material/dialog';
import { NavigationServiceService } from '../../../services/navigation-service.service';
// import {MatMenuTrigger} from '@angular/material/MatMenuTrigger'
@Component({
  selector: 'app-top-info-navbar',
  templateUrl: './top-info-navbar.component.html',
  styleUrls: ['./top-info-navbar.component.css']
})




export class TopInfoNavbarComponent implements OnInit {
  @Input() callBack: (args: any) => void;
  @Input() patientResponse: any
  @ViewChild(MatMenuTrigger) menuForPatientInfo: MatMenuTrigger

  @ViewChild(MatMenuTrigger) triggerBtn: MatMenuTrigger;
  locality: any
  xpandStatus = false;
  first_name: any = ""
  middle_name: any = ""
  last_name: any = ""
  fcmToken: any = false;
  profilePhoto: any = '';
  gender: any = ""
  patientDataFromLocal
  ageYears: any = ""
  ageMonths: any = ""
  ageWeeks: any = ""
  mobileNumber: any = ""
  visit = false
  ageObj: any = {};
  showAgeYear = false;
  vitalsFlag = false
  appointmentVisit = false
  profilePic: any;
  actionFlag = ""
  patientId: any = "";
  parmpatientId:any ="";
  additionalInfoForPatient: any = ""
  medicalConditions: string = ""
  familyMedicalConditions: string = ""
  allergies: string = ""
  allPatientInfo: any
  recurringComplaints: string = ""
  accidentalHistory: string = ""
  // selectedIndex = null;
  showLoader: boolean = true;
  medicalConditionArray: any = []
  above
  patientData: any = {};
  @Input() parentForm: String;
  @Input() rxUi
  @Input() selectedIndex: any;
  @Input() appointmentId:any
  @ViewChild('moreInfoTrigger') moreInfoTrigger: MatMenuTrigger;
  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;
  profileImagePath = Constants.APICONFIG.patientProfileImagePath
  visitIdFromEditText: any;
  appointment_id:any

  constructor(private navService: NavigationServiceService, private route: ActivatedRoute, public dialog: MatDialog, private refreshservice: refreshService, private patientService: PatientService, private router: Router) { }

  ngOnInit(): void {
    // if(this.appointmentId){
    //   this.appointment_id=this.appointmentId
    // }
    console.log("ngOnInit  called by clicking overview",this.appointment_id);
    // Retrieve the selectedIndex parameter from the query params

    this.patientDataFromLocal = JSON.parse(localStorage.getItem("patientData"));
    console.log("patientDataFromLocal",this.patientDataFromLocal);
    
    this.route.queryParams.subscribe(params => {
      const selectedIndex = params['selectedIndex'];
      console.log("params by clking overwinew ", params);
      this.patientId = params.patientId
      this.appointment_id=params.appointment_id
      this.parmpatientId= params.patientId
      console.log("params.patientId",params.patientId );
      this.getPatientInfo();
      if (selectedIndex === '4') {
        this.selectedIndex = 4;

        console.log("Selected index is 4.");
      } else {

        console.log("Selected index is not 4.");
      }
    });
    console.log("111112222222321", this.selectedIndex);


    this.refreshservice.Patient_History.subscribe(valuesent => {
      console.log("GOT REFRESH EVENT hdhsjdhsjd", valuesent);
      this.patientId = valuesent
      this.getPatientInfo();
      //  this.getAllDoctorPrescriptions()


    });



    this.refreshservice.ProfileRefreshEvent.subscribe(valuesent => {
      console.log("GOT REFRESH EVENT", valuesent);
      this.patientId = valuesent
      this.getPatientInfo();





    })


    //  this.openMoreInformation();
    //this.adjustersMenuTrigger.openMenu()selectmedicalConditions

    // this.patientData = {}
    this.refreshservice.topNav_Menu.subscribe(valuesent => {
      console.log("GOT show RX EVENTtttt---", valuesent);
      switch (valuesent.EventName) {
        case this.refreshservice.EventName_OverviewScreen:
          this.openOverview();
          break;
        case this.refreshservice.EventName_RxScreen:
          this.rxClicked();

          //   this.router.navigate([`/doctor/patients/quickprescription/view`], { queryParams: { patientId: this.patientData.id } });
          break;
        case this.refreshservice.EventName_Vitals:
          this.actionFlag = JSON.parse(localStorage.getItem('action'))
          this.visit = true
          this.openVitals();
          break;
        case this.refreshservice.EventName_appointmentVital:

          this.appointmentVisit = true
          this.openVitals();
          break;

      }


    })


    this.patientData = JSON.parse(localStorage.getItem("patientData"));

    console.log("parentForm", this.patientData);
    this.first_name = this.patientData.User && this.patientData.User.first_name ? this.patientData.User.first_name : this.patientData.firstName;
    this.middle_name = this.patientData.User && this.patientData.User.middle_name ? this.patientData.User.middle_name : this.patientData.middleName
    this.last_name = this.patientData.User && this.patientData.User.last_name ? this.patientData.User.last_name : this.patientData.lastName
    this.locality = this.patientData.User.locality ? this.patientData.User.locality : ""
    this.mobileNumber = this.patientData.User && this.patientData.User.phone_number ? this.patientData.User.phone_number : this.patientData.contactNo;
    this.fcmToken = this.patientData && this.patientData.User && this.patientData.User.Customer && this.patientData.User.Customer.fcmtoken ? true : false
    this.profilePhoto = this.patientData.User && this.patientData.User.profile_photo ? this.patientData.User.profile_photo : ''


    this.gender = this.patientData.User.gender.toLowerCase() === 'male' ? 'M' : "F";

    this.getAge();

    setTimeout(() => {
      this.getPatientInfo();
    }, 500)





    //this.openMenuMoreInfo();
    this.getAllRequiredConstants();
    if (this.parentForm == "QRX") {
      setTimeout(() => {
        this.openPatientInfo();
      }, 1000)
    }



    
    this.refreshservice.openVitals$.subscribe(() => {
      this.openVitals();
      console.log("refreshservice called");
      
    })

  }
 

  menuOpened() {
    console.log("1jan 2023");
    //this.moreInfoTrigger.openMenu();
  }


  ngOnDestroy() {
    this.patientData = {};
  }

  toggleSideNav() {
    this.navService.setShowNav(true);
  }

  openDialog(content, index) {


    this.dialog.open(content);
  }

  closeDialog() {

    this.dialog.closeAll();
  }

  getPatientInfo = () => {
    console.log("getpatientinfo called by clicking overview", this.patientId ,this.patientData);
    this.patientDataFromLocal = JSON.parse(localStorage.getItem("patientData"));
var patientID = this.patientDataFromLocal.id;
console.log("patientID 4444", patientID, this.parmpatientId);
console.log("");

    this.patientService.getPatientInfo(this.parmpatientId ? this.parmpatientId : patientID).subscribe(response => {
      if (response) {
        this.allPatientInfo = response
        console.log("2Jul", this.allPatientInfo)
        var obj = {};
        var patientInfo: any = response
        this.ageObj = response.age;
        this.locality = patientInfo.locality ? patientInfo.locality : ""
        this.first_name = patientInfo.firstName ? patientInfo.firstName : ""
        this.last_name = patientInfo.lastName ? patientInfo.lastName : "";
        this.mobileNumber = patientInfo.contactNo ? patientInfo.contactNo : ""
        this.gender = patientInfo.gender.toLowerCase() === 'male' ? 'M' : "F";

        this.medicalConditions = patientInfo && patientInfo.affected_comment ? patientInfo.affected_comment : '';
        this.familyMedicalConditions = patientInfo && patientInfo.familyHistory ? patientInfo.familyHistory : '';
        this.allergies = patientInfo && patientInfo.allergies ? patientInfo.allergies : '';
        this.recurringComplaints = patientInfo && patientInfo.comment ? patientInfo.comment : '';
        this.accidentalHistory = patientInfo && patientInfo.accidentalHistory ? patientInfo.accidentalHistory : '';
        this.additionalInfoForPatient = patientInfo && patientInfo.additionalInfo && patientInfo.additionalInfo ? JSON.parse(patientInfo.additionalInfo) : {};
        obj['phone_number'] = patientInfo.contactNo
        obj['spousecontactno'] = patientInfo.spousecontactno
        if (patientInfo && patientInfo.parent.fcmtoken) {
          obj['fcmtoken'] = patientInfo.parent.fcmtoken.substring(0, 5);
        }


        // this.patientData['User']['Customer'] = obj
        console.log("fcmdate", this.patientData)


      }
    })

  }

  getAge() {
    this.patientData["age"] = {};
    var date = this.reverseDate(this.patientData.User.dob);
    const { day, month, year } = date;
    let bdate = new Date();
    bdate.setMonth(month - 1);
    bdate.setDate(day);
    // 0
    bdate.setFullYear(year);
    // let age = this.calculateAge(new Date(), bdate);
    // this.patientData.User["age"]["years"] = age[0];
    // this.patientData.User["age"]["months"] = age[1];
    // this.patientData.User["age"]["weeks"] = Math.floor(age[2] / 7);
  }
  reverseDate(date) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }
  calculateAge(endingDate, startingDate) {
    var startDate = new Date(
      new Date(startingDate).toISOString().substr(0, 10)
    );
    if (!endingDate) {
      endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
    }
    var endDate = new Date(endingDate);
    if (startDate > endDate) {
      var swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    var startYear = startDate.getFullYear();
    var february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
        ? 29
        : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    var yearDiff = endDate.getFullYear() - startYear;
    var monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    var dayDiff = endDate.getDate() - startDate.getDate();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }
    this.ageYears = yearDiff
    console.log("age", this.ageYears)
    this.ageMonths = monthDiff
    this.ageWeeks = dayDiff
    return [yearDiff, monthDiff, dayDiff];
  }


  mouseEnterForMoreInfo = (index) => {
    this.trigger.toArray()[1].closeMenu()
    //this.trigger.toArray()[index - 1].openMenu();
  }


  mouseEnter = (index) => {
    // alert('Triggered');
    // this.trigger.openMenu();

    //   this.trigger.toArray()[0].closeMenu()
    //   this.trigger.toArray()[1].closeMenu()
    //   this.trigger.toArray()[2].closeMenu()

    //  this.trigger.toArray()[3].closeMenu()


    this.trigger.toArray()[index - 2].openMenu();





  }

  openTabMenu = (index) => {
    let ind = this.parentForm == 'QRX' ? index + 1 : index
    this.trigger.toArray()[0].closeMenu()
    this.trigger.toArray()[1].closeMenu()
    this.trigger.toArray()[2].closeMenu()
    if (this.parentForm == 'QRX') {
      this.trigger.toArray()[3].closeMenu()
    }
    this.trigger.toArray()[ind].openMenu()
  }

  openMoreInformation() {
    var index = -1

    if (this.allergies || this.medicalConditions || this.accidentalHistory || this.additionalInfoForPatient) {
      let ind = this.parentForm == 'QRX' ? index + 1 : index
      this.trigger.toArray()[ind].openMenu()

    }


  }

  closeTabMenu = (index) => {
    let ind = this.parentForm == 'QRX' ? index + 1 : index
    this.trigger.toArray()[ind].closeMenu()
  }

  navigateToOverview() {
console.log(" navigateToOverview fired");

    localStorage.setItem('patientData', JSON.stringify(this.patientData));
    this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.patientData.id? this.patientData.id: this.allPatientInfo._id,appointment_id:this.appointment_id  } })
  }

  rxClicked() {
    var patientID = this.patientDataFromLocal.id
    this.selectedIndex = 0;
    if (this.rxUi == 'NewRxUI') {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_Rx);
      localStorage.setItem('patientData', JSON.stringify(this.patientData));
      // this.router.navigate([`/doctor/patients/quickprescription/view`], { queryParams: { patientId: this.patientData.id } });
      this.router.navigate([`/newrx/rxHomePage`], { queryParams: { patientId: this.patientId? this.patientId:patientID} });
      console.log("his.router.navigate if");
      
    }
    else {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_Rx);
      localStorage.setItem('patientData', JSON.stringify(this.patientData));
      this.router.navigate([`/doctor/patients/quickprescription/view`], { queryParams: { patientId: this.patientData.Id? this.patientData.Id:patientID,appointment_id:this.appointment_id ? this.appointment_id:this.appointmentId } });
      console.log("his.router.navigate else");
      
      //this.router.navigate([`/newrx/rxHomePage`], { queryParams: { patientId: this.patientData.id} });
    }



  }

  openOverview() {
    this.selectedIndex = 1;
    this.navigateToOverview();
    this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_Overview);
    setTimeout(() => {
      this.selectedIndex = 1;
    }, 400);
  }

  //  --- Investigations ---//
  investigationClicked() {
    // this.selectedIndex = 2;
  }

  openRequisitions() {
    this.navigateToOverview();
    this.selectedIndex = 2;

    setTimeout(() => {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_Requisitions);
    }, 200);

  }
  public openDocuments() {
    //this.selectedIndex = 4;
    this.navigateToOverview();
    setTimeout(() => {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_AllDocuments);
    }, 500)

  }

  openReports() {
    this.navigateToOverview();
    this.selectedIndex = 2;
    setTimeout(() => {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_Reports);
    }, 500);

  }

  //  --- Health Profile ---//

  openVitals() {
    this.vitalsFlag = true;
    this.navigateToOverview();
    this.selectedIndex = 3;
    setTimeout(() => {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_Visits);
    }, 500);
  }

  openImmunization() {
    this.showLoader = true;
    this.selectedIndex = 3;
    this.navigateToOverview();
    setTimeout(() => {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_Immunization);
      console.log("Runnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn");

    }, 500);
  }

  openMilestone() {
    this.selectedIndex = 3;
    this.navigateToOverview();
    setTimeout(() => {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_Milestone);
    }, 500);
  }


  //  --- Documents ---//

  openReferral() {
    this.selectedIndex = 4;
    this.navigateToOverview();
    setTimeout(() => {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_Referral);
    }, 500);
  }
  openCertificate() {
    this.selectedIndex = 4;
    this.navigateToOverview();
    setTimeout(() => {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_Certificate);
    }, 500);
  }

  openReceipt() {
    this.selectedIndex = 4;
    this.navigateToOverview();
    setTimeout(() => {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_Receipt);
    }, 500);
  }
  openNotes() {
    this.selectedIndex = 4;
    this.navigateToOverview();
    setTimeout(() => {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_Notes);
    }, 500);

  }
  editPatient() {
    console.log("allPatientInfo",this.allPatientInfo);
    if(this.allPatientInfo.visits && this.allPatientInfo.visits.length>0){
    this.visitIdFromEditText= this.allPatientInfo.visits[0]._id;

    }
console.log("visitIdFromEditText",this.visitIdFromEditText);
this.refreshservice.setFlag(this.visitIdFromEditText);
    const dialogRef = this.dialog.open(NewOnboardPatientComponent, {

      height: 'fit-content',
      width: '75%',
      //  data: this.patientData 

    });
    localStorage.setItem("allPatientInfo", JSON.stringify(this.allPatientInfo))

    localStorage.setItem("patientHistory", JSON.stringify(this.patientData))
    //this.router.navigate([`/doctor/patients/patientOnboard`])
  }

  // this.router.navigate([`/doctor/patients/patientOnboard`], { state: { data: this.patientData } });

  //}

  openUpload = () => {
    console.log("Open Upload called")
    this.selectedIndex = 4;
    this.navigateToOverview();
    setTimeout(() => {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_Upload);
    }, 500);
  }
  openMenuMoreInfo() {
    this.openTabMenu(3)
    console.log("Dec29")
  }


  // Appointmentclicked() {
  //   this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_Appointment);

  // }
  // Visitsclicked() {
  //   this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_Visits);
  // }

  // Overviewclicked() {
  //   this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_Overview);
  // }


  saveMoreInfo() {
    var postData: any = {};
    postData.allergies = this.allergies;
    postData.affected_comment = this.medicalConditions;
    postData.comment = this.recurringComplaints;
    postData.accidentalHistory = this.accidentalHistory;
    // postData.familyHistory = this.familyMedicalConditions;
    this.patientService.updatePatientMoreInfo(postData, this.patientData.id).subscribe(response => {
      if (response) {

        // this.getPatientInfo('Overview');
      }
    })
  }

  getAllRequiredConstants = () => {
    let newArray = [];
    this.patientService.getAllRequiredConstants('').subscribe(response => {

      if (response) {
        this.medicalConditionArray = response.data.medicalConditions.map(ins => { return { name: ins.name, selected: false } });

      }
    })
  }

  selectmedicalConditions(item) {
    if (this.medicalConditions) {
      this.medicalConditions = this.medicalConditions + "," + " " + item.name
    }
    else {
      this.medicalConditions = item.name
    }

  }

  openPatientInfo() {
    var test = this.additionalInfoForPatient.highRisk
    console.log("jan", test)
    if (this.allergies != "" || this.medicalConditions != "" || this.additionalInfoForPatient.highRisk != undefined || this.additionalInfoForPatient.reactionToMeds != "" || this.additionalInfoForPatient.geneticDisorders != "") {

      this.menuForPatientInfo.openMenu();





    }

  }
}

