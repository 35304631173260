<!-- <button class="close" mat-button (click)="closeDialog()">X</button> -->

<mat-horizontal-stepper [@.disabled]="true" (selectionChange)="setIndex($event)" #stepper linear>

  <mat-step *ngFor="let step of steps">
    <ng-template matStepLabel>{{step.name}}</ng-template>
    <form [formGroup]="profileForm">
      <div
        *ngIf="!antenetalBirthFlag && !healthProfileFlag && !familyProfilePed && !healthHistoryUi  && !FamilyProfileAdultFlag"
        style="display: flex;flex-direction: column;justify-content: center; width: 100%; margin-top: 10px;">
        <div style="display:flex;flex-direction: row; width: 100%; justify-content: space-between;">
          <div style="display: flex;flex-direction: row; justify-content: space-evenly; width: 50%;">
            <mat-form-field style="width: 10%;">
              <mat-label>Country</mat-label>
              <input matInput formControlName="countryCode" readonly>

            </mat-form-field>
            <mat-form-field style="width: 60%;">
              <mat-label>Mobile Number</mat-label>
              <input matInput placeholder="Enter Mobile Number" (keypress)="validateNumber($event)" [readonly]="isEdit"
                maxlength="10" formControlName="mobileNumber">
            </mat-form-field>
            <div *ngIf="profileForm.get('mobileNumber').value.length < 10 && !isOTPConfirmed "
              style=" margin: 10px 0px;" [hidden]="isEdit">
              <button mat-raised-button disabled>Confirm</button>
            </div>
            <div *ngIf="profileForm.get('mobileNumber').value.length === 10 && !isOTPConfirmed "
              (click)="findPatientByName();" style="margin: 10px 0px;" [hidden]="isEdit">
              <button mat-raised-button color="primary">Confirm</button>
            </div>
            <div *ngIf="isOTPConfirmed " style="margin: 10px 0px;" [hidden]="isEdit">
              <img style="margin-left: 0px;margin-top: 0px; " width="20" height="20"
                src="assets/images/tick_mark_color.svg" alt="" />
            </div>
          </div>
          <div style="display: flex;flex-direction: row;  width: 45%;">
            <mat-form-field style="width: 10%;">
              <mat-label>Country</mat-label>
              <input matInput formControlName="countryCode" readonly>

            </mat-form-field>
            <mat-form-field style="width: 70%; margin-left: 24px;">
              <mat-label>Second Mobile Number</mat-label>
              <input matInput placeholder="Enter Second Mobile Number" (keypress)="validateNumber($event)"
                maxlength="10" formControlName="secondNumber">
            </mat-form-field>
            <div></div>

          </div>


        </div>

        <div>


        </div>
        <div style="width: 100%; display: flex;flex-direction: row; justify-content: space-between;">
          <div style="width: 50%; display: flex;">
            <app-alertbox [title]="'Why Family\'s Mobile Number?'"
              [subtitle]="'You are adding the mobile number as Family\'s Registered Number(FRN) with Copious Care. While visiting doctor, patient will always be identified with this number'"
              [type]="'Warning'"></app-alertbox>
          </div>
          <div style="width: 50%; height: fit-content; display: flex; margin-left: 10px;">
            <app-alertbox [title]="'Why additinal Mobile Number?'"
              [subtitle]="'This is additional important number in the family. This is help number and not(FRN)'"
              [type]="'Warning'"></app-alertbox>
          </div>
        </div>
        <!-- <div _ngcontent-gpm-c366="" style="display: flex; flex-direction: row;width: 94%; justify-content: space-between;"><ngb-alert _ngcontent-gpm-c366="" role="alert" class="alert show alert-warning fade alert-dismissible ng-star-inserted" style="width: 48%; font-size: 12px; margin-top: 13px;"><strong _ngcontent-gpm-c366="">Why Family's Mobile Number?</strong><br _ngcontent-gpm-c366=""> You are adding the mobile number as Family's Registered. Number(FRN) with Copious Care.while Visiting doctor patient will always be identified with this numbe<button type="button" aria-label="Close" class="close ng-star-inserted"><span aria-hidden="true">×</span></button>
         </ngb-alert><ngb-alert _ngcontent-gpm-c366="" role="alert" class="alert show alert-warning fade alert-dismissible ng-star-inserted" style="width: 48%; font-size: 12px; margin-top: 13px;"><strong _ngcontent-gpm-c366="">Why additinal Mobile Number?</strong><br _ngcontent-gpm-c366=""> This is additional important number in the family. This is help number and not(FRN)<button type="button" aria-label="Close" class="close ng-star-inserted"><span aria-hidden="true">×</span></button>
        </ngb-alert></div> -->


        <div style="display:flex;flex-direction: row; justify-content: space-around; width: 100%; margin-top: 10px;">
          <mat-form-field style="width: 30%;">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="Enter First Name" maxlength="25" formControlName="firstname">
          </mat-form-field>
          <mat-form-field style="width: 30%;">
            <mat-label>Middle Name</mat-label>
            <input matInput placeholder="Enter Middle Name" maxlength="25" formControlName="middlename">
          </mat-form-field>

          <mat-form-field style="width: 30%;">
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="Enter Last Name" maxlength="25" formControlName="lastname">
          </mat-form-field>
        </div>



        <div style="display:flex;flex-direction: row;  width: 100%;">
          <div style="display: flex;flex-direction: row;justify-content:flex-start; width: 100%;">

            <mat-form-field *ngIf="showDate" [hidden]="isEdit || showDate" style="width: 30%; margin-left: 17px;">

              <input placeholder="Choose a Birth Date" matInput [matDatepicker]="picker" (click)="picker.open()"
                [max]="todayDate" [min]="dob" formControlName="dob" (ngModelChange)="getAge();">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>


            </mat-form-field>
           
            <mat-form-field *ngIf="!showDate" [hidden]="isEdit" style="width: 40%; margin-left: 17px;">

              <input placeholder="Choose a Birth Date" matInput [matDatepicker]="picker" (click)="picker.open()"
                [max]="todayDate" [min]="dob" formControlName="dob" (ngModelChange)="getAge();">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>


            </mat-form-field>



            <mat-form-field style="width: 38%;margin-left: 22px;">
              <mat-label>Age In Year</mat-label>
              <input [readonly]="isEdit" matInput placeholder="Enter Age" (keypress)="validateNumber($event)"
                maxlength="15" (ngModelChange)="addAge()" formControlName="ageYears">
            </mat-form-field>

          </div>
            
          <div style="width: 100%;" *ngFor="let item of genderArray; let i = index"
            (click)="selectModalValueGender(i, 'genderArry')" class="searchable-component-style"
            style="width: 120px;margin-left: 0px;margin-top: 12px;">
            <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
              class="tick-icon" />

            <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
              class="tick-icon" />

            <div>{{item.name}}</div>
          </div>

        </div>
        <p class="errors" *ngIf="errors.ageYears">{{errors.ageYears}}</p>
         <div style="display:flex;flex-direction: row; justify-content: space-around; width: 100%; margin-top: 10px; ">
          <mat-form-field style="width: 30%; " >
            <mat-label>Address</mat-label>
         
            <input matInput placeholder="Enter Address" formControlName="patientAddress">
          </mat-form-field>
          <mat-form-field style="width: 30%;" >
            <mat-label>Locality</mat-label>
            <input matInput placeholder="Enter Locality" formControlName="patientLocality">
          </mat-form-field>
          <mat-form-field style="width: 30%; "  >
            <mat-label>City</mat-label>
            <input matInput placeholder="Enter City" formControlName="patientCity">
          </mat-form-field>
 
        </div> 
        <div style="display:flex;flex-direction: row; justify-content: flex-start; width: 100%; margin-top: 10px; ">
                   <mat-form-field style="width: 30%; margin-left: 17px;" >
            <mat-label>Pincode</mat-label>
            <input matInput placeholder="Enter Pincode" (keypress)="validateNumber($event)" maxlength="6" formControlName="patientPincode">
          </mat-form-field>


          <mat-form-field style="width: 30%; margin-left: 25px;">
            <mat-label style="font-size: 15px;">Preferred Language</mat-label>
            <mat-select style="font-size: 16px;" [value]="patientSelectedLang"
              (selectionChange)="onDropdownSelectLanguage('language',$event.value)">
              <mat-option style="font-size: 16px;" *ngFor="let language of languageArray" [value]="language.name">
                {{language.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          </div>
     
        <div *ngIf="!isEdit" style="padding-top: 30px; display: flex; justify-content: flex-end;">
          <button (click)="closeDialog()" class="cancelUibtn">Cancel</button>

          <button *ngIf="!disabledButton" type="submit" (click)="onSubmitButton();" [disabled]="disabledButton" class="btnUi"> Next</button>
          <!-- <button *ngIf="disabledButton" type="submit"  class="btnUi"> Next</button> -->
          <button *ngIf="disabledButton" class="cancelUibtn">Next</button>
        </div>
        <!-- <p class="errors" *ngIf="errors.ageYears">{{errors.ageYears}}</p> -->
        <div *ngIf="isEdit" style="padding-top: 30px; display: flex; justify-content: flex-end;">
          <button (click)="closeDialog()" class="cancelUibtn">Cancel</button>

          <button type="submit" (click)="onSubmitButton()" class="btnUi"> Next</button>

        </div>

      </div>


      <!-- Antenetal & Birth History Ui-->
      <div
        *ngIf="antenetalBirthFlag && !profileUiFlag && !healthProfileFlag && !healthHistoryUi && !FamilyProfileAdultFlag"
        class="headingStyle">Antenetal & Birth History</div>
      <div
        *ngIf="antenetalBirthFlag && !profileUiFlag && !healthProfileFlag && !healthHistoryUi && !FamilyProfileAdultFlag"
        style="display: flex;flex-direction: column;justify-content: center; width: 100%; height: 100%;  margin-top: 10px;">


        <div style="display:flex;flex-direction: row; justify-content: space-around;">
          <mat-form-field style="width: 30%;">
            <mat-label>Date Of Birth</mat-label>
            <input matInput placeholder="Enter First Name" formControlName="dateOfBirth">
          </mat-form-field>
          <mat-form-field style="width: 30%;">

            <input placeholder="Expected DOB*" formControlName="eDob" matInput [matDatepicker]="picker" 
              (ngModelChange)="setExpectedBDateAndGestestionalAge();">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

          </mat-form-field>

          <mat-form-field style="width: 30%;">
            <mat-label>Delivery Type</mat-label>
            <mat-select formControlName="deliveryType" (selectionChange)="dropdownSelect('delivery',$event.value)">
              <mat-option *ngFor="let item of typeOfDeliveryArr" [value]="item">{{item}}</mat-option>
            </mat-select>

          </mat-form-field>


        </div>



        <div style="display:flex;flex-direction: row; justify-content: space-around; width: 100%;">

          <mat-form-field style="width: 30%;">
            <mat-label>Gestational Age</mat-label>
            <input matInput placeholder="Enter Age" formControlName="gestationalAge">
          </mat-form-field>

          <mat-form-field style="width: 30%;">
            <mat-label>High-Risk</mat-label>

            <input matInput formControlName="highRisk">
            <mat-checkbox [checked]="showHighRisk"
              style="display: flex;flex-direction: row;justify-content: flex-end;margin-top: -23px; width: 100%;"
              (change)="checkBoxValueChnage($event.checked)"></mat-checkbox>
          </mat-form-field>

          <mat-form-field style="width: 30%;">
            <mat-label>New Born Screening</mat-label>
            <input matInput placeholder="" formControlName="newBornScreening">
            <mat-checkbox [checked]="shownewBornscreen"
              style="display: flex;flex-direction: row;justify-content: flex-end;margin-top: -23px; width: 100%;"
              (change)="checkHeelPrickTest($event.checked)"></mat-checkbox>
          </mat-form-field>


        </div>
        <div style="display: flex; justify-content: center; color: #565353;">{{uploadTestValue}}</div>
        <div *ngIf="showHighRisk" style="display: flex;justify-content: center;">
          <div style="display: flex;flex-direction: row; width: 100%; justify-content: center;">
            <mat-form-field style="width: 30%; margin-left: 15px; margin-left: 15px; ">
              <mat-label>Add high-risk category</mat-label>
              <input matInput placeholder="High-Risk category" formControlName="highRiskType">
            </mat-form-field>
            <img (click)="open(highRiskTypeModal)" style="height: 30px; width: 30px; margin-top: 19px ;"
              src="assets/images/plus_button_new.svg" />
          </div>


        </div>
        <div class="lableUi">Vitals At Birth</div>


        <div style="display:flex;flex-direction: row; justify-content: space-around; width: 100%;">

          <mat-form-field style="width: 30%;">
            <mat-label>Weight(kg)</mat-label>
            <input matInput placeholder="Enter Weight" (keypress)="validateNumber($event)" maxlength="5"
              formControlName="birth_weight">
          </mat-form-field>

          <mat-form-field style="width: 30%;">
            <mat-label>Height(cm)</mat-label>
            <input matInput placeholder="Enter Height" (keypress)="validateNumber($event)" maxlength="5"
              formControlName="birth_height">
          </mat-form-field>

          <mat-form-field style="width: 30%;">
            <mat-label>HC(Cm)</mat-label>
            <input matInput placeholder="Enter Age" (keypress)="validateNumber($event)" maxlength="5"
              formControlName="birth_hc">
          </mat-form-field>


        </div>
        <div style="display:flex;flex-direction: row; justify-content: space-around; width: 100%;">

          <mat-form-field style="width: 30%;">
            <mat-label>CC(Cms)</mat-label>
            <input matInput placeholder="Enter CC" (keypress)="validateNumber($event)" maxlength="5"
              formControlName="birth_cc">
          </mat-form-field>

          <mat-form-field style="width: 30%;">
            <mat-label>BP(Sys)</mat-label>
            <input matInput placeholder="Enter BP" (keypress)="validateNumber($event)" maxlength="5"
              formControlName="birth_bpsys">
          </mat-form-field>

          <mat-form-field style="width: 30%;">
            <mat-label>BP(Dia)</mat-label>
            <input matInput placeholder="Enter BP(Dia)" (keypress)="validateNumber($event)" maxlength="5"
              formControlName="birth_bpdia">
          </mat-form-field>


        </div>
        <div style="display:flex;flex-direction: row;  ">

          <mat-form-field style="width: 30%; margin-left: 16px;">

            <input placeholder="Vaccination Date*" formControlName="vacDate" matInput [matDatepicker]="picker1" 
              (ngModelChange)="getAge();">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>

          </mat-form-field>

          <mat-form-field style="width: 30%; margin-left: 30px;">
            <mat-label>Blood Group</mat-label>
            <mat-select formControlName="blood_group" (selectionChange)="dropdownSelect('bloodGroup',$event.value)">
              <mat-option *ngFor="let item of bloodGroups" [value]="item">{{item}}</mat-option>
            </mat-select>

          </mat-form-field>


        </div>

        <div style="padding-top: 0px; margin-top: 0px; display: flex; justify-content: flex-end;">
          <!-- <button (click)="closeDialog()" class="cancelUibtn">Cancel</button> -->
          <button  (click)="backToProfile()" class="cancelUibtn"> Back</button>

          <button type="submit" (click)="addAntenetalBirth()" class="btnUi"> Next</button>

        </div>

      </div>

      <!-- Health Profile-->
      <div
        *ngIf="!antenetalBirthFlag && !profileUiFlag && !familyProfilePed && !healthHistoryUi && !FamilyProfileAdultFlag"
        class="headingStyle">Health Profile</div>

      <div
        *ngIf="!antenetalBirthFlag && !profileUiFlag && !familyProfilePed && !healthHistoryUi && !FamilyProfileAdultFlag"
        style="display: flex;flex-direction: column;justify-content: center; width: 100%; margin-top: 10px;">


        <div style="display:flex;flex-direction: row; justify-content: space-around;">

          <mat-form-field style="width: 46%; margin-left: 13px;">
            <mat-label>Medical Conditions</mat-label>
            <input matInput placeholder="Enter Medical Conditions" formControlName="medicalConditions">
            <button style=" top: -27px;left: -35px; position: absolute;" (click)="open(medicalConditionsModal)"
              type="button" mat-icon-button matSuffix>
              <mat-icon><img style="height: 30px; width: 30px;" src="assets/images/plus_button_new.svg" /></mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field style="width: 46%;">
            <mat-label>Allergies</mat-label>
            <input matInput placeholder="Enter Allergies" formControlName="allergies">

          </mat-form-field>
        
        </div>
        <!-- <div style="width: 50%; margin-left: 20px;">
        <mat-chip-list aria-label="Fish selection" [multiple]="true" style="margin:10px 6px;">
          <mat-chip class="price-filter" style="font-size: 16px; background-color: #E8F7F5;" #ref="matChip"
              *ngFor="let item of medicalConditionArray;let i=index" (click)='selectmedicalConditions(item);'
              [value]="item">
              {{item.name}}
          </mat-chip>
      
      </mat-chip-list>
    </div> -->
        <div style="display:flex;flex-direction: row; justify-content: space-around; width: 100%;">
          <mat-form-field style="width: 46%;  margin-left: 13px;">
            <mat-label>Reactions to Drug/Medicines</mat-label>
            <input matInput placeholder="Enter Reactions" formControlName="reactions">
          </mat-form-field>


          <mat-form-field style="width: 46%;">
            <mat-label>Recurring Complaints</mat-label>
            <input matInput placeholder="Enter Complaints" formControlName="recurring">
          </mat-form-field>
        </div>


        <div style="display:flex;flex-direction: row; justify-content: space-around; width: 100%;">

          <mat-form-field style="width: 46%; margin-left: 13px;">
            <mat-label>Hospitalisation</mat-label>

            <input matInput placeholder="Enter Hospitalisation" formControlName="hospitalisation">

          </mat-form-field>

          <mat-form-field style="width: 46%;">
            <mat-label>Health History</mat-label>
            <input matInput placeholder="Enter Health History" formControlName="healthHistory">

          </mat-form-field>


        </div>



        <div style="display:flex;flex-direction: row; justify-content: space-around; width: 100%;">

          <mat-form-field style="width: 46%; margin-left: 13px;">
            <mat-label>Email Id</mat-label>
            <input matInput placeholder="Enter Email Id" formControlName="emailId">
          </mat-form-field>

          <mat-form-field style="width: 46%;">
            <mat-label>UHID/Ref .No</mat-label>
            <input matInput placeholder="Enter UHID/Ref .No " formControlName="otherId">
          </mat-form-field>




        </div>
        <div style="display:flex;flex-direction: row; justify-content: flex-start; width: 100%;">
          <mat-form-field style="width: 46%; margin-left: 29px;">
            <mat-label>Any Known Genetic Disorder</mat-label>
            <input matInput placeholder="Enter Genetic Disorder" formControlName="geneticDisorders">
            <button style=" top: -27px;left: -35px; position: absolute;" (click)="open(geneticDisordersModal)"
              type="button" mat-icon-button matSuffix>
              <mat-icon><img style="height: 30px; width: 30px;" src="assets/images/plus_button_new.svg" /></mat-icon>
            </button>
          </mat-form-field>
        </div>
        <!-- <div style="width: 100%; height: 15vh; overflow: scroll; margin-left: 20px;">
        <mat-chip-list aria-label="Fish selection" [multiple]="true" style="margin:10px 6px;">
          <mat-chip class="price-filter" style="font-size: 16px; background-color: #E8F7F5;" #ref="matChip"
              *ngFor="let item of geneticDisordersArray	;let i=index" (click)='selectgeneticDisorders(item);'
              [value]="item">
              {{item.name}}
          </mat-chip>

      </mat-chip-list>
    </div> -->

        <div style="padding-top: 36px; display: flex; justify-content: flex-end;">
          <!-- <button (click)="closeDialog()" class="cancelUibtn">Cancel</button> -->
            <button (click)="backaddHealthProfile()" class="cancelUibtn">Back</button> 

          <button type="submit" (click)="addHealthProfile()" class="btnUi"> Next</button>

        </div>



      </div>

      <!-- Family Profile-->


      <div *ngIf="!antenetalBirthFlag && !profileUiFlag && !healthProfileFlag && !familyProfilePed && !healthHistoryUi "
        style="display: flex;flex-direction: column;justify-content: center; width: 100%; margin-top: 10px;">
        <div style="display:flex;flex-direction: row; justify-content: center; width: 100%;">
          <mat-form-field style="width: 50%;">
            <mat-label>Family Medical History</mat-label>
            <input matInput placeholder="Enter Family Medical History" formControlName="familyMedicalConditions">
            <button style=" top: -27px;left: -35px; position: absolute;" (click)="open(familyMedicalConditionsModal)"
              type="button" mat-icon-button matSuffix>
              <mat-icon><img style="height: 30px; width: 30px;" src="assets/images/plus_button_new.svg" /></mat-icon>
            </button>
          </mat-form-field>



        </div>
        <div style="display:flex;flex-direction: row; justify-content: center; width: 100%;">
          <mat-form-field style="width: 50%;">
            <mat-label>Enter Disorders Any Known Family Genetic Disorders</mat-label>
            <input matInput placeholder="Enter Family Medical History" formControlName="familyGeneticDisorders">
            <button style=" top: -27px;left: -35px; position: absolute;" (click)="open(familyGeneticDisordersModal)"
              type="button" mat-icon-button matSuffix>
              <mat-icon><img style="height: 30px; width: 30px;" src="assets/images/plus_button_new.svg" /></mat-icon>
            </button>
          </mat-form-field>
        </div>
      
        <div style="padding-top: 36px; display: flex; justify-content: flex-end;">
          <!-- <button (click)="closeDialog()" class="cancelUibtn">Cancel</button> -->
           <button (click)="backaddFamilyProfilePed()" class="cancelUibtn">Back</button> 

          <button type="submit" (click)="addFamilyProfilePed()" class="btnUi"> Next</button>

        </div>

      </div>

    </form>


    <div
      *ngIf="!antenetalBirthFlag && !profileUiFlag && !healthProfileFlag && !healthHistoryUi && !FamilyProfileAdultFlag"
      class="headingStyle">Family Profile</div>
    <div
      *ngIf="!antenetalBirthFlag && !profileUiFlag && !healthProfileFlag && !healthHistoryUi && !FamilyProfileAdultFlag"
      style="display: flex;flex-direction: column;justify-content: center; width: 100%; margin-top: 10px;">


      <div style="display:flex;flex-direction: row; justify-content: center; width: 100%;">
        <div>Father's Height</div> &nbsp;&nbsp;&nbsp;
        <div *ngIf="fathersHeightInFt === 'ft'">
          <mat-form-field style="width: 20%;">
            <mat-label>Ft</mat-label>
            <input matInput placeholder="Enter ft " (keypress)="validateNumber($event)"
              [(ngModel)]="fathersHeightFt.ft">
          </mat-form-field>

          <mat-form-field style="width: 20%; margin-left: 20px;">
            <mat-label>Inches</mat-label>
            <input matInput placeholder="Enter ft " (keypress)="validateNumber($event)"
              [(ngModel)]="fathersHeightFt.in">
          </mat-form-field>

          <mat-form-field style="width: 30%; margin-left: 30px;">
            <mat-label></mat-label>
            <mat-select [(ngModel)]="fathersHeightInFt"
              (selectionChange)="dropdownSelectForHeight('heightInFit',$event.value)">
              <mat-option *ngFor="let item of  heightArr " [value]="item">{{item}}</mat-option>
            </mat-select>

          </mat-form-field>
        </div>





        <div *ngIf="fathersHeightInFt === 'cm'">
          <mat-form-field style="width: 20%;">
            <mat-label>Ft</mat-label>
            <input matInput placeholder="Enter ft " [(ngModel)]="fathersHeightCm">
          </mat-form-field>
          <mat-form-field style="width: 30%; margin-left: 30px;">
            <mat-label></mat-label>
            <mat-select [(ngModel)]="fathersHeightInFt"
              (selectionChange)="dropdownSelectForHeight('heightInFit',$event.value)">
              <mat-option *ngFor="let item of  heightArr " [value]="item">{{item}}</mat-option>
            </mat-select>

          </mat-form-field>
        </div>



      </div>

      <div style="display:flex;flex-direction: row; justify-content: center; width: 100%;">
        <div>Mother Height</div>&nbsp;&nbsp;&nbsp;
        <div *ngIf="mothersHeightInFt==='ft'">
          <mat-form-field style="width: 20%;">
            <mat-label>Ft</mat-label>
            <input matInput placeholder="Enter ft " [(ngModel)]="mothersHeightFt.ft">
          </mat-form-field>

          <mat-form-field style="width: 20%; margin-left: 20px;">
            <mat-label>Inches</mat-label>
            <input matInput placeholder="Enter ft " [(ngModel)]="mothersHeightFt.in">
          </mat-form-field>

          <mat-form-field style="width: 30%; margin-left: 30px;">
            <mat-label></mat-label>
            <mat-select [(ngModel)]="mothersHeightInFt"
              (selectionChange)="dropdownSelectForHeight('motherHeight',$event.value)">
              <mat-option *ngFor="let item of  heightArr " [value]="item">{{item}}</mat-option>
            </mat-select>

          </mat-form-field>
        </div>



        <div *ngIf="mothersHeightInFt==='cm'">
          <mat-form-field style="width: 20%;">
            <mat-label>cm</mat-label>
            <input matInput placeholder="Enter cm " [(ngModel)]="mothersHeightCm">
          </mat-form-field>

          <mat-form-field style="width: 30%; margin-left: 30px;">
            <mat-label></mat-label>
            <mat-select [(ngModel)]="mothersHeightInFt"
              (selectionChange)="dropdownSelectForHeight('motherHeight',$event.value)">
              <mat-option *ngFor="let item of  heightArr " [value]="item">{{item}}</mat-option>
            </mat-select>

          </mat-form-field>
        </div>
      </div>

      <div style="display:flex;flex-direction: row; justify-content: center; width: 100%;">
        <mat-form-field style="width: 50%;">
          <mat-label>Family Medical History</mat-label>
          <input matInput placeholder="Enter Family Medical History" [(ngModel)]="familyMedicalConditions">
          <button style=" top: -27px;left: -35px; position: absolute;" (click)="open(familyMedicalConditionsModal)"
            type="button" mat-icon-button matSuffix>
            <mat-icon><img style="height: 30px; width: 30px;" src="assets/images/plus_button_new.svg" /></mat-icon>
          </button>
        </mat-form-field>



      </div>
      <div style="display:flex;flex-direction: row; justify-content: center; width: 100%;">
        <mat-form-field style="width: 50%;">
          <mat-label>Enter Disorders Any Known Family Genetic Disorders</mat-label>
          <input matInput placeholder="Enter Family Medical History" [(ngModel)]="familyGeneticDisorders">
          <button style=" top: -27px;left: -35px; position: absolute;" (click)="open(familyGeneticDisordersModal)"
            type="button" mat-icon-button matSuffix>
            <mat-icon><img style="height: 30px; width: 30px;" src="assets/images/plus_button_new.svg" /></mat-icon>
          </button>
        </mat-form-field>
      </div>

     
      <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
        <!-- <button (click)="closeDialog()" class="cancelUibtn">Cancel</button> -->
        <button *ngIf="step.name === 'Family Profile'" (click)="backaddFamilyPrfofileisPediatric()" class="cancelUibtn">Back</button>
        <button type="submit" (click)="addFamilyPrfofileisPediatric()" class="btnUi">Next</button>

      </div>

    </div>

    <div
      *ngIf="!antenetalBirthFlag && !profileUiFlag && !healthProfileFlag && !familyProfilePed && !FamilyProfileAdultFlag"
      style="display: flex;flex-direction: column;justify-content: center; width: 100%; margin-top: 10px;">

      <div style="display:flex;flex-direction: row; justify-content: center; width: 100%;">
        <mat-form-field style="width: 31%;">
          <mat-label>Health History Highlights</mat-label>
          <input matInput placeholder="Enter Health History" [(ngModel)]="healthHistoryHighlights">
        </mat-form-field>
        <mat-form-field style="width: 32%; margin-left: 27px;">
          <mat-label>Blood Groups</mat-label>
          <mat-select [value]="blood_group" (selectionChange)="dropdownSelect('bloodGroup',$event.value)">
            <mat-option *ngFor="let item of  bloodGroups " [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>


      <div style="display: flex;justify-content: center;">
        <div style="justify-content: flex-start;align-items: center;height: 100%">
          <div style="border: solid 1px #CECECE; border-radius: 8px;width: 85%; margin-left: 62px; ">
            <div
              style="padding-bottom: 20px;width: 672px; height: 100%; box-sizing: border-box;  color:  #656565;  border-radius: 8px;">
              <div style="margin-left: 14px;">
                <label class="section-label" style="color: #959191;">Habits</label>
              </div>
              <div>
                <p style="display: flex;flex-direction: row;flex: 1;margin-left: 156px;">Past</p>
                <p style="display: flex; flex-direction: row;justify-content: flex-end;
                   
                    margin-right: 61px;" class="habbitUi">Present</p>
              </div>
              <div style="display: flex;flex-direction: row; justify-content: space-evenly; width: 92%;">
                <div style="display: flex;flex-direction: column;margin-left: 33px;">
                  <div style="display: flex;flex-direction: row;">1.Chewing</div>
                  <div style="margin-top: 15px;">2.Smoking</div>
                  <div style="margin-top: 15px;">3.Drinking</div>
                  <div style="margin-top: 15px;">4.Drugs</div>
                  <div style="margin-top: 15px;">5.Other</div>
                </div>
                <div class="item" style="display:flex;flex-direction: row;width: 100%; justify-content: space-between;">

                  <div style="display: flex; width: 100%; flex-direction: row;justify-content: space-between;">

                    <div class="item-checkbox" style="display: flex;flex-direction: column; margin-left: 44px;">

                      <input [checked]="habbitsArray['Chewing']['past']==1" type="checkbox"
                        style="width:57px;height:24px;margin-top: 1px;"
                        (change)="pastHabbitsAdd($event,'Chewing','past')">

                      <input [checked]="habbitsArray['Smoking']['past']==1" type="checkbox"
                        style="width:57px;height:24px;margin-top: 10px;"
                        (change)="pastHabbitsAdd($event,'Smoking','past')">
                      <input [checked]="habbitsArray['Drinking']['past']==1" type="checkbox"
                        style="width:57px;height:24px;margin-top: 10px;"
                        (change)="pastHabbitsAdd($event,'Drinking','past')">
                      <input [checked]="habbitsArray['Drugs']['past']==1" type="checkbox"
                        style="width:57px;height:24px;margin-top: 10px;"
                        (change)="pastHabbitsAdd($event,'Drugs','past')">
                      <input [checked]="habbitsArray['Other']['past']==1" type="checkbox"
                        style="width:57px;height:24px;margin-top: 10px;"
                        (change)="pastHabbitsAdd($event,'Other','past')">

                    </div>

                    <div class="item-checkbox" style="display: flex;flex-direction: column; justify-content: flex-end;">

                      <input [checked]="habbitsArray['Chewing']['present']==1" type="checkbox"
                        style="width:57px;height:24px;margin-top: 1px;"
                        (change)="pastHabbitsAdd($event,'Chewing','present')">
                      <input [checked]="habbitsArray['Smoking']['present']==1" type="checkbox"
                        style="width:57px;height:24px;margin-top: 10px;"
                        (change)="pastHabbitsAdd($event,'Smoking','present')">
                      <input [checked]="habbitsArray['Drinking']['present']==1" type="checkbox"
                        style="width:57px;height:24px;margin-top: 10px;"
                        (change)="pastHabbitsAdd($event,'Drinking','present')">
                      <input [checked]="habbitsArray['Drugs']['present']==1" type="checkbox"
                        style="width:57px;height:24px;margin-top: 10px;"
                        (change)="pastHabbitsAdd($event,'Drugs','present')">
                      <input [checked]="habbitsArray['Other']['present']==1" type="checkbox"
                        style="width:57px;height:24px;margin-top: 10px;"
                        (change)="pastHabbitsAdd($event,'Other','present')">

                    </div>

                  </div>
                </div>

              </div>


            </div>
          </div>

        </div>


      </div>

      <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
        <!-- <button (click)="closeDialog()" class="cancelUibtn">Cancel</button> -->
       <button (click)="backaddHealthHistory()" class="cancelUibtn">Back</button> 
        <button type="submit" (click)="addHealthHistory()" class="btnUi"> Next</button>

      </div>


    </div>



  </mat-step>

</mat-horizontal-stepper>




<ng-template #patientListModal let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h5 class="modal-title" id="modal-basic-title" style="color: #94999e;">Already onboarded patient with same
      mobile number</h5>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="height: 60vh; overflow: scroll;">

      <ul *ngFor="let patient of patientList">
        <li class="test rounded-circle clearfix">
          <p style=" margin-left: 1px; text-align: center; margin-top: 8px; color: #94999e;font-size: 19px;">
            {{patient.firstName.toUpperCase().charAt(0)}} {{patient.lastName.toUpperCase().charAt(0)}}</p>
        </li>
        <li class="clearfix" style="font-size: 17px; color: #333; font-family: 'Mukta', sans-serif; margin-left: 88px;
      margin-top: -43px;">

          {{patient.firstName}} {{patient.lastName}} </li>

        <li class="clearfix" style="font-size: 15px; color: grey; margin-top: -4px; margin-left: 88px;">
          {{patient.contactNo}} /{{patient.gender.toUpperCase().charAt(0)}}</li>
      </ul>
    </div>
  </div>

  <div class="modal-footer">
    <div style="display:flex; flex-direction: row; justify-content: space-between; flex: 1; margin-right: 30px;">
      <div (click)="patientFormOpen();modal.dismiss()" class="quickrx" style="margin-left: -1px;"> Modify RMN
      </div>

      <div  (click)="modal.dismiss();" class="quickrx"
        style="margin-right:-29px;height: 49px;">Proceed With same RMN</div>
    </div>
    <!-- <div (click)="onSubmitButtonn();modal.dismiss();" class="btn-type-filled">Proceed With same RMN
          </div> -->
  </div>
</ng-template>



<ng-template #continueProfileModal let-modal>
  <div class="modal-header" style="border-bottom: 0px; display: flex;flex-direction: column;">
    <div style="display: flex;flex-direction: row; justify-content: space-between; flex: 1;">
      <h4 class="modal-title" id="modal-basic-title">Choose Action</h4>

      <!-- <button type="button" class="close" style="outline: none; border-width: 0px; margin-left: 58px;"
        aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true" style="margin-left: 217px;">&times;</span>
      </button> -->


    </div>

    <br>
    <h6 class="modal-title" id="modal-basic-title" style="color: #94999e;">Do you want to start treating this
      patient?</h6>

  </div>
  <div class="modal-body">
    <div class="row"
      style="background-color: #fff; padding: 16px; box-shadow: 0 0 2px #ccc; margin-left: -14px; border-radius: 6px;">
      <div style="display: flex;flex-direction: column;">
        <!-- <div *ngIf="isPediatric" style="display: flex;flex-direction: row;" class="hovereffect"
          (click)="getvaccDate();open(checkDateModal);" style="cursor: pointer;">
          <i class="fa fa-pencil-square-o iconsAdd clearfix"></i>
          <span class="iconsText">Add Vitals</span>
        </div> -->
        <!-- <div *ngIf="!isPediatric" style="display: flex;flex-direction: row;" class="hovereffect"
          (click)="goToAddVitals();modal.dismiss();" style="cursor: pointer;">
          <i class="fa fa-pencil-square-o iconsAdd clearfix"></i>
          <span class="iconsText">Add Vitals</span>
        </div> -->
        <hr style="width: 125%;">
        <div *ngIf="isAppointmentEnabled == '1'" style="display: flex;flex-direction: row;"
          (click)="createAppointment();modal.dismiss();" style="cursor: pointer;">
          <i class="fa fa-calendar iconsAdd clearfix"></i>
          <span class="iconsText">Create Appointment </span>
        </div>
        <div *ngIf="isAppointmentEnabled == '2'" style="display: flex;flex-direction: row;"
          (click)="createAppointment();modal.dismiss();" style="cursor: pointer;">
          <i class="fa fa-calendar iconsAdd clearfix"></i>
          <span class="iconsText">Add to Queue </span>
        </div>

        <hr style="width: 125%;">
        <div style="display: flex;flex-direction: row;" (click)="uploadDocument();modal.dismiss();"
          style="cursor: pointer;">
          <i class="fa fa-upload iconsAdd clearfix"></i>
          <span class="iconsText">Upload Documents</span>
        </div>
        <hr style="width: 125%;">
        <div style="display: flex;flex-direction: row;" (click)="goToRx();modal.dismiss();">
          <div class="iconsAdd clearfix" style="cursor: pointer;">
            <img src="assets/images/icons8-prescription-32.png" alt="" />
          </div>

          <span class="iconsText" style="cursor: pointer;">Quick Rx</span>
        </div>
        <hr style="width: 125%;">
        <div style="display: flex;flex-direction: row;" (click)="modal.dismiss();">
          <div class="iconsAdd clearfix" style="cursor: pointer;">
            <img src="assets/images/edit-user-32.png" alt="" />
          </div>

          <span class="iconsText" style="cursor: pointer;">Continue to complete detail profile</span>
        </div>


      </div>
    </div>
  </div>


  <div class="modal-footer">
    <div (click)="nextTabHomePage(); modal.dismiss()" class="btn-type-filled">Done</div>
  </div>
</ng-template>

<ng-template #checkDateModal let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Check Dates</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span style="margin-right: 6px;" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="width: 98%;
      padding-left: 5px;
      margin-top: 14px;
      margin-bottom: 4px;
      margin-left: 8px;">
      <div class="row">
        We have added Expected DOB as {{dobDate}} and Vaccination <br>
        Start date as {{vacDate}} Would you like to modify or continue with same?<br>

        Once continued these dates cannot be changed

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div style="display: flex;flex-direction: row;justify-content: space-between;">
      <div (click)="modifyVitals();" class="btn-type-filled">MODIFY</div>
      <div (click)="goToAddVitals(); modal.dismiss()" class="btn-type-filled">CONTINUE</div>
    </div>

  </div>
</ng-template>
<ng-template #medicalConditionsModal let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Medical Conditions</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>

      <div class="form-group">
        <div class="input-group">
          <input id="searchbox" (input)="onSearchChange($event.target.value, 'medicalConditions')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: -20px;
          font-size: 16px;" class="form-control" placeholder="Start typing to search" name="dp">
        </div>
        <div style="height: 50vh; overflow: scroll;">
          <div
            style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw; ">

            <div *ngFor="let item of medicalConditionArray; let i = index"
              (click)="selectModalValue(i, 'medicalConditions')" class="searchable-component-style">
              <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
                class="tick-icon" />

              <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
                class="tick-icon" />
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div (click)="saveModalValues('medicalConditions'); modal.dismiss()" class="btn-type-filled">Add Medical
      Conditions</div>
  </div>
</ng-template>

<ng-template #geneticDisordersModal let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Genetic Disorders</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>

      <div class="form-group">
        <div class="input-group">
          <input id="searchbox" (input)="onSearchChange($event.target.value, 'geneticDisorders')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: -20px;
          font-size: 16px;" class="form-control" placeholder="Start typing to search" name="dp">
        </div>
        <div style="height: 60vh; overflow: scroll;">
          <div
            style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw; ">

            <div *ngFor="let item of geneticDisordersArray; let i = index"
              (click)="selectModalValue(i, 'geneticDisorders')" class="searchable-component-style">
              <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
                class="tick-icon" />

              <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
                class="tick-icon" />
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div (click)="saveModalValues('geneticDisorders'); modal.dismiss()" class="btn-type-filled">Add Genetic
      Disorders </div>
  </div>
</ng-template>


<ng-template #familyGeneticDisordersModal let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Family Genetic Disorders</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>

      <div class="form-group">
        <div class="input-group">
          <input id="searchbox" (input)="onSearchChange($event.target.value, 'familyGeneticDisorders')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: -20px;
          font-size: 16px;" class="form-control" placeholder="Start typing to search" name="dp">
        </div>
        <div style="height: 50vh; overflow: scroll;">
          <div
            style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw; ">

            <div *ngFor="let item of familyGeneticDisordersArray; let i = index"
              (click)="selectModalValue(i, 'familyGeneticDisorders')" class="searchable-component-style">
              <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
                class="tick-icon" />

              <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
                class="tick-icon" />
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div (click)="saveModalValues('familyGeneticDisorders'); modal.dismiss()" class="btn-type-filled">Add Genetic
      Disorders </div>
  </div>
</ng-template>
<ng-template #familyMedicalConditionsModal let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Family Medical Conditions</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>

      <div class="form-group">
        <div class="input-group">
          <input id="searchbox" (input)="onSearchChange($event.target.value, 'familyMedicalConditions')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: -20px;
          font-size: 16px;" class="form-control" placeholder="Start typing to search" name="dp">
        </div>
        <div style="height: 60vh; overflow: scroll;">
          <div
            style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw; ">

            <div *ngFor="let item of familyMedicalArray; let i = index"
              (click)="selectModalValue(i, 'familyMedicalConditions')" class="searchable-component-style">
              <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
                class="tick-icon" />

              <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
                class="tick-icon" />
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div (click)="saveModalValues('familyMedicalConditions'); modal.dismiss()" class="btn-type-filled">Add Family
      Medical Conditions </div>
  </div>
</ng-template>



<ng-template #doneProfileModal let-modal>
  <div class="modal-header" style="border-bottom: 0px; display: flex;flex-direction: column;">
    <div style="display: flex;flex-direction: row; justify-content: space-between; flex: 1;">
      <h4 class="modal-title" id="modal-basic-title">Success</h4>

      <!-- <button type="button" class="close" style="outline: none; border-width: 0px; margin-left: 273px;"
        aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true" style="margin-left: 68px;">&times;</span>
      </button> -->


    </div>

    <br>
    <h6 class="modal-title" id="modal-basic-title" style="color: #94999e;">Patient detail profile with family
      history is completed</h6>

  </div>
  <div class="modal-body">
    <div class="row"
      style="background-color: #fff; width: 106%; padding: 16px; box-shadow: 0 0 2px #ccc; margin-left: -14px; border-radius: 6px;">
      <div style="display: flex;flex-direction: column;">
        <!-- <div style="display: flex;flex-direction: row;" class="hovereffect" (click)="goToAddVitals();modal.dismiss();"
          style="cursor: pointer;">
          <i class="fa fa-pencil-square-o iconsAdd clearfix"></i>
          <span class="iconsText">Add Vitals</span>
        </div> -->
        <hr style="width: 185%;">
        <div *ngIf="isAppointmentEnabled == '1'" style="display: flex;flex-direction: row;"
          (click)="createAppointment();modal.dismiss();" style="cursor: pointer;">
          <i class="fa fa-calendar iconsAdd clearfix"></i>
          <span class="iconsText">Create Appointment </span>
        </div>
        <div *ngIf="isAppointmentEnabled == '2'" style="display: flex;flex-direction: row;"
          (click)="createAppointment();modal.dismiss();" style="cursor: pointer;">
          <i class="fa fa-calendar iconsAdd clearfix"></i>
          <span class="iconsText">Add to Queue </span>
        </div>

        <hr style="width: 185%;">
        <div style="display: flex;flex-direction: row;" (click)="uploadDocument();modal.dismiss();"
          style="cursor: pointer;">
          <i class="fa fa-upload iconsAdd clearfix"></i>
          <span class="iconsText">Upload Documents</span>
        </div>
        <hr style="width: 185%;">
        <div style="display: flex;flex-direction: row;" (click)="goToRx();modal.dismiss();">
          <div class="iconsAdd clearfix" style="cursor: pointer;">
            <img src="assets/images/icons8-prescription-32.png" alt="" />
          </div>

          <span class="iconsText" style="cursor: pointer;">Quick Rx</span>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div (click)="nextTabHomePage(); modal.dismiss()" class="btn-type-filled">Done</div>
  </div>
</ng-template>

<ng-template #otpModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Copious Healthcare
    </h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div class="newLoginHeader"> <label class="newLoginLabel">Welcome SMS sent to
          {{stdCode}}{{mobileNumber}}</label></div>
      <!-- <h6 class="email-id"><span class="p-cursor" {{user.phone_number.slice(-3)}} (click)="showEmail()">{{emailId}}</span></h6> -->
      <div style="margin-top: 2%;margin-left: 4%;">

      </div>



      <div class="login-wrapper">
        <form>


          <button type="submit" class="opd-primary-btn btn-md w125 btn-type-filled"
            style="margin-left: 60%;margin-top: 5%;text-transform: capitalize;"
            (click)="modal.dismiss('Cross click');confirmOTP()">Confirm</button>
        </form>
      </div>
    </div>
  </div>


</ng-template>


<ng-template #highRiskTypeModal let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">High-Risk Category</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>

      <div class="form-group">

        <div class="input-group">
          <input id="searchbox" [(ngModel)]="highRiskForBabies"  matInput  (input)="onSearchChange($event.target.value)" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: px;
                      font-size: 16px;" class="form-control" placeholder="Start typing to search" name="sb">
        </div>
        <div style="height: 65vh; width: 50vw; overflow: scroll; margin-top: 10px; ">



          <mat-accordion class="example-headers-align" >
            <mat-expansion-panel *ngFor="let item of newHighRisksCategoriesForBabies; let i = index; first as isFirst" [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>{{item.section}} </mat-panel-title>
             
              </mat-expansion-panel-header>
            
        
                <div
            style="display: flex; flex-direction: column; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 48vw;   ">

            <div *ngFor="let item1 of item.data ; let j = index" class="searchable-component-style testCardUi"
              (click)="selectHighRiskValue(item1,i,j)" >
              <img *ngIf="item1.selected!= true" style="margin: 0px 8px 0px 0px;height:16px;width:16px;"
                src="assets/images/tick_mark_grey.svg" class="tick-icons" />

              <img *ngIf="item1.selected==true" style="margin: 0px 8px 0px 0px;height:16px;width:16px;"
                src="assets/images/tick_mark_color.svg" class="tick-icons" />
              <div>{{item1.name}}</div>
            </div>
          </div>
            
             
            </mat-expansion-panel>
          </mat-accordion>


        </div>


      </div>
    </form>


  </div>

  <div class="modal-footer">
    <div (click)="saveModalValues('highRiskType'); modal.dismiss()" class="btn-type-filled">Add High-Risk
      Category</div>
  </div>
</ng-template>

<p-toast position="top-right" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class=" p-flex p-flex-column"
      style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;">
      <div>
        <img (click)="goBack()" style="height: 36px; margin-left: 12px; width: 36px; align-self: center;"
          src="assets/images/exclamation-circle-blue.svg" alt="" />
      </div>
      <div class=" p-text-center">
        <div style="font-size: 18px;
                  font-weight: bold;
                  padding: 12px 0px 0px 12px;
                  
                  color: #034A70;">{{message.summary}}</div>
        <div style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
          {{message.detail}}</div>
      </div>
    </div>
  </ng-template>
</p-toast>