<div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="showNavLoading">
    <label for="" class="loader"></label>
</div>

    <div class="headerbar">

        <app-top-info-navbar [rxUi]="rxUi" [appointmentId]="appointmentId" [patientResponse]="patientResponse" [selectedIndex]='selectedIndex' [parentForm]="'PO'" *ngIf="(patientResponse | json) != '{}' && (selectedIndex | json) != '{}' "></app-top-info-navbar>

    </div>
    <div *ngIf="tab == 'Visits'"
    style="display: flex; flex-direction: column; background-color: #fff; height: 100vh;width: 104vw; align-items: center">
    <patient-vitals [patientObj]="patientId" [showGraphs]="showGraphs" [graphName]="graphName"></patient-vitals>
</div>

<div *ngIf="tab == 'Milestone'"
style="display: flex; flex: 1; position: relative; flex-direction: column;  height: 100%; width: 100%;">
<app-milestone [patientObj]="patientId" [doctorObj]="currentDoctorObj" [milestoneFlag]="milestoneFlag"></app-milestone>
</div>
 <!-- ***********Notes Section*********************** -->

 <div *ngIf="tab == 'Notes'" style="display: flex; flex-direction: row; height: 100vh;">


    <div style="display: flex; justify-content: flex-start; width: 50%; flex-direction: column;">
        <!-- <div style="margin: 16px 0px 0px 30px; font-size: 24px; color: #333; font-weight: bold;">Notes</div> -->
        <div class="heading">Notes</div>
        <div class="subheading">This feature is used create notes for patients.</div>

        <div *ngIf="!notesArr.length"
            style="width: 100%;display: flex; flex-direction: column; width: 100%;height: 80vh;display: flex;justify-content: center;align-items: center;">
            <div style="text-align: center;">
                <img style="justify-content: center;align-items: center; width: 170px;margin-bottom: -10px"
                    src="assets/images/placeholder.svg" alt="" />

                <div style="font-size: 20px; color: #333333; font-weight: bold;">No Notes </div>
                <div style="font-size: 18px; color: grey;">No notes found for selected Patient</div>

            </div>
        </div>

        <div style="margin-left: 16px; width: 95%;">
            <div *ngFor="let note of notesArr; let i = index">
                <div class="card" style="width: 100%;align-self: center;padding: 10px;">
                    <div style="display: flex; flex-direction: row; margin-left: 10px; ">
                        <div style="display: flex; flex-direction: row;">
                            <div class="leftSideTextOnCard">Dr. {{note.docName}}</div>
                        </div>
                        <div class="rightSideTextOnCard">{{note.date}}</div>
                    </div>
                    <div style="margin-top: 10px;margin-left: 15px;">
                        <div style="font-size: 16px;color: #8B8B8B; word-break: break-all !important">{{note.note}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        style="display: flex; width: 50%; height: 100vh; flex-direction: column; justify-content: center; align-items: center;">

        <div hidden style="margin: 16px 0px 0px 30px;
        font-size: 24px;
        color: #333;
        font-weight: bold;">Notes</div>
        <div class="ticket"
            style="height: 410px; width: 90%; background-color: #fff; margin-top: 16px; border-color: rgb(70, 135, 191);">
            <div style="margin: 1px 0px 0px 0px;
                font-size: 24px;
                color: #333;
                font-weight: bold;">Create Note</div>
            <div style="margin: 0px 0px 0px 0px;
                 font-size: 16px;
                 color: #798197;
                 font-weight: bold;
                 ">Create or update a note for this patient & keep it precise.</div>

            <textarea name="Text1" cols="40" rows="10" class="text-area-style" maxlength="500" placeholder=""
                [(ngModel)]="notes_message">{{notes_message}}</textarea>

            <div style="display: flex; justify-content: flex-end; width: 100%">
                <button type="submit" class=" btn-md w125 newBtnStyle"
                    style="align-self: flex-end; margin-top:16px; text-transform: capitalize; background-color: #4687BF !important; border: 1px solid #4687BF !important;"
                    (click)="saveNotes()">Save</button>
            </div>
        </div>

    </div>

</div>

<!--***********Investigation*******************************-->

<div *ngIf="tab == 'Investigations'">

    <div *ngIf="innertab == 'Reports'" style="flex: 1; height: 100vh; width: '100%';">

        <div *ngIf="innertab == 'Reports'">
            <div style="background-color: #fff;
        height: 100%;
        width: 100%;
        z-index: 100;" class="d-flex justify-content-center align-items-center position-fixed"
                *ngIf="showReportsLoading">
                <label for="" class="loader"></label>
            </div>
        </div>


        <div
            style="width: 100%; height: 60px; background-color: #fff;padding: 16px; display: flex; justify-content: space-between; align-items: center; margin-top: 16px;">

            <div style="display: flex; flex-direction: column; justify-content: center; ">
                <div class="heading" style="margin-top:0px">Reports</div>
                <div class="subheading" style="width: 100%;">You can upload patient reports</div>
            </div>


            <div>
                <button (click)="getAllDocumentsOfPatient(0)"
                    style="border: 1px solid #5D86CC;
                            padding: 8px 16px;
                            border-radius: 6px;
                            color: #5D86CC;
                            font-size: 18px;
                            font-weight: bold;
                            background-color: #fff;
                            margin-right: 12px;
                            outline: none;">Refresh</button>

                <button (click)="openDialog(addUploadModal,'addUploadModal');closeUploadModal(); clickReportDoc()"
                    style="background-color: #5D86CC;
padding: 8px 16px;
border-radius: 6px;
color: #fff;
font-size: 18px;
font-weight: bold;
border: 0px;
margin-right: 20px;
outline: none;">Upload Report</button>

            </div>

        </div>


        <div style="display: flex; flex: 1; justify-content: center;">

            <div style=" width: 80%; justify-content:center;">

                <div *ngIf="!allReports.length" style="width: 100%;display: flex; flex-direction: column; width: 100%;
                height: 80vh;
                display: flex;
               
                justify-content: center;
                align-items: center;">

                    <div style="text-align: center;">
                        <img style="justify-content: center;align-items: center; width: 200px; height: 200px; margin-bottom:0px"
                            src="assets/images/placeholder.svg" alt="" />

                        <div style="font-size: 20px; margin-top: 16px; color: #333333; font-weight: bold;">No
                            Reports</div>
                        <div style="font-size: 18px; color: grey;">No reports found for selected Patient</div>
                    </div>
                </div>


                <div *ngFor="let reports of allReports; let i = index">

                    <div class="card" style="align-self: center;padding: 10px; display: flex; ">

                        <div style="display: flex; flex-direction: row; margin-left: 10px; ">
                            <div style="display: flex; flex-direction: row;">
                                <div class="leftSideTextOnCard">{{reports.documentType}}: </div>
                            </div>
                            <div class="leftSideTextOnCard" style="margin-left: 10px;
                          
                            color: #8C8B8C;"> {{reports.notes}}</div>
                            <div class="rightSideTextOnCard">{{reports.documentDate | date: 'dd/MM/yyyy'}}</div>
                        </div>


                        <div style="display: flex; flex-direction: row; margin-left: 10px; ">
                            <div
                                style="display: flex; flex-direction: row; width:100%; justify-content: space-between; margin-top:8px;">

                                <div *ngIf="reports.url" class="leftSideTextOnCard"
                                    (click)="onClickViewReports(reports)" style="color:#4687BF;"> Preview
                                </div>

                                <div *ngIf="reports.isLocked" class="leftSideTextOnCard"
                                    (click)="openDialog(unlock_report_confirmation_modal, i); setSelectedReport(reports)"
                                    style="color:#4687BF;"> Unlock for Patient
                                </div>


                            </div>

                        </div>

                    </div>


                </div>

            </div>
        </div>

    </div>


    <div *ngIf="innertab == 'Requisitions'">
        <app-patient-requisition [allRequistionTest]="allRequistionTest" [patientResponse]="patientResponse" [doctorObj]="currentDoctorObj"
            [originalSectionWiseRequisitionTests]="originalSectionWiseRequisitionTests"></app-patient-requisition>


    </div>

</div>

<!--Referral-->
<div *ngIf="tab == 'Referral'">
    <app-patient-referral [appointmentArr]="appointmentArr" [patientResponse]="patientResponse" [doctorObj]="currentDoctorObj"></app-patient-referral>
</div>
<!--Certificate-->
<div *ngIf="tab == 'Certificate'">
    <app-patient-certificate [patientResponse]="patientResponse" [doctorObj]="currentDoctorObj"
        [patientType]="isPediatric" *ngIf="(patientResponse | json) != '{}' && (doctorObj | json) != '{}' "></app-patient-certificate>
</div>

<!-- ***********Receipt Section*********************** -->

<div *ngIf="tab == 'Receipts'" style="display: flex; width: 100%; margin-top: 0px;">
    <app-patient-receipt [patientObj]="patientId" [patientType]="isPediatric" [doctorObj]="currentDoctorObj">
    </app-patient-receipt>

</div>


<div *ngIf="tab == 'History'">
    <app-patient-history [patientPrescription]="appointmentArr" [patientAllDocumnets]="allDocumentReports"
        [patientId]="patientId.id ? patientId.id : patientId._id">
    </app-patient-history>

</div>


<!-- ***********Vaccination Section*********************** -->

<div *ngIf="tab == 'Vaccination'"
    style="display: flex; flex-direction: column; background-color: #ffffff; height: 105vh; width: 100%; align-items: center; margin-top: 0px;">

    <patient-immunization [splVaccFlag]="splVaccFlag" [patientObj]="patientId" [doctorObj]="currentDoctorObj"
        [specialSituationVaccines]="specialSituationVaccines" [specialVaccineInfo]="specialVaccineInfo">
    </patient-immunization>
</div>

<!-- ***********Overview Section*********************** -->

     <div *ngIf="tab == 'Overview'" style="width: 100%; height: 100vh;" >
        <div *ngIf="!isPediatric" style="width:100% ;display: flex;flex-direction: column; ">
        <div  [ngStyle]="{'width' : adultLatestVisitBloodSugarArray.length  ? '100%' : '42%'}" style="display: flex; margin: 15px 5px 0px 26px;">
            <!-- <div [ngStyle]="{'width' : !adultLatestVisitBloodSugarArray.length  ? '81%' : '42%'}" *ngIf="adultLatestVisitArray.length" style="font-size: 18px; color: #333; font-weight: 600;">Vitals At of {{adultLatestVisitDate}}</div> -->
            <!-- <div *ngIf="adultLatestVisitBloodSugarArray.length" style="font-size: 18px; color: #333;font-weight: 600;width: 26%; ">Blood Sugar </div>
            <div *ngIf="adultLatestVisitArray.length" style="font-size: 18px; color: #333;font-weight: 600; ">Blood Pressure</div>-->
        </div> 
        <div style="display: flex;flex-direction: row; margin-left: 22px;">
        <div *ngIf="adultLatestVisitArray.length" style="border: 1px solid #A59C9B;
        border-style: dashed; width: 23%;  margin-left: 10px; ">
          <div style="font-size: 18px; color: #333; font-weight: 600; margin-left: 10px; margin-top: 11px;">Vitals At of {{adultLatestVisitDate}}</div>  
        <div >
    <div *ngIf="adultLatestVisitArray.length"  class="cardUi">
        <div  *ngFor="let item of adultLatestVisitArray" style="display: flex;flex-direction: column;flex-wrap: wrap; " >
          <div *ngIf="item.value!='NA'" class="smallcard" style="width: 78px;height: 80px;">
<div style="color: #000000; margin: 5px 5px 0px 5px; font-size: 16px;border-radius: 6px 6px 0px 0px; font-weight: 600;"> {{item.name}}</div>
<div style=" color: #6B6B6B; font-size: 18px; margin: 5px 0px 0px 4px;line-height: 1;
font-weight: 600;"> {{item.value}}<span style="font-size: 12px; color: #969696;">{{item.symbol}}</span> 
  <br>  <span style="font-size: 14px;color: #969696;"> {{item.date |date: 'dd-MMM-yy'}}</span> </div>

 </div>
</div>
</div>
</div> 
</div>
<div *ngIf="adultLatestVisitBloodSugarArray.length" style="border: 1px solid #A59C9B;
border-style: dashed; width: 23%;  margin-left: 10px; ">
  <div style="font-size: 18px; color: #333; font-weight: 600; margin-left: 10px; margin-top: 11px;">Blood Sugar </div>  
<div >
<div *ngIf="adultLatestVisitArray.length"  class="cardUi">

<div  *ngFor="let item of adultLatestVisitBloodSugarArray" style="display: flex;flex-direction: column;flex-wrap: wrap; " >
  <div *ngIf="item.value!='NA'" class="smallcard" style="width: 86px;height: 80px;">
<div style="color: #000000; margin: 5px 5px 0px 5px; font-size: 16px;border-radius: 6px 6px 0px 0px; font-weight: 600;"> {{item.name}}</div>
<div style=" color: #6B6B6B; font-size: 18px; margin: 5px 0px 0px 4px;line-height: 1;
font-weight: 600;"> {{item.value}} <span style="font-size: 12px; color: #969696;">{{item.symbol}}</span>
 <br>  <span style="font-size: 14px;color: #969696;"> {{item.date |date: 'dd-MMM-yy'}}</span>
</div>

</div>
</div>
</div>
</div> 
</div>

<div *ngIf="adultLatestVisitBloodPressureArray.length" style="border: 1px solid #A59C9B;
border-style: dashed; width: 23%;  margin-left: 10px; ">
  <div style="font-size: 18px; color: #333; font-weight: 600; margin-left: 10px; margin-top: 11px;">Blood Pressure </div>  
<div>
<div class="cardUi">

<div  *ngFor="let item of adultLatestVisitBloodPressureArray" style="display: flex;flex-direction: column;flex-wrap: wrap; " >
  <div *ngIf="item.value!='NA'" class="smallcard" style="width: 90px;height: 80px;">
<div style="color: #000000; margin: 5px 5px 0px 5px; font-size: 16px;border-radius: 6px 6px 0px 0px; font-weight: 600;"> {{item.name}}</div>
<div style=" color: #6B6B6B; font-size: 18px; margin: 5px 0px 0px 4px ;line-height: 1;
font-weight: 600;"> {{item.value}}<span style="font-size: 12px; color: #969696;">{{item.symbol}}</span> 
 <br><span style="font-size: 14px;color: #969696;"> {{item.date |date: 'dd-MMM-yy'}}</span>
</div>

</div>
</div>
</div>
</div> 
</div>

<div *ngIf="adultLatestVisitLipidProfileArray.length" style="border: 1px solid #A59C9B;
border-style: dashed; width: 23%;  margin-left: 10px;">
  <div style="font-size: 18px; color: #333; font-weight: 600; margin-left: 10px; margin-top: 11px;">Lipid Profile (Cholesterol) </div>  
<div>
<div  class="cardUi">

<div  *ngFor="let item of adultLatestVisitLipidProfileArray" style="display: flex;flex-direction: column;flex-wrap: wrap; " >
  <div *ngIf="item.value!='NA'" class="smallcard" style="width: 86px;height: 80px;">
<div style="color: #000000; margin: 5px 5px 0px 5px; font-size: 16px;border-radius: 6px 6px 0px 0px; font-weight: 600;"> {{item.name}}</div>
<div style=" color: #6B6B6B; font-size: 18px; margin: 5px 0px 0px 4px;line-height: 1;
font-weight: 600;"> {{item.value}}<span style="font-size: 12px; color: #969696;">{{item.symbol}}</span>
 <br>  <span style="font-size: 14px;color: #969696;"> {{item.date |date: 'dd-MMM-yy'}}</span>
</div>

</div>
</div>
</div>
</div> 
</div>

</div>


     <div   style="height: 55vh; display: flex;flex-direction: row; justify-content: space-between; width: 100%; margin:10px 0px 0px 29px;">
     
        <div style="display: flex;flex-direction: column; width: 35%;">
       <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #f3f3f3;  padding: 14px 11px 14px 11px;">
        <div style="color: #000000; font-size: 18px; font-weight: 600;">Patient Profile</div>
        <div (click)="opensDialog(moreInfoModal, 'moreInfoModal')" style="color: #2052D1; font-size: 18px;font-weight: 600;">Edit</div>
       </div>
       <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 14px 11px 14px 11px;">
        <div style="color: #2E2E2E; font-size: 16px; ">Medical Conditions</div>
        <div style="color: #F95b5b; font-size: 16px;font-weight: 600;">{{medicalConditions}}</div>
       </div>
       <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 14px 11px 14px 11px;">
        <div style="color: #2E2E2E; font-size: 16px; ">Allergies</div>
        <div style="color: #F95b5b; font-size: 16px;font-weight: 600;">{{allergies}}</div>
       </div>
       <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 14px 11px 14px 11px;">
        <div style="color: #2E2E2E; font-size: 16px; ">Recurring Complaints</div>
        <div style="color: #F95b5b; font-size: 16px;font-weight: 600;">{{recurringComplaints}}</div>
       </div>
       <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 14px 11px 14px 11px;">
        <div style="color: #2E2E2E; font-size: 16px;">Accidental/Procedural Information</div>
        <div style="color: #F95b5b; font-size: 16px;font-weight: 600;">{{accidentalHistory}}</div>
       </div>
    </div>
    
    <div style="display: flex;flex-direction: column; width: 35%; margin-left: 10px;">
        <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #f3f3f3;  padding: 14px 11px 14px 11px;">
         <div style="color: #000000; font-size: 18px; font-weight: 600;">Additional Information</div>
         <div style="color: #2052D1; font-size: 18px;font-weight: 600;"></div>
        </div>
        <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 14px 11px 14px 11px;">
            <div style="color: #2E2E2E; font-size: 16px; ">Reaction To Drugs/ Medicine</div>
            <div style="color: #F95b5b; font-size: 16px;font-weight: 600;">{{reactionToMeds}}</div>
           </div>
           <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 14px 11px 14px 11px;">
            <div style="color: #2E2E2E; font-size: 16px; ">Health History</div>
            <div  style="color: #F95b5b; font-size: 16px;font-weight: 600;">{{ additionalInfo? additionalInfo.healthHistory:""}}</div>
           </div>
           <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 14px 11px 14px 11px;">
            <div style="color: #2E2E2E; font-size: 16px; ">Family Medical History</div>
            <div style="color: #F95b5b; font-size: 16px;font-weight: 600;">{{familyHistory}}</div>
           </div>
           <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 14px 11px 14px 11px;">
            <div style="color: #2E2E2E; font-size: 16px; ">Genetic Disorders</div>
            <div style="color: #F95b5b; font-size: 16px;font-weight: 600;">{{additionalInfo?additionalInfo.geneticDisorders:""}}</div>
           </div>
    </div>
    <div  style="display: flex;flex-direction: column;  width: 30%; margin-left: 10px; ">
        <div style="width: 77%; border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #f3f3f3;  padding: 14px 11px 14px 11px;">
            <div style="color: #000000; font-size: 18px; font-weight: 600;">Notes</div>
            <div (click)="saveNotes()" style="color: #2052D1; font-size: 18px;font-weight: 600;">Save</div>
           </div>
           <div style="width: 77%; border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 14px 11px 14px 11px;">
            <textarea style="width: 300px;" placeholder="Create Notes"  cols="40" rows="10" class="notesTextarea" maxlength="500" 
             [(ngModel)]="notes_message">{{notes_message}}</textarea>
           </div>

           <div style="height: 30vh;overflow-y: scroll; width: 77%; border: 1px solid #f3f3f3; display: flex;flex-direction: column;justify-content: space-between; background-color: #fff;  padding: 14px 11px 14px 11px;">
            <div *ngFor="let note of notesArr; let i = index" style=" border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 10px 11px 10px 11px;">
                <div style="color: #000000; font-size: 18px; font-weight: 600;">{{note.note.substring(0,35)}}</div>
                <div (click)="getNotes(note);openDialog(openNotes,'openNotes')" style="color: #2052D1; font-size: 18px;font-weight: 600;">
                    <mat-icon [ngClass]="'blue-icon'">
                        info
                    </mat-icon></div>
               </div>
           </div>
          
        </div>

    

    </div>


    
  
   


</div>

<div  *ngIf="isPediatric" style="display: flex;flex-direction: column; ">
    <div style="display: flex;flex-direction: row; justify-content: space-between; ">
        <div class="boxUiPed" style="margin: 15px 0px 0px 10px; width: 43%;">
            <div style="display: flex;flex-direction: row; justify-content: space-between;">
                <div style="margin: 10px 0px 0px 10px ;font-size: 16px;font-weight: 600; " >Vitals At Birth</div>
                <div style="margin: 10px 10px 0px 0px ;cursor: pointer;" (click)="gotToVisit();"> <mat-icon [ngClass]="'blue-icon'">
                    arrow_right_alt
                </mat-icon></div>
            </div>
            <div style="display:flex;flex-direction: row;">
            <div  class="smallcard" *ngFor="let item of oldOrBirthVitalsArray" style="width: 100px;height: 77px;">
          
                <div style="color: #000000; margin: 5px 0px 0px 5px; font-size: 16px;border-radius: 6px 6px 0px 0px; font-weight: 600;"> {{item.name}}</div>
                <div style=" color: #6B6B6B; font-size: 18px; margin: 6px 0px 0px 10px;"> {{item.value}}<span style="font-size: 12px; color: #969696;">{{item.unit}}</span> </div>
                 <br>
                </div>
                
                      </div>
        </div>
        <div class="boxUiPed" style="margin: 15px 8px 0px 0px; width: 55%; ;">
            <div style="display: flex;flex-direction: row; justify-content: space-between;">
                <div style="margin: 10px 0px 0px 10px ;font-size: 16px;font-weight: 600;">Vitals As Of {{pediatricVisitDate}}</div>
                <div style="margin: 10px 10px 0px 0px; cursor: pointer;"(click)="gotToVisit();">
                    <mat-icon [ngClass]="'blue-icon'">
                        arrow_right_alt
                    </mat-icon></div>
            </div>
            <div style="display:flex;flex-direction: row;">
            <div *ngFor="let item of recentVitalsArray">
                 <div *ngIf="item.value!='NA'" class="smallcard" style="width: 80px;height: 77px;">
                <div   style="display: flex; justify-content: space-between;"> 
                    <div  style="color: #000000; margin: 5px 0px 0px 5px; font-size: 16px;border-radius: 6px 6px 0px 0px; font-weight: 600;">{{item.name}}</div>
                    <div style="cursor: pointer;">
                        <img *ngIf="item.name != 'SPO2'|| item.name!='LMP'" (click)="onClickVitalToShowGraph(item.name)"
                            style="height: 15px; width: 15px; align-self: center; margin-top: 7px;"
                            src="assets/images/share.png" alt="" />
                    </div>
                </div>
                <div   style=" color: #6B6B6B; font-size: 18px; margin: 6px 0px 0px 10px;"> {{item.value}}<span style="font-size: 12px; color: #969696;">{{item.unit}}</span> </div>
                 <br>
                </div>
                </div>
                </div>
        </div>
    </div>

    <div style=" display: flex;flex-direction: row; justify-content: space-between; width: 106%; margin: 7px 0px 0px 14px; height: 35vh;">
        <div style="display: flex;flex-direction: column; width: 33%; ">
            <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #f3f3f3;  padding: 5px 5px 5px 5px;">
             <div style="color: #000000; font-size: 18px; font-weight: 600;">Profile</div>
            
            </div>
            <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 5px 5px 5px 5px;">
                <div style="color: #2E2E2E; font-size: 16px; ">Type Of Delivery</div>
                <div style="color: #909090; font-size: 16px;font-weight: 600;">{{typeOfDelivery}}</div>
               </div>
               <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 5px 5px 5px 5px;">
                <div style="color: #2E2E2E; font-size: 16px; ">Date Of Birth</div>
                <div style="color: #909090; font-size: 16px;font-weight: 600;">{{dob}}</div>
               </div>
               <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 5px 5px 5px 5px;">
                <div style="color: #2E2E2E; font-size: 16px; ">EDOB</div>
                <div style="color: #909090; font-size: 16px;font-weight: 600;">{{eDob}}</div>
               </div>
               <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 5px 5px 5px 5px;">
                <div style="color: #2E2E2E; font-size: 16px; ">GA</div>
                <div style="color: #909090; font-size: 16px;font-weight: 600;">{{gestationalAge.w}}w {{gestationalAge.d}}d</div>
               </div>
               <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 5px 5px 5px 5px;">
                <div style="color: #2E2E2E; font-size: 16px; ">Status</div>
                <div style="color: #F95b5b; font-size: 16px;font-weight: 600;">
                    <div *ngIf="isHighRisk==true" (click)="open(highRiskModal)"
                    style="font-size: 16px;color: #FF0000;text-decoration: underline; cursor: pointer;">High
                    Risk</div>
                </div>
               </div>
        </div>
        <div style="display: flex;flex-direction: column; width: 33%; margin-left: 10px;">
       <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #f3f3f3;  padding: 5px 5px 5px 5px;">
        <div style="color: #000000; font-size: 18px; font-weight: 600;">Patient Profile</div>
        <div (click)="opensDialog(moreInfoModal, 'moreInfoModal')" style="color: #2052D1; font-size: 18px;font-weight: 600;">Edit</div>
       </div>
       <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 5px 5px 5px 5px;">
        <div style="color: #2E2E2E; font-size: 16px; ">Medical Conditions</div>
        <div style="color: #F95b5b; font-size: 16px;font-weight: 600;">{{medicalConditions}}</div>
       </div>
       <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 5px 5px 5px 5px;">
        <div style="color: #2E2E2E; font-size: 16px; ">Allergies</div>
        <div style="color: #F95b5b; font-size: 16px;font-weight: 600;">{{allergies}}</div>
       </div>
       <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 5px 5px 5px 5px;">
        <div style="color: #2E2E2E; font-size: 16px; ">Recurring Complaints</div>
        <div style="color: #F95b5b; font-size: 16px;font-weight: 600;">{{recurringComplaints}}</div>
       </div>
       <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 5px 5px 5px 5px;">
        <div style="color: #2E2E2E; font-size: 16px;">Accidental/Procedural Information</div>
        <div style="color: #F95b5b; font-size: 16px;font-weight: 600;">{{accidentalHistory}}</div>
       </div>
       <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 5px 5px 5px 5px;">
        <div style="color: #2E2E2E; font-size: 16px;">Reaction To Drugs/ Medicine</div>
        <div style="color: #F95b5b; font-size: 16px;font-weight: 600;">{{reactionToMeds}}</div>
       </div>
    </div>
    <div  style="display: flex;flex-direction: column;  width: 35%; margin-left: 10px; ">
        <div style="width: 77%; border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #f3f3f3;  padding: 14px 11px 14px 11px;">
            <div style="color: #000000; font-size: 18px; font-weight: 600;">Notes</div>
            <div (click)="saveNotes()" style="color: #2052D1; font-size: 18px;font-weight: 600;">Save</div>
           </div>
           <div style="width: 77%; border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 14px 11px 14px 11px;">
            <textarea style="width: 365px;" placeholder="Create Notes"  cols="40" rows="10" class="notesTextarea" maxlength="500" 
             [(ngModel)]="notes_message">{{notes_message}}</textarea>
           </div>

           <div style="height: 30vh;overflow-y: scroll; width: 77%; border: 1px solid #f3f3f3; display: flex;flex-direction: column;justify-content: space-between; background-color: #fff;  padding: 14px 11px 14px 11px;">
            <div *ngFor="let note of notesArr; let i = index" style=" border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 10px 11px 10px 11px;">
                <div style="color: #000000; font-size: 18px; font-weight: 600;">{{note.note.substring(0,35)}}</div>
                <div (click)="getNotes(note);openDialog(openNotes,'openNotes')" style="color: #2052D1; font-size: 18px;font-weight: 600;">
                    <mat-icon [ngClass]="'blue-icon'">
                        info
                    </mat-icon></div>
               </div>
           </div>
          
        </div>
   
    </div>

    <div style=" display: flex;flex-direction: row;  width: 100%; margin: 10px 0px 0px 14px; height: 25vh;">
        <div style="display: flex;flex-direction: column; width: 34%; ">
            <div style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #f3f3f3;  padding: 5px 5px 5px 5px;">
             <div style="color: #000000; font-size: 18px; font-weight: 600;">Next Vaccinations</div>
             <div (click)="gotTovaccTab()"   style="cursor: pointer; color: #2052D1; font-size: 18px;font-weight: 600;"> <mat-icon [ngClass]="'blue-icon'">
                arrow_right_alt
            </mat-icon></div>
            </div>
            <div style="height: 15vh; overflow-y: scroll;">
            <div *ngFor="let item of pendingVaccineObj.data; let i = index" style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 5px 5px 5px 5px;">
                <div style="color: #2E2E2E; font-size: 16px;font-weight: 600; ">  <span [ngClass]="item.administeredStatus ? 'activedot' :'dot'"></span><span style="margin: 0px 0px 0px 9px;">{{item.name}}</span> </div>
                <div style="color: #CACACA; font-size: 16px;font-weight: 600;">{{item.dueDate}}</div>
               </div>
             
            </div>
            
        </div>
        <div  style="display: flex;flex-direction: column; width: 34%; margin-left: 10px;">
       <div *ngIf="milestoneshowFlag" style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #f3f3f3;  padding: 5px 5px 5px 5px;">
        <div style="color: #000000; font-size: 18px; font-weight: 600;">Developmental Milestones</div>
        <div (click)="openMilestoneTab();" style="cursor: pointer; color: #2052D1; font-size: 18px;font-weight: 600;"> 
            <mat-icon [ngClass]="'blue-icon'">
                arrow_right_alt
            </mat-icon>
        </div>
       </div>
       <div *ngIf="milestoneshowFlag" style="height: 15vh; overflow-y: scroll;">
       <div *ngFor="let item of dueMilestoneArray; let i = index" style="border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 5px 5px 5px 5px;">
        <div style="color: #2E2E2E; font-size: 16px;font-weight: 600; ">  <span [ngClass]="item.status=='2' ? 'activedot' :'dot'"></span><span style="margin: 0px 0px 0px 9px;"> {{item.start_month}} To {{item.end_month}} M</span> </div>
        <!-- <div style="color: #2E2E2E; font-size: 16px; font-weight: 600; "><span class="dot"></span> <span style="margin: 0px 0px 0px 9px;"></span> </div> -->
        <div style="color: #B7B7B7; font-size: 16px;font-weight: 600;">{{item.name}}</div>
       </div>
       </div>
      
     
      
    </div>
    
     <!-- <div style="display: flex;flex-direction: column; width: 33%; margin-top: -73px;">
        <div style="margin: 17px 24px 0px 15px; height: 50vh;overflow-y: scroll;">
            <div *ngFor="let note of notesArr; let i = index" style="  width: 100%;flex-wrap: wrap; border: 1px solid #f3f3f3; display: flex;flex-direction: row;justify-content: space-between; background-color: #fff;  padding: 10px 11px 10px 11px;">
             <div  style="color: #000000; font-size: 18px; font-weight: 600; width:90%;">{{note.note.substring(0,35)}}</div>
             <div (click)="getNotes(note);openDialog(openNotes,'openNotes')" style="cursor: pointer; color: #2052D1; font-size: 18px;font-weight: 600; width: 10%;">
                 <mat-icon [ngClass]="'blue-icon'">
                     info
                 </mat-icon></div>
            </div>
         </div>
     
     </div>  -->
   
    </div>

</div>

<div style="display: flex; flex-direction: row;  margin-left: 15px;">
    <button   (click)="opensDialog(link_patient_modal);  closeLinkData();" class="btn btn-secondary"
    style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

    <mat-icon [ngClass]="'blue-icon'">
        link
    </mat-icon>

    <span style="margin-left: 4px;">Link Patient</span>
</button>
<button *ngIf="showDelete" (click)="opensDialog(addDeletePatient);"  class="btn btn-secondary"
style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

<mat-icon [ngClass]="'blue-icon'">
    delete
</mat-icon>

<span style="margin-left: 4px;">Delete Patient</span>
</button>
<button (click)="openHistoryTab()"  class="btn btn-secondary"
style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

<mat-icon [ngClass]="'blue-icon'">
history
</mat-icon>

<span style="margin-left: 4px;">History</span>
</button>

<button  *ngIf="isPediatric && !allHeelPrickTest.length" class="btn btn-secondary" (click)="openDialog(addUploadModal ,'addUploadModal');closeUploadModal(); clickHeelDoc()"
style="cursor: pointer; margin-top: 6px; display: flex; justify-content: center; align-items: center;">

<mat-icon *ngIf="!allHeelPrickTest.length" [ngClass]="'blue-icon'" >
upload
</mat-icon>


<span style="margin-left: 4px;">New Born Screening</span>

</button>
<button  *ngIf="isPediatric && allHeelPrickTest.length" class="btn btn-secondary" (click)="onClickViewHeelReports(heelPrickUrl)"
style="cursor: pointer; margin-top: 6px; display: flex; justify-content: center; align-items: center;">

<!-- <mat-icon *ngIf="!allHeelPrickTest.length" [ngClass]="'blue-icon'" (click)="openDialog(addUploadModal ,'addUploadModal');closeUploadModal(); clickHeelDoc()">
upload
</mat-icon> -->

<mat-icon *ngIf="allHeelPrickTest.length" [ngClass]="'blue-icon'"  style="cursor: pointer;">
    visibility
    </mat-icon>
<span style="margin-left: 4px;">New Born Screening</span>

</button>
<!-- <div *ngIf="isPediatric" style="display: flex;flex-direction: row; justify-content: space-between; padding: 8px 16px;border-radius: 6px; border-style: dashed; border-color: #C4D4E5; width: 18%;">
    <div style="color:#4A4A4A; font-size: 16px;font-weight: 600;"> New Born Screening</div>
    <div *ngIf="!allHeelPrickTest.length"
    (click)="openDialog(addUploadModal ,'addUploadModal');closeUploadModal(); clickHeelDoc()" style="cursor: pointer; color: #7495C7; font-size: 16px;font-weight: 600;"> UPLOAD</div>

    <div *ngIf="allHeelPrickTest.length" (click)="onClickViewHeelReports(heelPrickUrl)" style="cursor: pointer; color: #7495C7; font-size: 16px;font-weight: 600;"> VIEW </div>

</div> -->
</div>


</div>

<ng-template #moreInfoModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">More Information</h4>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="closeDialog('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="width:50vw;">

        <div style="display: flex;flex-direction: column; padding-top: 10px;">

            <mat-form-field appearance="outline">
                <mat-label>Medical Conditions</mat-label>
                <input matInput [(ngModel)]="medicalConditions" placeholder="Enter Medical Conditions">
               
              </mat-form-field>
              <mat-chip-list aria-label="Fish selection" [multiple]="true" style="margin:10px 6px;">
                <mat-chip class="price-filter" style="font-size: 16px; background-color: #E8F7F5;" #ref="matChip"
                    *ngFor="let item of medicalConditionArray;let i=index" (click)='selectmedicalConditions(item);'
                    [value]="item">
                    {{item.name}}
                </mat-chip>
    
            </mat-chip-list>


              <mat-form-field appearance="outline">
                <mat-label>Allergies</mat-label>
                <input matInput [(ngModel)]="allergies" placeholder="Enter Allergies">
               
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Recurring Complaints</mat-label>
                <input matInput [(ngModel)]="recurringComplaints" placeholder="Enter Recurring Complaints">
               
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Accidental / Procedural
                    History</mat-label>
                <input matInput [(ngModel)]="accidentalHistory" placeholder="Enter Accidental / Procedural
                History">
               
              </mat-form-field>
        </div>
     
        <div style="display: flex;width: 100%;align-items: flex-end;justify-content: flex-end;">
            <button type="submit" class="new_btn"
                style=" display: flex;text-transform: capitalize;align-items: center;justify-content: center;"
                (click)="closeDialog('Cross click');saveMoreInfo()">Save</button>
        </div>
    </div>


</ng-template>

<ng-template #addDeletePatient let-modal>
    <div class="modal-header" style="border-bottom: 0px;">
        <h4 class="modal-title" id="modal-basic-title">Delete Patient</h4>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="closeDialog('Cross click');">
            <span aria-hidden="true">&times;</span>
        </button>

    </div>
    <div class="modal-title">
        <div style="font-size: 16px;">
            Are you sure you want to delete the selected Patient?
        </div>


        <!-- <div *ngIf="requisition.doctorId===currentDoctorObj.id" class="rightSideTextOnCard"(click)="onClickRequisitionDelete(requisition)" style="color: #ff0000;">Delete</div>    -->

    </div>



    <div class="modal-footer">

        <button type="button" style="
    border-color: #6c757d; color:#6c757d; border-radius: 4px;" class="btn btn-outline-secondary"
            (click)="closeDialog('addDeletePatient ')">Cancel</button>
        <button type="button" style="background-color: #ff0000; color: '#fff'; border-radius: 4px;"
            class="new_delete_btn" (click)=" onClickDeletePatient();closeDialog('addDeletePatient')">Delete</button>


    </div>
</ng-template>


<ng-template #link_patient_modal let-modal>
    <div class="modal-header" style="border-bottom: 0px;padding-bottom: 80px;">

        <div style="display: flex;flex-direction: row;">

            <h4 class="modal-title" id="modal-basic-title">Link Patient

                <button type="button" class="close" style="outline: none; border-width: 0px;padding-top: 25px;"
                    aria-label="Close" (click)="closeDialog('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
                <p style="color: #798197;font-size: 16px;">Enter doctor complete mobile number</p>
                <div class="inventory-outer  pl-1 fs-18 mb-0 pb-0" style="padding-top: 20px;">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Doctor's Full Mob No</mat-label>
                        <input matInput type="text" [(ngModel)]="searchText" (keyup)="searchDoctors()"
                        (keypress)="validateNumber($event)" [maxLength]='10'>
                        <button style="display: flex;flex-direction: row;" *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear" (click)="searchText=''">
                          <mat-icon>close</mat-icon>
                          <!-- <mat-icon matSuffix>search</mat-icon> -->
                        </button>
                       
                      </mat-form-field>

                </div>

            </h4>

        </div>

    </div>
    <div class="modal-body ">

        <div style="margin-top: -45px;">
            <div *ngIf="selectedDoctor"
                style="font-size: 18px; font-weight: bold; color: #333; display: flex;flex-direction: row; justify-content: center;">
                <figure>

                    <img width="40" height="40" style="border: solid 1px #ccc;border-radius: 81px;"
                        src="assets/images/profile_placeholder.png" alt="" class="navbar-avatar rounded-circle" />
                </figure>
            </div>
            <div style="display: flex;flex-direction: row; justify-content: center;">

                <div style="color: black;font-weight: bold; margin-top: 2px;">{{docName}}</div>
            </div>
            <div style="display:flex;justify-content: center; color: #8B8B8B;margin-top: 2px;">{{docEmail}}</div>
        </div>
        <div *ngIf="selectedDoctor"
            style="display: flex; justify-content: center;padding-top: 5px;padding-bottom: 40px;">
            <div *ngIf="selectedDoctor" (click)="
    linkDoctorPatient();closeDialog();" class="new_btn">Link</div>
        </div>
        <div *ngIf="!selectedDoctor">
            <div style="text-align: center;padding-bottom: 25px;">
                <img style="justify-content: center;align-items: center; width: 120px;"
                    src="assets/images/placeholder.svg" alt="" />

                <div style="font-size: 20px; color: #333333; font-weight: bold;">No doctor found</div>
                <div style="font-size: 18px; color: grey;">Try to enter complete mobile number.</div>
            </div>
        </div>
    </div>

</ng-template>
<ng-template #openNotes let-modal>

    <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <h4  style="color:#4687BF ;">Notes</h4>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="closeDialog('Cross click')">
            <span aria-hidden="true" style="font-size: 30px;">&times;</span>
        </button>
        <!-- <button (click)="closeDialog()" class="cancelUibtn">Close</button> -->
    
      </div>
      <br>
      <div style=" width: 40vw; " >
        <div >
            <div class="card" style="width: 90%;align-self: center;padding: 10px;">
                <div style="display: flex; flex-direction: row; margin-left: 10px; ">
                    <div style="display: flex; flex-direction: row;">
                        <div class="leftSideTextOnCard">Dr. {{docName}}</div>
                    </div>
                    <div class="rightSideTextOnCard">{{docDate}}</div>
                </div>
                <div style="margin-top: 10px;margin-left: 15px;">
                    <div style="font-size: 16px;color: #8B8B8B; word-break: break-all !important">{{docNotes}}
                    </div>
                </div>
            </div>
        </div>
  
        </div>
    <!-- <div style="margin-left: 16px; width: 65%;">
        <div *ngFor="let note of notesArr; let i = index">
            <div class="card" style="width: 90%;align-self: center;padding: 10px;">
                <div style="display: flex; flex-direction: row; margin-left: 10px; ">
                    <div style="display: flex; flex-direction: row;">
                        <div class="leftSideTextOnCard">Dr. {{note.docName}}</div>
                    </div>
                    <div class="rightSideTextOnCard">{{note.date}}</div>
                </div>
                <div style="margin-top: 10px;margin-left: 15px;">
                    <div style="font-size: 16px;color: #8B8B8B; word-break: break-all !important">{{note.note}}
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    </ng-template>

<ng-template #highRiskModal let-modal>
    <div class="modal-header" style="border-bottom: 0px; ">
        <h5 class="modal-title" id="modal-basic-title" style="display: flex;flex-direction: row; color: #333333;">
            High-Risk Category:



        </h5>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span style="margin-right: 6px;" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="
    padding-left: 5px;
    margin-top: 14px;
    margin-bottom: 4px;
    margin-left: 8px; font-size: 18px;">
            <div class="row" *ngFor="let item of highRiskCategory ">

                -{{item}}

            </div>
        </div>
    </div>

    <div style="display: flex;flex-direction: row;justify-content:flex-end;padding-bottom: 10px;">
        <div (click)="modal.dismiss();" class="btn-type-filled">Ok</div>

    </div>
</ng-template>


<ng-template #addUploadModal let-modal>
    <div style="display: flex;flex-direction: column;">
        <div style="font-size: 24px; display: flex;flex-direction: row;justify-content: space-between;
color: #333;
width: 400px;
font-weight: bold;">Upload Documents
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog();closeAddReceiptModal();closeUploadModal()">
                <span aria-hidden="true" style="font-size: 40px;color: #000;" (click)="closeDialog()">&times;</span>
            </button>
        </div>
        <div style="font-size: 16px;
color: #798197;
 width: 400px;
font-weight: bold;">Upload health reports and related documents as photos,images and
            PDFs.</div>

    </div>


    <div style="display: flex;flex-direction: column; margin-top: 50px 0px;">
        <mat-form-field style="width: 400px;">
            <mat-label style="font-size: 15px;">Document Types
            </mat-label>
            <mat-select [value]="dose" [(ngModel)]="selectedDocumentType"
                (selectionChange)="onChangeDoc($event.value)">
                <mat-option *ngFor="let docType of documentType" [value]="docType">
                    {{docType}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 400px">
            <mat-label style="font-size: 16px;">Created By</mat-label>
            <input matInput [(ngModel)]="notes">
        </mat-form-field>
        <mat-form-field style="width: 400px">
            <mat-label style="font-size: 15px;">Document On</mat-label>
            <input matInput [max]="date" [min]="minDate" [matDatepicker]="picker" [(ngModel)]="createdOnRaw">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

        </mat-form-field>

    </div>


    <div style="display: flex;flex-direction: column;">
        <div style="font-size: 18px; display: flex;flex-direction: row;justify-content: space-between;
    color: #333;
    width: 100%;
    font-weight: bold;">
            Select Document(Max:10 mb)
        </div>
        <div style="font-size: 16px;
    color: #798197;
    width: 100%;
    font-weight: bold;">Uploaded documents are shared with your doctor</div>

    </div>
    <div class="modal-body modal-lg">
        <!-- <p style="color: #777477;font-size: 14px;">Upload health reports and related documents as photos,images and
        PDFs.</p> -->
        <div>

            <div style="display: flex; justify-content: center;">
                <div for="fileDropRef" style="margin-left: 5px;">

                    <div class="container" appDnd (fileDropped)="onFileDropped($event)"
                        style="margin-left: 5px;width: 400px;height: 256px;">
                        <input type="file" #fileDropRef id="fileDropRef"
                            (change)="fileBrowseHandler($event.target.files)" />

                        <img src="assets/images/new_upload.svg" alt="" width="90" height="90" />

                        <div style="font-weight: 600; font-size: 16px; color: #717887;">Drag and drop pdf file
                            here(Max,'10'mb)</div>
                        <h3>or</h3>
                        <h6 style="color:red">{{report_message}}</h6>


                        <div style="color: green;">{{fileInfo}}</div>
                        <label class="btnNewStyle" for="fileDropRef">Browse for file</label>
                    </div>

                    <div class="files-list">
                        <div class="single-file" *ngFor="let file of files; let i = index">
                            <div class="file-icon" style="width: 50px">
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 58 58"
                                    style="enable-background:new 0 0 58 58;" xml:space="preserve">
                                    <polygon style="fill:#EDEADA;" points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
                                    <g>
                                        <path style="fill:#CEC9AE;"
                                            d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
                                        <path style="fill:#CEC9AE;"
                                            d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
                                        <path style="fill:#CEC9AE;"
                                            d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
                                        <path style="fill:#CEC9AE;"
                                            d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
                                        <path style="fill:#CEC9AE;"
                                            d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
                                    </g>
                                    <polygon style="fill:#CEC9AE;" points="37.5,0 37.5,14 51.5,14 " />
                                </svg>
                            </div>
                            <div class="info">
                                <h4 class="name">
                                    {{ file?.name }}
                                </h4>
                                <p class="size">
                                    {{ formatBytes(file?.size) }}
                                </p>
                                <app-progress [progress]="file?.progress"></app-progress>
                            </div>

                            <div class="delete" (click)="deleteFile(i)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
                                    <path fill="#B1B1B1" fill-rule="nonzero"
                                        d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
                                </svg>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div style="display: flex; height: 100%; width: 100%; justify-content: flex-end;">
            <button (click)="uploadDocument();closeDialog();" style="background-color: #5D86CC;
        padding: 8px 16px;
        border-radius: 6px;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        border: 0px;
        display: flex;
        outline: none;">Upload</button>
        </div>
    </div>


</ng-template>

<ng-template #viewHeelReportsModal let-modal>
    <div>
        <div class="modal-header" style="border-bottom: none;">
            <div style="display:flex; flex-direction: row; justify-content: space-between; flex: 1;">
                <h4 class="modal-title" id="modal-basic-title">View Report</h4>
                <div style="display: flex;flex-direction: row;">

                    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                        (click)="closeDialog();">
                        <span aria-hidden="true" style="font-size: 40px;color: #000;">&times;</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-body">
            <div style="display: flex; height: 70vh; margin-top: 0px;width: 70vw;">
                <embed [attr.src]="heelreportsPdfURL" type="application/pdf" width="100%" height="100%">
            </div>
            <div style="display: flex;flex-direction: row;justify-content: flex-end; flex: 1; margin-top: 10px;">
                <div (click)="shareWithOtherHeelPrick(); closeDialog();" class="quickrx">
                    <img style="height: 25px; margin-left: 0x; width: 20px;"
                        src="assets/images/whatsapp-icon-seeklogo.com.svg" alt="" /><span
                        style="margin-left: 5px;">Share With Other</span>
                </div>
            </div>


        </div>

    </div>
</ng-template>
<ng-template #addVitalsModal let-modal>
    <div style="height:100vh;">


        <app-add-vitals [addVisit]="true" [patientResponse]="patientResponse" [isPediatric]="isPediatric"
            [visitDate]="visitDate"></app-add-vitals>

    </div>

</ng-template>

<ng-template #editVitalsModal let-modal>
    <div style="height:100vh;">
        <app-add-vitals [addVisit]="true" [patientResponse]="patientResponse" [isPediatric]="isPediatric"
            [visitDate]="visitDate"></app-add-vitals>
    </div>
</ng-template>


<ng-template #viewReportsModal let-modal>
    <div>
        <div class="modal-header" style="border-bottom: none;">
            <h4 class="modal-title" id="modal-basic-title">View Report</h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog();">
                <span aria-hidden="true" style="font-size: 40px;color: #000;">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="display: flex; height: 70vh; margin-top: 0px; width: 70vw;">
                <embed [attr.src]="reportsPdfURL" type="application/pdf" width="100%" height="100%">
            </div>


        </div>

    </div>
</ng-template>

