<div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="showLoading">
  <label for="" class="loader"></label>
</div>



<app-top-info-navbar [selectedIndex]='0' [parentForm]="'QRX'"></app-top-info-navbar>

<div style="height: 100vh; width: 100%; display: flex;flex-direction: row; overflow: scroll;">

  <mat-horizontal-stepper [@.disabled]="true" (selectionChange)="setIndex($event)" #stepper
    style="position: fixed;overflow: scroll;">

    <mat-step style="display: flex;flex-direction: row;justify-content: center; width: 30%;">
      <ng-template matStepLabel style="font-size: 18px; font-family: 'Mukta', sans-serif;font-weight: 600;">Complaints
      </ng-template>
      <div style=" display: flex;flex-direction: row; width: 100%; height: 100vh;">
        <div style="height: 100%; width: 50%; display: flex; overflow: scroll; ">
          <!-- CHIEFCOMPLAINTS SECTION -->
          <div>


            <div class="headingflex" style="align-items: center; justify-content: space-between; ">
              <div style="font-weight: 600; font-size: 20px; ">Chief Complaints</div>
              <!-- <div>
                <div *ngIf="visitFlag" (click)="goToVital('edit');"
                  style="color:#2596be;text-decoration: underline;cursor: pointer; font-size: 18px;padding-right: 0px; ">
                  Todays Vitals</div>
                <div *ngIf="!visitFlag" (click)="goToVital('add');"
                  style="color:#F57F7F;text-decoration: underline;cursor: pointer; font-size: 18px;padding-right: 0px; ">
                  Add Vitals?</div>
              </div> -->
              <div style="display:flex; flex-direction: row;">

                <div id="searchbox" class="form-control searchBox" placeholder="Start typing to search" name="sb"><i
                    class="fa fa-search" style="color: #bfbebf; padding: 7px;"></i>

                  <input type="text" [(ngModel)]="searchString"
                    (keyup)="searchComplaints($event.target.value, 'complaints')" placeholder="Search"
                    style="padding: 3px; border: none; outline: none;width: 190px;">
                  <i class="fa fa-close" (click)="closeSearch()" aria-hidden="true"
                    style="color: #bfbebf;padding: 7px; cursor: pointer;"></i>
                </div>
                <button *ngIf="!isAddButton" mat-raised-button disabled
                  style="height: 40px;margin-left: 9px;margin-top: 3px; font-family: 'Mukta', sans-serif;">
                  +New</button>

                <button *ngIf="isAddButton" (click)="searchComplaintAdd();"
                  style="background-color: #5D86CC; color: #fff; height: 40px;margin-left: 9px;margin-top: 3px;font-size: 16px;"
                  mat-raised-button>+New</button>
              </div>
            </div>
            <div style="display: flex;flex-direction: column;justify-content: space-between;height: 72%;">
              <div style="display: flex; flex-direction: column; padding: 0px 26px;">
                <div style="display: flex;flex-direction: column; ">
                  <div style=" width: 100%; height: 100%; overflow-y: scroll; scrollbar-width: none;margin-top: 0px; ">
                    <!-- <div *ngIf="!specialVisitTypeFlag" class="dataContainer" style="width: 48vw;">
                      <div *ngFor="let item of allComplaints.slice(4, 18); let i = index"
                        (click)="selectComplaintModalValue(item,i);">
                        <app-cutom-card [item]="item.complaint" [selected]="item.selected"
                          [specialVisitType]="item.specialVisitType"
                          style="display:flex;flex-direction: row;flex-wrap: wrap;"></app-cutom-card>
                      </div>
                    </div> -->
                    <div   class="dataContainer" style="width: 48vw;">
                      <div  *ngFor="let item of allComplaints.slice(0, 14); let i = index"
                        (click)="selectComplaintModalValue(item,i);">
                        <app-cutom-card  [item]="item.complaint" [selected]="item.selected"
                          [specialVisitType]="item.specialVisitType" 
                          style="display:flex;flex-direction: row;flex-wrap: wrap;"></app-cutom-card>
                          
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
              <!-- <div
                  style="width: 46vw; display: flex;flex-direction: row; justify-content: flex-end;margin-top: 0px; ">
                </div> -->


              <h5 style="padding: 0px 16px; margin-top: 26px;" *ngIf="detailsComplaintsFlag">Complaint Details <span
                  style="color: grey; font-weight:normal">(To refine your complaints)</span> </h5>
              <div *ngIf="detailsComplaintsFlag" style="width: 100%; padding: 0px 16px;">
                <div style=" border: 1px dashed #6fbdbb;padding: 8px;
                width: 98%;
               border-radius: 0.6rem;overflow-y: scroll; scrollbar-width: none;
                font-size: 16px;" placeholder="Start typing to search" name="sb">
                  <!-- <h5>Complaint Details</h5> -->
                  <div>
                    <div
                      style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 0px; margin-bottom: 0px; width: 45vw; margin-left: 0px; ">
                      <div *ngFor="let item of complaintDetailsForFever; let i = index"
                        class="searchable-component-style" (click)="onSelectSinceComplaints(item,i)"
                        style="background-color:#fff; margin-top: -1px; height: 32px; ;font-size: 16px;">

                        <img style="margin: 0px 8px 0px 0px;width:22px; color:#007d7e;"
                          src="assets/images/newAddButton.svg" class="largerCheckbox" />
                        <div>{{item.name}}</div>
                      </div>

                      <div class="searchable-component-style"
                        style="background-color:#E5EFFA; margin-top: -1px; height: 20px; ;font-size: 16px;">
                        Since &nbsp; <input type="number" [(ngModel)]="sinceDays"
                          style="width:35px; border-radius: 5px;"> &nbsp; Days &nbsp; <img
                          (click)="onSelectSinceComplaintsDays(sinceDays)"
                          style="margin: 0px 8px 0px 0px;width:25px; color:#007d7e;"
                          src="assets/images/newAddButton.svg" class="largerCheckbox" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h5 *ngIf="moreSymptoms" style="padding: 0px 16px; margin-top: 16px;"> More Symptoms</h5>
              <div *ngIf="moreSymptoms" style="width: 100%; padding: 0px 16px;">
                <!-- <h5 style="width: 45vw;
              justify-content: flex-start;
              align-items: center;  font-size: 18px; ">More Symptoms</h5> -->
                <div style="border: 1px dashed #6fbdbb;padding: 8px; width: 98%;
             border-radius: 0.6rem;overflow-y: scroll; scrollbar-width: none;
              font-size: 16px;" placeholder="Start typing to search" name="sb">

                  <div
                    style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 0px; margin-bottom: 0px; width: 45vw; margin-left: 0px; ">
                    <div *ngFor="let item of suggestComplaints; let i = index" class="searchable-component-style"
                      (click)="onSelectSuggestedComplaints(item,i)"
                      style="background-color:#fff; margin-top: -1px; height: 32px; ;font-size: 18px;">
                      <!-- <input *ngIf="item.selected!=true" type="checkbox" style="margin: 0px 8px 0px 0px;width: 22px;"
                        class="largerCheckbox">
                      <img *ngIf="item.selected==true" style="margin: 0px 8px 0px 0px;width:22px; color:#007d7e;"
                        src="assets/images/checkbox_material.svg" class="largerCheckbox" /> -->
                      <img style="margin: 0px 8px 0px 0px;width:22px; color:#007d7e;"
                        src="assets/images/newAddButton.svg" class="largerCheckbox" />
                      <div>{{item.complaint}}</div>
                    </div>
                  </div>
                </div>
              </div>


              <div style="display: flex;flex-direction: column;">
                <h5 style="padding: 0px 16px; margin-top: 16px;"> Chief Complaints, Symptoms</h5>
                <div style="padding: 0px 16px;">

                  <div
                    style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 0px; margin-bottom: 0px;">

                    <div *ngFor="let item of  selectedComplaints; let i = index" class="searchable-component-style"
                      class="selectComplanits tagsContainer">


                      {{item}} <span (click)="removeSelectedComplaints(item)" class="crossButton">&times;</span>
                    </div>
                  </div>


                </div>
                <div style="height: 30px;"></div>
              </div>

            </div>
          </div>
          <!-- CHIEFCOMPLAINTS SECTION END -->
        </div>
        <!-- COM-1 -->

      </div>
    </mat-step>
    <mat-step>
      <ng-template (click)="VitalOldRxClicked()" matStepLabel>Vital</ng-template>

      <div style="height: 100vh; width: 100%;  display: flex;flex-direction: row;">
        <div style="height: 100%;width: 50%;padding-left: 30px;">
          <app-add-vitals #AddVitalsComponent [appionmentMessage]="appionmentMessage" [addVisit]="false" [isPediatric]="isPediatric" [visitDate]="visitDate">
          </app-add-vitals>
        </div>
      </div>
      <!-- <div style="height: 100vh; width: 100%;  display: flex;flex-direction: row;"> -->
      <!-- <div style="height: 100vh;width: 50%;background-color: red;">
          <app-add-vitals></app-add-vitals>
      
          <div>
        
          </div>
        </div>
      <div style="height: 100vh;width: 50%;">

      </div> -->
      <!-- COM-2 -->


      <!-- </div> -->
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>On Examination</ng-template>
      <div style="height: 100vh; width: 100%;  display: flex;flex-direction: row;">
        <div style="height: 100%;width: 50%">
          <div>
            <div class="headingflex" style="justify-content: space-between; align-items: center; height: 10%;">
              <div style="font-size: 20px;font-weight: 600;">On Examination</div>
              <!-- <div>
                <div *ngIf="visitFlag" (click)="goToVital('edit');"
                  style="color:#2596be;text-decoration: underline;cursor: pointer; font-size: 18px;padding-right: 0px; ">
                  Todays Vitals</div>
                <div *ngIf="!visitFlag" (click)="goToVital('add');"
                  style="color:#F57F7F;text-decoration: underline;cursor: pointer; font-size: 18px;padding-right: 0px; ">
                  Add Vitals?</div>
              </div> -->
              <div style="display:flex; flex-direction: row;">
                <div>
                  <div id="searchbox" class="form-control searchBox" placeholder="Start typing to search" name="sb"><i
                      class="fa fa-search" style="color: #bfbebf;padding: 7px;"></i>

                    <input type="text" [(ngModel)]="searchStringOnExamination"
                      (keyup)="searchOnExamination($event.target.value, 'OnExamination');" placeholder="Search"
                      style="padding: 3px; border: none; outline: none;width: 190px;">
                    <i class="fa fa-close" (click)="closeSearchOnExamination()" aria-hidden="true"
                      style="color: #bfbebf;padding: 7px; cursor: pointer;"></i>
                  </div>
                </div>
                <button *ngIf="!isAddButton" mat-raised-button disabled
                  style="height: 40px;margin-left: 9px;margin-top: 3px; font-family: 'Mukta', sans-serif;">
                  +New</button>
                <button *ngIf="isAddButton" (click)="searchDataOnExamination();" mat-raised-button
                  style="background-color: #5D86CC; color: #fff; font-size: 16px; height: 40px; margin-left: 9px;margin-top: 3px;">+New</button>
              </div>
            </div>
            <div
              style="display: flex; flex-direction: column; justify-content: space-between; height: 72vh; padding: 0px 26px;">
              <div style="display: flex;flex-direction: column; ">
                <div style=" width: 100%; height: 30vh; overflow-y: scroll; scrollbar-width: none;">
                  <div class="dataContainer">
                    <div *ngFor="let item of allOnExamination; let i = index"
                      (click)="selectOnExaminationValue(item,i)">
                      <app-cutom-card [item]="item.examination" [selected]="item.selected"
                        style="display:flex;flex-direction: row;flex-wrap: wrap;"></app-cutom-card>
                    </div>
                  </div>

                </div>
                <div
                  style="width: 46vw; display: flex;flex-direction: row; justify-content: flex-end;margin-top: 0px; ">
                </div>
                <div *ngIf="moreSymptoms" style="width: 100%;height: 20vh;">
                  <h5 style="width: 45vw;
            justify-content: flex-start;
            align-items: center; padding: 0px 0px; font-size: 18px; margin-top: 20px; "></h5>
                  <div style=" border: 1px dashed #6fbdbb;padding: 8px;
         height:15vh;  margin-top: 10px;border-radius: 0.6rem;overflow-y: scroll; scrollbar-width: none;
            font-size: 16px;" placeholder="Start typing to search" name="sb">
                    <div
                      style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 0px; margin-bottom: 0px; width: 45vw; margin-left: 0px; ">
                      <div *ngFor="let item of suggestOnExamination; let i = index" class="searchable-component-style"
                        (click)="selectSuggestedOnExamination(item,i)"
                        style="background-color:#fff; margin-top: -1px; height: 32px; ;font-size: 18px;">
                        <!-- <input *ngIf="item.selected!=true" type="checkbox" style="margin: 0px 8px 0px 0px;width: 22px;"
                          class="largerCheckbox">
                        <img *ngIf="item.selected==true" style="margin: 0px 8px 0px 0px;width:22px; color:#007d7e;"
                          src="assets/images/checkbox_material.svg" class="largerCheckbox" /> -->
                        <img style="margin: 0px 8px 0px 0px;width:22px; color:#007d7e;"
                          src="assets/images/newAddButton.svg" class="largerCheckbox" />
                        <div>{{item.examination}}</div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>


              <div style="display: flex;flex-direction: column;">

                <h5 style="width: 45vw;
                justify-content: flex-start;
                align-items: center; padding: 0px 0px;margin-top: 10px;">Observation</h5>
                <div class="selectedComplaintsBox">
                  <div
                    style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 0px; margin-bottom: 0px;">
                    <div *ngFor="let item of selectedOnExamination; let i = index" class="searchable-component-style"
                      class="selectComplanits tagsContainer">
                      {{item}} <span (click)="removeSelectedOnExamination(item)" class="crossButton">&times;</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- COM-2 -->

      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        Diagnosis
      </ng-template>
      <div style="height: 100vh;width: 100%; display: flex;flex-direction: row;">
        <div style="height: 100vh;width: 50%;">
          <div>
            <div class="headingflex" style="justify-content: space-between; align-items: center;">
              <div style="font-size: 20px; font-weight: 600;">Diagnosis</div>
              <!-- <div>
                <div *ngIf="visitFlag" (click)="goToVital('edit');"
                  style="color:#2596be;text-decoration: underline;cursor: pointer; font-size: 18px;padding-right: 0px; ">
                  Todays Vitals</div>
                <div *ngIf="!visitFlag" (click)="goToVital('add');"
                  style="color:#F57F7F;;text-decoration: underline;cursor: pointer; font-size: 18px;padding-right: 0px; ">
                  Add Vitals?</div>
              </div> -->

              <div style="display:flex; flex-direction: row;">
                <div>
                  <div id="searchbox" class="form-control searchBox" placeholder="Start typing to search" name="sb"><i
                      class="fa fa-search" style="color: #bfbebf;padding: 7px;"></i>

                    <input type="text" [(ngModel)]="searchStringDiagnosis"
                      (keyup)="searchDiagnosis($event.target.value, 'diagnosis')" placeholder="Search"
                      style="padding: 3px; border: none; outline: none;width: 190px;">
                    <i class="fa fa-close" (click)="closeSearchDiagnosis()" aria-hidden="true"
                      style="color: #bfbebf;padding: 7px; cursor: pointer;"></i>
                  </div>
                </div>
                <button *ngIf="!isAddButton" mat-raised-button disabled
                  style="height: 40px;margin-left: 9px;margin-top: 3px; font-family: 'Mukta', sans-serif;">
                  +New</button>
                <button *ngIf="isAddButton" (click)="searchDataDignosisAdd();" mat-raised-button
                  style="background-color: #5D86CC; color: #fff; font-size:16px; height: 40px;margin-left: 9px;margin-top: 3px;">+New</button>
              </div>
            </div>

            <div
              style="display: flex; flex-direction: column; justify-content: space-between; height: 72vh; padding: 0px 26px;">
              <div style="display: flex;flex-direction: column; ">
                <div style=" width: 100%; height: 50vh;overflow: scroll;">
                  <div class="dataContainer">
                    <div *ngFor="let item of allDignosis; let i = index" (click)="selectDignosisModalValue(item,i)">
                      <app-cutom-card [item]="item.diagnosis" [selected]="item.selected"
                        style="display:flex;flex-direction: row;flex-wrap: wrap;"></app-cutom-card>
                    </div>
                  </div>
                </div>
              </div>

              <div style="display: flex;flex-direction: column;">
                <h5 style="width: 45vw;
                  justify-content: flex-start;
                  align-items: center;">Selected Diagnosis</h5>
                <div class="selectedComplaintsBox">
                  <div
                    style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 0px; margin-bottom: 0px;">
                    <div *ngFor="let item of selectedDiagnosis; let i = index" class="searchable-component-style"
                      class="selectComplanits tagsContainer">
                      {{item}} <span (click)="removeSelectedDiagnosis(item)" class="crossButton">&times;</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- COM-3 -->

      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        Medicines
      </ng-template>
      <div style="height: 100vh; width: 100%; display: flex;">
        <div style="height: 100vh; width: 50%;">
          <!-- MEDICINES SECTION -->
          <div class="headingflex" style="justify-content: space-between; align-items: center; ">
            <div style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;">
              <div style="font-size: 20px; font-weight: 600; ">Medicines</div>
              <!-- <div>
              <div *ngIf="visitFlag" (click)="goToVital('edit');"
                style="color:#2596be;text-decoration: underline;cursor: pointer; font-size: 18px;padding-right: 0px; ">
                Todays Vitals</div>
              <div *ngIf="!visitFlag" (click)="goToVital('add');"
                style="color:#F57F7F;;text-decoration: underline;cursor: pointer; font-size: 18px;padding:0px 10px ">
                Add Vitals?</div>
            </div> -->
              <div style="display: flex; flex-direction: row; border-bottom: 1px solid #eee; ">

                <div (click)="toggleMedicineSection(1)"
                  style="padding: 0px 10px; font-weight: bold;cursor: pointer; font-size: 18px;"
                  [ngClass]="medicineSectionToggle==1 ? 'focused-tab' : 'unfocused-tab'">
                  Add New
                </div>

                <!-- (click)="dialog.open(popular_medicines); closefavper();popularMedicines()" -->
                <div (click)="toggleMedicineSection(2)"
                  style="padding: 0px 10px; font-weight: bold;cursor: pointer; font-size: 18px;"
                  [ngClass]="medicineSectionToggle==2 ? 'focused-tab' : 'unfocused-tab'">
                  Popular
                </div>

                <!-- (click)="dialog.open(previous_medicines_fav); closefavper()" -->
                <div (click)="toggleMedicineSection(3)"
                  style="padding: 0px 10px; font-weight: bold;cursor: pointer; font-size: 18px;"
                  [ngClass]="medicineSectionToggle==3 ? 'focused-tab' : 'unfocused-tab'">
                  Favourite
                </div>

                <!-- (click)="dialog.open(previous_medicines_modal);previousMed();" -->
                <div (click)="toggleMedicineSection(4)"
                  style="padding: 0px 10px; font-weight: bold;cursor: pointer; font-size: 18px;"
                  [ngClass]="medicineSectionToggle==4 ? 'focused-tab' : 'unfocused-tab'">
                  Previous

                </div>

              </div>
            </div>
          </div>




          <!-- MEDICINE SECTION START -->
          <div *ngIf="medicineSectionToggle==1" class="row"
            style="justify-content: center; overflow-y: scroll; height: 85vh; padding-bottom: 200px; align-content: flex-start;">

            <div onclick="event.stopPropagation();" (click)="addNewMedicine($event)"
              style="width: 93%; height: 60px; border-radius: 8px; border: 2px dashed #047A7B; display: flex; align-items: center; justify-content: space-around; margin-bottom: 10px; color: #047A7B; font-weight: bold;"
              [ngStyle]="{'height': isAddMedicine ? null : '60px'}">
              <div style="height: 100%; width: 90%; display: flex; flex-direction: column; justify-content: center">
                <div
                  style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; padding-top: 16px; align-items: center;">

                  <div style="display: flex; align-items: center;">
                    <div style="font-size: 20px; margin-left: 4px;">Add New Medicine</div>
                  </div>
                  <div style="display: flex; align-items: center;">

                    <div (click)="dialog.open(add_new_drug_modal);" #tooltip="matTooltip"
                      matTooltip="Cannot find drugs by search? Add New." matTooltipPosition="below"
                      class="addNewDrugAndMoreInsButton">+New Drug</div>
                  </div>
                </div>
                <div
                  style="
                display: flex; flex-direction: row; width: 97%; height: 40px; margin: 16px 0px; justify-content: space-between;">
                  <div style="position: relative; width: 80%; height: 100%; display: flex; align-items: center;">
                    <div *ngIf="medicineLoader" style="position: absolute; right: 8px; height: 1.5rem; width: 1.5rem;"
                      class="spinner-border text-muted">
                    </div>

                    <input #typeAhead id="typeahead-format" type="text" class="form-control"
                      placeholder="{{searchText}}" [resultTemplate]="searchDrugResultFormatterTemplate"
                      [(ngModel)]="medicineName" [ngbTypeahead]="searchDrugsByTyping" [inputFormatter]="formatMatches"
                      [resultFormatter]="formatter" (input)="getSearchedMeds($event.target.value)"
                      (selectItem)="setSelectMedicine($event)"
                      [ngClass]="(unselectedDrugName) ? 'errorBorder' : 'commonBorder'"
                      (ngModelChange)="onChangeDrugName()" style="
                    height: 100%;
                    width: 100%;
                    border-radius: 8px;
                    outline: none;    
                    font-size: 18px;
                    padding-left: 16px;" name="fname" popupClass="background-color:red">
                  </div>

                  <mat-form-field style="width: 90px; margin: 0px 6px;">
                    <mat-label>Drug Type</mat-label>
                    <mat-select placeholder="{{drugType}}" [formControl]="drugTypeField" [value]="selectedDrugType"
                      required (selectionChange)="dropdownSelect('drugType',$event.value)">
                      <mat-option *ngFor="let item of drugTypeSelection" [value]="item">{{item}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>

                <!-- add drug -->

                <div *ngFor="let dose of doseCount; let i = index"
                  style="margin-left: 8px; display: flex; flex-direction: row;  justify-content: space-around; align-items: center; ">

                  <div style="display: flex; flex-direction: column; ">
                    <div *ngFor="let element of frequencyWithVal.slice(0, frequencyCollapsibleVariable); let j = index"
                      style="display: flex; flex-direction: row; justify-content: center; align-items: center; margin-bottom: 6px;">

                      <div style="display: flex; flex-direction: row;" *ngIf="selectedUnit !='Apply Locally'">

                        <input *ngIf="i==0" class="unitInput" type="number" min="0" step="any"
                          [(ngModel)]="element.value" (input)="onUnitChange($event.target.value, j)" id="drugUnit_{{j}}"
                          onKeyPress="if(this.value.length==5) return false;" (keydown)="preventNegativeInput($event)" />

                        <!-- <input *ngIf="i!=0" class="unitInput" type="number" min="0" step="any"
                          (input)="onUnitChange($event.target.value)" id="drugUnit_{{i}}" /> -->

                        <span *ngIf="drugTypeUnit !='Fingertip' && drugTypeUnit!='Fingertip,Apply-Locally'" style="padding: 6px;
                          margin-top: 2px;
                          font-size: 16px;
                          width: 60px;
                          font-weight: 500;
                          color: #333;">{{drugTypeUnit}}</span>
                      </div>

                      <!-- <input *ngIf="element.value =='' && selectedUnit == 'Apply Locally'" class="largerCheckbox"
                        type="checkbox" (input)="updateFrequencyWithVal(j)" /> -->

                      <!-- <img (click)="updateFrequencyWithVal(j)"
                        *ngIf="element.value !='' && selectedUnit == 'Fingertip'" style="cursor: pointer;"
                        src="assets/images/checkbox_material.svg" class="largerCheckbox" /> -->

                      <!-- <mat-checkbox ></mat-checkbox> -->

                      <!-- <div
                        style="display: flex; flex-direction: row; margin-right: 4px; margin-left: 16px; color: #333; font-weight: normal;"
                        *ngIf="selectedUnit == 'Fingertip'">
                        {{selectedUnit }}
                      </div> -->


                      <!-- FREQUENCY -->
                      <!-- <div (click)="incrementDosage(i)" style="height: 10px; width: 10px; background-color: red;"></div> -->
                      <!-- <mat-form-field style="width: 300px; margin: 0px 6px;" appearance="legacy">
                      <mat-select placeholder="{{toFrequency}}" [value]="dose.frequency" multiple
                        [formControl]="FrequencyField" (selectionChange)="dropdownSelect('frequency',$event.value)"
                        id="drugFrequency_{{i}}" id="drugFrequency_{{i}}">
                        <mat-option *ngFor="let item of frequency" [value]="item">{{item}}

                        </mat-option>
                      </mat-select>
                    </mat-form-field> -->

                      <div style="font-size: 16px; width: 100px;"> x {{element.name}}</div>


                    </div>

                    <a (click)="toggleFrequencyCollapse()" style="color: #F57F7F;">Show {{frequencyCollapsibleVariable
                      == 4 ? 'More' : 'Less' }} </a>

                  </div>

                  <!-- <img style="width:24px; margin-bottom: 6px; color:#007d7e;" src="assets/images/curly_brace.svg"
                    class="largerCheckbox" /> -->

                  <div style="display: flex; flex-direction: row;">


                    <!-- Drug Unit -->
                    <mat-form-field id="fourth" style="width: 110px; margin: 0px 6px;" *ngIf="i == 0">
                      <!-- <mat-label>When to take</mat-label> -->
                      <mat-select placeholder="Drug Unit" [formControl]="DrugUnitField" [value]="selectedDrugUnit"
                        (selectionChange)="dropdownSelect('drugUnit',$event.value)">
                        <mat-option *ngFor="let item of drugUnitTypes" [value]="item">{{item}}</mat-option>
                      </mat-select>
                    </mat-form-field>


                    <!-- WHEN TO TAKE -->
                    <mat-form-field id="fourth" style="width: 110px; margin: 0px 6px;" *ngIf="i == 0">
                      <!-- <mat-label>When to take</mat-label> -->
                      <mat-select placeholder="{{Whentotake}}" [formControl]="WhentotakeField" [value]="dose.whenToTake"
                        (selectionChange)="dropdownSelect('whenToTake',$event.value)">
                        <mat-option *ngFor="let item of whenToTake" [value]="item">{{item}}</mat-option>
                      </mat-select>
                    </mat-form-field>


                    <!-- DURATION -->
                    <mat-form-field id="third" style="width: 90px; margin: 0px 6px;">
                      <!-- <mat-label>Duration</mat-label> -->
                      <mat-select placeholder="{{Duration}}" [value]="dose.duration" [formControl]="DurationField"
                        (selectionChange)="dropdownSelect('duration',$event.value)">
                        <mat-option *ngFor="let day of duration" [value]="day">
                          {{day}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                  </div>

                  <div id="fourth" style="width: 100px; margin: 0px 6px;" *ngIf="i != 0">

                  </div>

                  <!-- <img (click)="removeDose(i, $event)" *ngIf="i != 0"
                    style="width:24px; margin-bottom: 6px; color:#007d7e;" src="assets/images/cross_red.svg"
                    class="largerCheckbox" /> -->
                </div>

                <!-- <u (click)="addAnotherDose()" style="height: 40px;
                  cursor: pointer;
                  border-radius: 100px;
                  margin-left: 10px;
                  margin-bottom: 0px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #037a7b;
                  align-self: flex-end;
                  font-size: 16px;
                  ">+Add varying dose</u> -->

                <div
                  style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; padding-top: 0px; align-items: center;">

                  <div style="display: flex; align-items: center;width: '100%';">
                    <app-language-select-textbox #lngMedInstr [width]="100"
                      patientSelectedLanguage="{{patientSelectedLanguage}}" [callBack]="onInputCustomComponent"
                      styleForWidth="instructions" placeholder="Instructions" style="padding-left: 0px; width: 100%;">
                    </app-language-select-textbox>
                    <div style="display: flex; align-items: center;">

                      <div (click)="dialog.open(medicine_instructions_modal);" #tooltip="matTooltip"
                        style="margin-top: 2px;" matTooltip="Cannot find drugs by search? Add New."
                        matTooltipPosition="below" class="addNewDrugAndMoreInsButton">+More</div>
                    </div>
                  </div>
                </div>




                <!-- <div
                  style="width:100%; background-color: #EFF3F6; display: flex; flex-direction: row; align-items: center; border-radius: 8px; border: 0.5px solid #ccc;">
                  <input type="text" class="form-control" [(ngModel)]="selectedInstructions"
                    (input)="updateMedicineInstructions($event.target.value)" placeholder="Instructions" style="
                    width: 90%;
                    border-radius: 8px;
                    outline: none;    
                    font-size: 16px;
                    border:0px;
                    padding-left: 16px;" id="fname" name="fname">

                  <div (click)="dialog.open(medicine_instructions_modal); clearAllSelection()"
                    style="font-size: 16px; z-index: 1; border-top-right-radius: 8px; border-bottom-right-radius: 8px; width: 10%; height: 100%; display: flex; justify-content: center; align-items: center; color: #1F3042; font-weight: 500; cursor: pointer; ">
                    More
                    <div style="font-size: 10px; margin-top: 4px;">▼</div>
                  </div>
                </div> -->

                <div (click)="addToList()" onclick="event.stopPropagation();" style="
                width: 100%;
                background-color: #037a7b;
                padding: 10px;
                border-radius: 6px;
                
                margin-bottom: 10px;
                margin-top: 16px;
                display: flex;
                align-self: center;
                justify-content: center;
                align-items: center;
                color: #fff;
                cursor: pointer;
                font-size: 18px;
                ">Add to List</div>

                <!-- <div (click)="closeAddMedicine($event);" onclick="event.stopPropagation();" style="
                        width: 100%;
                        background-color: #eee;
                        padding: 10px;
                        border-radius: 6px;
                        margin-left: 10px;
                        margin-bottom: 10px;
                        font-size: 18px;
                        display: flex;
                        align-self: center;
                        justify-content: center;
                        align-items: center;
                        color: grey;
                        cursor: pointer;
                ">Cancel</div> -->
              </div>

              <div style="font-size: 18px; cursor: pointer;" *ngIf="!isAddMedicine">+Add Medicine</div>
            </div>


            <!-- MEDICINE SECTION END -->

            <!-- <div (click)="openDialog(delete_modal, 0)"
              style="display: flex;flex-direction: row;justify-content: flex-end; color: #F57F7F; cursor: pointer; font-weight: bold;">
              Clear Medicines</div> -->

            <!-- <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
              <div *ngFor="let item of allMedicines; let i = index" (click)="selectMedicine(item, i)" cdkDrag
                [cdkDragDisabled]="item.editing"
                style="display: flex; flex-direction: column; width: 55vw; align-items: center;">

                <div [ngClass]="(!item.editing) ?  'selectedCardActive' :'selectedCard'" style="width: 93%; min-height: 80px; padding: 6px 0px 6px 0px; border-radius: 8px;position: relative;
                
                 display: flex; align-items: center; justify-content: space-around; margin-bottom: 10px;"
                  [ngStyle]="{'height': item.editing ? '200px' : '', 'border': item.editing ? '1px solid #F08439' : '1px solid #eee', 'background-color':item.selected ? '#e4f8f5' : '#eee'}">

                  <input type="checkbox" (click)="onSelectMedicine(i, 1)" *ngIf="!item.selected"
                    style="border: 1px solid #ccc; background-color: #fff; border-radius: 16px; margin-left: 8px; margin-right: 8px; cursor: pointer;"
                    class="largerCheckbox">

                  <img (click)="onSelectMedicine(i, 1)" *ngIf="item.selected"
                    style="width:26px; color:#007d7e; margin-left: 8px; margin-right: 8px; cursor: pointer;"
                    src="assets/images/checkbox_material.svg" class="largerCheckbox" />

                  <div style="width: 86%;">
                    <div style="display: flex; flex-direction: row;">
                      <div [ngClass]="(false) ?  'titleActive' :'titleInactive'"
                        style="align-self: center; margin-right: 16px;">{{item.name}}</div>

                      <mat-form-field *ngIf="item.editing" style="width: 90px; margin: 0px 6px;">
                        <mat-label>Drug Type</mat-label>
                        <mat-select placeholder="{{drugType}}" [formControl]="drugTypeField" [value]="selectedDrugType"
                          required (selectionChange)="dropdownSelect('drugType',$event.value)">
                          <mat-option *ngFor="let item of drugTypeSelection" [value]="item">{{item}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>




                    <div id="something" name="something" *ngIf="!item.editing"
                      [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'">
                      {{item.dosage2}} {{item.duration2}}</div>
                    <div id="something" name="something" *ngIf="!item.editing"
                      [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'" style="color: grey; font-size: 13px;">
                      {{item.instructions}}</div>

                    <div style="display: flex; flex-direction: row;">

                      <div *ngIf="item.editing" style="display: flex; align-items: center;">

                        <div style="display: flex; flex-direction: row;" *ngIf="selectedUnit !='Apply Locally'">
                          <input class="unitInput" type="number" min="0" (input)="onUnitChange($event.target.value)"
                            [(ngModel)]="editMedicineDosage" name="drugUnit_{{i}}" />

                          <span style="padding: 6px;
                        margin-top: 0px;
                        font-size: 16px;
                        width: 50px;
                        font-weight: 500;
                        color: #333;">{{drugTypeUnit}}</span>
                        </div>

                        <div style="display: flex; flex-direction: row;" *ngIf="selectedUnit == 'Apply Locally'">
                          {{selectedUnit}}
                        </div>


                      

                        <mat-form-field style="width: 200px; margin: 0px 6px;">
                          <mat-label>Frequency</mat-label>
                          <mat-select [formControl]="FrequencyFieldEdit" multiple
                            (selectionChange)="dropdownSelect('frequency',$event.value)">
                            <mat-option *ngFor="let item of frequency" [value]="item">{{item}}

                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        -
                        <mat-form-field id="third" style="width: 90px; margin: 0px 6px;">
                          <mat-label>Duration</mat-label>
                          <mat-select [value]="item.duration2" [formControl]="DurationField"
                            (selectionChange)="dropdownSelect('duration',$event.value)">
                            <mat-option *ngFor="let day of duration" [value]="day">
                              {{day}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field id="fourth" style="width: 110px; margin: 0px 6px;">
                          <mat-label>When to take</mat-label>
                          <mat-select [value]="item.whenToTake" [formControl]="WhentotakeField"
                            (selectionChange)="dropdownSelect('whenToTake',$event.value)">
                            <mat-option *ngFor="let item of whenToTake" [value]="item">{{item}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    
                    </div>
                    <app-language-select-textbox #medLangIns
                      [styles]="{'background-color':'#fff','width': '100%','height':'100%'}" *ngIf="item.editing"
                      [width]="90" placeholder="Enter medicine instructions">
                    </app-language-select-textbox>

                   

                  </div>

                  <div style="margin: 8px; display: flex; flex-direction: row; position: relative;">

                    <img (click)="editMedicineDose(i, $event)" *ngIf="!item.editing"
                      style="width:30px; margin: 0px 4px; color:#007d7e; cursor: pointer;"
                      src="assets/images/edit_icon.svg" class="largerCheckbox" />
                
                    <img (click)="updateMedicineDose(i, $event)" *ngIf="item.editing"
                      style="width:75px; color:#007d7e; cursor: pointer;" src="assets/images/update_tick_icon.svg"
                      class="largerCheckbox" />

                  </div>

                  <img (click)="closeEdit(i)" *ngIf="item.editing"
                    style="width:34px; color:#007d7e; cursor: pointer; z-index: 9; position: absolute; top: 8px; right: 8px;"
                    src="assets/images/cancel_cross.svg" class="largerCheckbox" />

                </div>

              </div>
            </div> -->

          </div>
          <!-- MEDICINE SECTION END -->

          <!-- POPULAR SECTION START -->
          <div *ngIf="medicineSectionToggle==2" class="form-group" style="padding-left: 16px;">
            <div style="flex-direction: column;" class="input-group">
              <div style="margin-top: 0px; display: flex; flex-direction: column; width: 100%; ">
                <input placeholder="Search Medicines From List" type="text" aria-label="Number" matInput
                  [formControl]="myControlFav" [(ngModel)]="searchMed" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px; width: 97%;
                    font-size: 16px; z-index: 0;">

              </div>
              <div style="overflow: scroll; padding-left: 7px; margin-top: 15px; width: 50vw; ">

                <mat-chip-list class="chipForDig" aria-label="Fish selection">
                  <mat-chip class="price-filter" #ref="matChip"
                    *ngFor="let item of diagnosisCollectionArray | filter:searchMed;let i=index" (click)="toggle(ref)"
                    [value]="item" [selected]="item.selected==true">
                    {{item.name}}
                  </mat-chip>

                </mat-chip-list>

                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="price-filter" style="margin-top: 10px;" #ref="matChip"
                    *ngFor="let item of drugTypeList | filter:searchMed;let i=index" (click)="toggle(ref)"
                    [selected]="item.selected==true" [value]="item">
                    {{item.name}}
                  </mat-chip>

                </mat-chip-list>
              </div>

              <div style="height: 500px; overflow: scroll; padding-top: 20px; padding-bottom: 20px;">
                <div *ngFor="let item of allMedicinesForDiagnosis | filter:searchMed; let i = index"
                  (click)="selectMedicine(item, i)"
                  style="display: flex; flex-direction: column; width: 100%; align-items: center; ">

                  <div style="width: 700px; height: fit-content; padding-top: 5px; padding-bottom: 5px; border-radius: 8px;position: relative;
                     display: flex; align-items: center; justify-content: space-around; margin-bottom: 6px;"
                    [ngStyle]="{'border': item.editing ? '1px solid #F08439' : '1px solid #DFDFDF'}">

                    <!-- 
                      'background-color':item.selected ? '#e4f8f5' : '#fff'
                      <div (click)="onSelectMedicine(item.idx, 2)" *ngIf="!item.selected"
                      style="border: 1px solid #ccc; background-color: #fff; border-radius: 8px; margin-left: 8px; cursor: pointer; height: 30px; width: 30px;"
                      class="largerCheckbox"></div> 
                     *ngIf="item.selected"
                    -->

                    <img (click)="onSelectMedicine(item.idx, 2); saveModalValues('copyPopularMedicines');"
                      style="width:26px; color:#007d7e; margin-left: 8px; cursor: pointer;"
                      src="assets/images/newAddButton.svg" class="largerCheckbox" />

                    <div style="width: 86%;">
                      <div [ngClass]="(false) ?  'titleActive' :'titleInactive'">{{item.name}}</div>
                      <div id="something" name="something" *ngIf="!item.editing"
                        [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'">
                        {{item.dosage2}} {{item.duration2}}</div>
                      <div id="something" name="something" *ngIf="!item.editing"
                        [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'"
                        style="color: grey; font-size: 13px;">
                        {{item.instructions}}  </div>

                    </div>
                    <div>

                    <img (click)="openDialog(delete_modalForPopularMed);popularMedicineDelete(item)"
                    style="width:30px; margin-right: 10px; color:#007d7e; cursor: pointer;"
                    src="assets/images/delete_icon_round.svg" class="largerCheckbox" />
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div style="width:100%; display: flex; justify-content: flex-end;">
              <!-- to be removed -->
              <!-- <button (click)="saveModalValues('copyPopularMedicines');" style="background-color: #5D86CC;
            padding: 8px 16px;
            border-radius: 6px;
            color: #fff;
            font-weight: bold;
            border: 0px;
            font-size: 18px;
            margin: 8px 0px;
          outline: none;">Copy Selected Medicines</button> -->
            </div>

          </div>
          <!-- POPULAR SECTION END -->

          <!-- FAVOURITE MEDICINE START -->
          <div *ngIf="medicineSectionToggle==3" style="flex-direction: column; padding-left: 16px;" class="input-group">
            <!-- <div *ngIf="selectedDiagnosis.length" style="color: grey; margin-top: -20px; margin-bottom: 10px;">Search by diagnosis:
              <span *ngFor="let diagnosis of selectedDiagnosis" (click)="selectDiagnosisFavModal(diagnosis)"
                style="padding:4px 8px; cursor: pointer; font-size: 12px; background-color: #e4f8f5; border: 1px solid #71bebc; color: #047A7B; border-radius: 100px; margin-left: 6px;">{{diagnosis}}</span>
            </div> -->

            <div
              style="margin-top: 0px; display: flex; flex-direction: row; width: 100%; border: 1px solid #ccc; border-radius: 6px;">

              <input #searchFavourites placeholder="Choose Favourites" id="favinputtextbox" type="text"
                aria-label="Number" matInput [matAutocomplete]="auto" [formControl]="myControlFav"
                [(ngModel)]="selectedPreviousFavMed" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px; width: 98%;
                font-size: 16px; z-index: 0;">
              <mat-autocomplete (optionSelected)='onDropdownSelectFavMed($event.option.value)' autoActiveFirstOption
                #auto="matAutocomplete">
                <mat-option *ngFor="let option of favArrayNames | async" [value]="option"
                  [ngClass]="(option.selectedComplaints=='New Born'||option.selectedComplaints=='Well Child') ?  'activeMat' :'UnactiveMat'">
                  <span>
                    <span style="font-weight: bold; color: #333;">{{option.value}} </span><span style="color: grey;"
                      *ngIf="option.selectedDiagnosis.length">({{option.selectedDiagnosis}})

                    </span>
                  </span>

                </mat-option>
              </mat-autocomplete>

              <i class="fa fa-close" (click)="clearFavouriteText()" aria-hidden="true"
                style="color: #bfbebf;padding: 10px; font-size: 18px; cursor: pointer;"></i>
            </div>
            <div *ngIf="selectedPreviousFavMed.length" style="height: 50vh; overflow: scroll;">
              <div *ngFor="let element of selectedFavMed; let i = index"
                style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin: 10px 0px 10px 0px;">
                <!-- <div>
                  <div style="color: #333; font-weight: bold; font-size: 18px;">{{i+1}}.
                    {{element.Drug.product_name}}
                  </div>
                  <div style="color: grey; font-size: 16px;"> {{element.dosage2}}</div>
                </div> -->
                <div class="newCardStyle"
                  [ngStyle]="{'border': element.editing ? '1px solid #F08439' : '1px solid #DFDFDF'}">

                  <!-- <div (click)="onSelectMedicine(i, 3)" *ngIf="!element.selected"
                    style="border: 1px solid #ccc; background-color: #fff; border-radius: 8px; margin-left: 8px; cursor: pointer; height: 30px; width: 30px;"
                    class="largerCheckbox"></div> -->

                  <img (click)="onSelectMedicine(i, 3); saveModalValues('copyMedicinesFav');"
                    style="width:26px; color:#007d7e; margin-left: 8px; cursor: pointer;"
                    src="assets/images/newAddButton.svg" class="largerCheckbox" />

                  <div style="width: 86%;">
                    <div [ngClass]="(false) ?  'titleActive' :'titleInactive'">{{element.Drug ?
                      element.Drug.product_name ? element.Drug.product_name:'':''}}</div>
                    <div id="something" name="something" *ngIf="!element.editing"
                      [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'">
                      {{element.dosage2}} {{element.duration2}}</div>
                    <div id="something" name="something" *ngIf="!element.editing"
                      [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'" style="color: grey; font-size: 13px;">
                      {{element.instructions}}</div>

                  </div>
                </div>



              </div>
            </div>


            <div style="display: flex; justify-content: space-between; width: 100%;">
              <a *ngIf="selectedPreviousFavMed.length" (click)="openDialog(delete_modal); " style="
              color: #f44336;
                          padding: 8px 0px;
                          
                          font-weight: bold;
                          margin: 8px;
                          "> ⚠️ Delete this favourite?</a>

              <button *ngIf="!selectedFavMed.length" style="background-color: #f44336; visibility: hidden;
                          padding: 8px 16px;
                          border-radius: 6px;
                          color: #fff;
                          font-weight: bold;
                          border: 0px;
                          margin: 8px;
                          outline: none;">Delete</button>
              <div style="padding-top: 16px; display: flex; justify-content: flex-end;">

                <!-- <button (click)="saveModalValues('copyMedicinesFav');" style="background-color: #5D86CC;
                      padding: 8px 16px;
                      border-radius: 6px;
                      color: #fff;
                      font-weight: bold;
                      border: 0px;
                      font-size: 18px;
                      margin: 8px 0px;
                    outline: none;">Copy Selected Medicines </button> -->
              </div>
            </div>

          </div>
          <!-- FAVOURITE MEDICINE END -->

          <!-- PREVIOUS MEDICINE START -->
          <div *ngIf="medicineSectionToggle==4" style="flex-direction: column; width: 90%; padding-left: 16px;"
            class="input-group">

            <div style="display: flex; flex-direction: row; align-items: center;">
              <div style="width: 80%;">
                <mat-form-field id="fourth" style="width: 100%; margin: 0px 6px;">
                  <mat-label>Filter Previous Prescriptions</mat-label>
                  <mat-select placeholder="{{selectedPreviousMedDate}}" (selectionChange)="onDropdownSelect($event)"
                    name="ll" #select>
                    <mat-select-trigger>
                      {{select.value?.value}}
                    </mat-select-trigger>
                    <mat-option *ngFor="let item of previousMedsArr" [value]="item" style=" line-height: normal;">

                      <!-- <div style="display: flex; flex-direction: column;"> -->
                      <div>
                        <div style="font-size: 17px; color: grey;">

                          <span style="font-weight: bold; color: #333;">{{item.value}} </span>(
                          <!-- Complaints: {{item.complaints ? item.complaints : 'NA'}}, &nbsp;&nbsp;&nbsp;&nbsp; -->
                          Diagnosis: {{item.diagnosis ? item.diagnosis : 'NA'}})
                        </div>
                      </div>
                      <!-- </div> -->

                    </mat-option>
                  </mat-select>

                </mat-form-field>
              </div>

              <div *ngIf="pdfUrlForViewPrescription" (click)="onClickViewPrescription()" style="width: 15%;
                background-color: #ffffff;
                font-size: 16px;
                text-align: center;
                margin-left: 16px;
                padding: 6px;
                border-radius: 6px;
                border: 2px solid #5d86cc;
                color: #5d86cc;
                font-weight: bold;
                cursor: pointer;
                ">
                View PDF</div>

            </div>

            <div style="height: 74vh; overflow: scroll;">
              <div *ngFor="let element of selectedPreviousMed; let i = index"
                style=" display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin: 10px 0px 10px 0px;">
                <!-- <div class="newCardStyle"
                  [ngStyle]="{'border': element.editing ? '1px solid #F08439' : '1px solid #DFDFDF', 'background-color':element.selected ? '#e4f8f5' : '#fff'}">
                  <div style="color: #333; font-weight: bold; font-size: 18px;">{{i+1}}.
                    {{element.Drug.product_name}}
                  </div>
                  <div style="color: grey; font-size: 16px;"> {{element.dosage2}}</div>
                </div> -->

                <div class="newCardStyle"
                  [ngStyle]="{'border': element.editing ? '1px solid #F08439' : '1px solid #DFDFDF'}">

                  <!-- <div (click)="onSelectMedicine(i, 4)" *ngIf="!element.selected"
                    style="border: 1px solid #ccc; background-color: #fff; border-radius: 8px; margin-left: 8px; cursor: pointer; height: 30px; width: 30px;"
                    class="largerCheckbox"></div> -->

                  <img (click)="onSelectMedicine(i, 4); saveModalValues('copyMedicines');"
                    style="width:26px; color:#007d7e; margin-left: 8px; cursor: pointer;"
                    src="assets/images/newAddButton.svg" class="largerCheckbox" />

                  <div style="width: 86%;">
                    <div [ngClass]="(false) ?  'titleActive' :'titleInactive'">{{element?.Drug?.product_name}}</div>
                    <div id="something" name="something" *ngIf="!element.editing"
                      [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'">
                      {{element.dosage2}} {{element.duration2}}</div>
                    <div id="something" name="something" *ngIf="!element.editing"
                      [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'" style="color: grey; font-size: 13px;">
                      {{element.instructions}}</div>

                  </div>
                </div>
              </div>
            </div>

            <div style="display: flex;flex-direction: row;justify-content: space-between;">
              <div *ngIf="!pdfUrlForViewPrescription"
                style="padding-top: 20px; color: #e9eaf0; font-size: 16px;font-weight: 600; text-decoration: underline;">
                <span style="cursor: pointer;"></span>
              </div>
              <!-- <div *ngIf="pdfUrlForViewPrescription" (click)="onClickViewPrescription()"
                style="padding-top: 20px; color: #6975d7; font-size: 16px;font-weight: 600; text-decoration: underline;">
                <span style="cursor: pointer;"> View Previous Pdf</span>
              </div> -->
              <!-- <div style="display: flex;justify-content: flex-end;">
                <button (click)="saveModalValues('copyMedicines');" class="btnUiForCopyMed">Copy Selected
                  Medicines</button>
              </div> -->
            </div>

          </div>
          <!-- PREVIOUS MEDICINE END -->

        </div>
        <!-- COM-4 -->

      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        Dietary Advice
      </ng-template>
      <div style="height: 100vh; width: 100%; display: flex;flex-direction: row;">
        <div style="height:100%;width: 50%;">
          <div>
            <div class="headingflex" style="align-items: center;  ">
              <div style="font-weight: 600; font-size: 20px; ">Dietary Advice</div>
              <div>

              </div>

              <div style="display:flex; flex-direction: row; width: 75%; margin-left: 31px;">

                <div style="display: flex; align-items: center;"
                  (keyup)="searchDietaryAdvice($event.target.value, 'dietaryAdvice')">
                  <app-language-select-textbox #lngDietaryInst [width]="86" value="langtextboxvalue"
                    patientSelectedLanguage="{{patientSelectedLanguage}}" placeholder="Start typing to search "
                    style="padding-left: 0px; width: 100%;">
                  </app-language-select-textbox>
                  <div style="display: flex; align-items: center;">

                    <button *ngIf="!isAddButton" mat-raised-button disabled
                      style="height: 40px;margin-left: -59px;margin-top: 3px; font-family: 'Mukta', sans-serif;">
                      +Add</button>
                    <button *ngIf="isAddButton" (click)="addDietaryAdvice();"
                      style="background-color: #5D86CC; color: #fff; height: 40px;margin-left: -59px;margin-top: 3px;font-size: 16px;"
                      mat-raised-button>+Add</button>
                  </div>
                </div>
              </div>

              <!-- <div style="display:flex; flex-direction: row; width: 75%;">

                <div id="searchbox" style="margin-left: 15px;" class="form-control searchBox"
                  placeholder="Start typing to search" name="sb"><i class="fa fa-search" style="color: #bfbebf; "></i>

                  <input type="text" [(ngModel)]="searchStringDietaryAdvice"
                    (keyup)="searchDietaryAdvice($event.target.value, 'dietaryAdvice')"
                    placeholder="Start typing to search" style="padding: 3px; border: none; outline: none;width: 91%;">
                  <i class="fa fa-close" (click)="closeSearchDietaryAdvice()" aria-hidden="true"
                    style="color: #bfbebf; cursor: pointer;"></i>
                </div>
                <button *ngIf="!isAddButton" mat-raised-button disabled
                  style="height: 40px;margin-left: 9px;margin-top: 3px; font-family: 'Mukta', sans-serif;">
                  +Add</button>

                <button *ngIf="isAddButton" (click)="addDietaryAdvice();"
                  style="background-color: #5D86CC; color: #fff; height: 40px;margin-left: 9px;margin-top: 3px;font-size: 16px;"
                  mat-raised-button>+Add</button>
              </div> -->
            </div>



            <div
              style="display: flex; flex-direction: column;justify-content: space-between; height: 72vh; padding: 0px 26px;">
              <div style="display: flex;flex-direction: column; ">
                <div style=" width: 100%; height: 50vh;overflow: scroll;">
                  <div class="dataContainer">
                    <div *ngFor="let item of allDietaryAdvices; let i = index"
                      (click)="selectDietaryAdviceslValue(item,i)">
                      <app-cutom-card [item]="item.dietAdvice" [selected]="item.selected"
                        style="display:flex;flex-direction: row;flex-wrap: wrap;"></app-cutom-card>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex;flex-direction: column;">
                <h5 style="width: 45vw;
                justify-content: flex-start;
                align-items: center; padding: 10px 0px;">Selected Dietary Advice</h5>
                <div class="selectedComplaintsBox" style="border: none;">
                  <div
                    style="border: none; display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 0px; margin-bottom: 0px;">
                    <div *ngFor="let item of selectedDietaryAdvices; let i = index" class="searchable-component-style"
                      class="selectComplanits tagsContainer">
                      {{item}} <span (click)="removeSelectedDietaryAdvice(item)" class="crossButton">&times;</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- COM-5 -->

      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>General Instructions</ng-template>
      <div style="height: 100vh; width: 100vw; display: flex;flex-direction: row;">
        <div style="height: 100vh; width: 50%;">
          <div>
            <div class="headingflex" style="align-items: center; justify-content: flex-start; width: 110%;">
              <div style="font-weight: 600; font-size: 20px; line-height: 20px;">Instructions</div>
              <div>

              </div>

              <div style="display:flex; flex-direction: row; width: 97%; ">

                <div style="display: flex; align-items: center;"
                  (keyup)="searchGeneralInstructions($event.target.value, 'generalInstructions')">
                  <app-language-select-textbox #lngGenInst [width]="86"
                    patientSelectedLanguage="{{patientSelectedLanguage}}" placeholder="Start typing to search "
                    style="padding-left: 0px; width: 100%;"></app-language-select-textbox>
                  <div style="display: flex; align-items: center;">

                    <button *ngIf="!isAddButton" mat-raised-button disabled
                      style="height: 40px;margin-left: -57px;margin-top: 3px; font-family: 'Mukta', sans-serif;">
                      +Add</button>
                    <button *ngIf="isAddButton" (click)="addGeneralInstruction();"
                      style="background-color: #5D86CC; color: #fff; height: 40px;margin-left: -57px;margin-top: 3px;font-size: 16px;"
                      mat-raised-button>+Add</button>
                  </div>
                </div>
              </div>

              <!-- <div style="display:flex; flex-direction: row;width: 71%;">

                <div id="searchbox" class="form-control searchBox" name="sb" style="margin-left: 15px;"><i
                    class="fa fa-search" style="color: #bfbebf;  "></i>

                  <input type="text" [(ngModel)]="searchStringGeneralInstructions" placeholder="Start typing to search"
                    (keyup)="searchGeneralInstructions($event.target.value, 'generalInstructions')"
                    style="padding: 3px; border: none; outline: none;width: 91%;">
                  <i class="fa fa-close" (click)="closeSearchGeneralInstructions()" aria-hidden="true"
                    style="color: #bfbebf; cursor: pointer;"></i>
                </div>
                <button *ngIf="!isAddButton" mat-raised-button disabled
                  style="height: 40px;margin-left: 9px;margin-top: 3px; font-family: 'Mukta', sans-serif;">
                  +Add</button>

                <button *ngIf="isAddButton" (click)="addGeneralInstruction();"
                  style="background-color: #5D86CC; color: #fff; height: 40px;margin-left: 9px;margin-top: 3px;font-size: 16px;"
                  mat-raised-button>+Add</button>
              </div> -->
            </div>


            <div
              style="display: flex; flex-direction: column; justify-content: space-between; height: 72vh; padding: 0px 26px;">
              <div style="display: flex;flex-direction: column; ">
                <div style="width: 100%; height: 50vh;overflow: scroll;">
                  <div class="dataContainer">
                    <div *ngFor="let item of allGeneralInstructions; let i = index"
                      (click)="selectGeneralInstructionsValue(item,i)">
                      <app-cutom-card [item]="item.generalInstruction" [selected]="item.selected"
                        style="display:flex;flex-direction: row;flex-wrap: wrap;"></app-cutom-card>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex;flex-direction: column;">
                <h5 style="width: 45vw;
                justify-content: flex-start;
                align-items: center; padding: 10px 0px;">Selected Instructions</h5>
                <div class="selectedComplaintsBox" style="border: none;">
                  <div
                    style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 0px; margin-bottom: 0px;">
                    <div *ngFor="let item of selectedGeneralInstructions; let i = index"
                      class="searchable-component-style" class="selectComplanits tagsContainer">
                      {{item}} <span (click)="removeSelectedGeneralInstructions(item)"
                        class="crossButton">&times;</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- COM-6 -->

      </div>
    </mat-step>



  </mat-horizontal-stepper>
  <div
  style="position:absolute;top:59px;right: 26px; display: flex;justify-content: flex-end; align-items: center; height: 20px; ">
  <!-- (click)="previewPrescriptionPDF()"  -->
  <div class="btn-type-filled btnUi" (click)="vitalsSaveOnly()"
    style="background-color: #5D86CC; margin-top: 70px; font-size: 18px; text-align: center; margin-right: 10px;">
    Save</div>
  <div (click)="saveVitals();dialog.open(followup_modal);getLangTextBoxData();" class="btn-type-filled btnUi"
    style="background-color: #5D86CC; margin-top: 70px; font-size: 18px; text-align: center; margin-right: 29px;">
    Proceed</div>
</div>

  <!-- <div
    style="position:absolute;top:59px;right: 26px; display: flex;justify-content: flex-end; align-items: center; height: 20px; ">
  
  <button [disabled]="saveButtonDisabledFlag" (click)="vitalsSaveOnly();"class="btn-type-filled btnUi" style="background-color: #5D86CC; margin-top: 70px; font-size: 18px; text-align: center; margin-right: 10px;" >
    Save
  </button>

    <div (click)="saveVitals();dialog.open(followup_modal);getLangTextBoxData();" class="btn-type-filled btnUi"
      style="background-color: #5D86CC; margin-top: 70px; font-size: 18px; text-align: center; margin-right: 29px;">
      Proceed</div>
  </div> -->

  <div style="width: 50%; height: 92vh; position: absolute; top: 40; right: 0; bottom: 0; overflow: hidden;">
    <app-prescription-live-preview #prescritionpreviewcomponent selectedComplaints="{{selectedComplaints.join(', ')}}"
      selectedDiagnosis="{{selectedDiagnosis.join(' , ')}}" [allMedicines]="allMedicines"
      selectedOnExamination="{{selectedOnExamination.join(', ')}}"
      selectedDietaryAdvices="{{selectedDietaryAdvices.join(', ')}}"
      selectedGeneralInstructions="{{selectedGeneralInstructions.join(', ')}}" [changedHtWt]="changedHtWt"
      [editMode]="true" (onDeleteMedicineFromPreview)="deleteMedicineFromPreview($event)">
    </app-prescription-live-preview>
  </div>


  <ng-template #previous_medicines_modal let-modal>
    <div class="modal-header" style="border-bottom: 0px; width: 50vw;">
      <h4 class="modal-title" id="modal-basic-title">Previously prescribed medicines</h4>
    </div>
    <div class="modal-body" style="padding: 0px 8px;">
      <form>
        <div class="form-group">
          <div style="flex-direction: column;" class="input-group">

            <mat-form-field id="fourth" style="width: 100%; margin: 0px 6px;">
              <mat-label>Filter Previous Prescriptions</mat-label>
              <mat-select placeholder="{{selectedPreviousMedDate}}" (selectionChange)="onDropdownSelect($event)"
                name="ll" #select>
                <mat-select-trigger>
                  {{select.value?.value}}
                </mat-select-trigger>
                <mat-option *ngFor="let item of previousMedsArr" [value]="item" style=" line-height: normal;">

                  <!-- <div style="display: flex; flex-direction: column;"> -->
                  <div>
                    <div style="font-size: 17px; color: grey;">

                      <span style="font-weight: bold; color: #333;">{{item.value}} </span>(
                      <!-- Complaints: {{item.complaints ? item.complaints : 'NA'}}, &nbsp;&nbsp;&nbsp;&nbsp; -->
                      Diagnosis: {{item.diagnosis ? item.diagnosis : 'NA'}})
                    </div>
                  </div>
                  <!-- </div> -->

                </mat-option>
              </mat-select>

            </mat-form-field>

            <div style="height: 50vh; overflow: scroll;">
              <div *ngFor="let element of selectedPreviousMed; let i = index"
                style=" display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin: 10px 0px 10px 0px;">
                <!-- <div class="newCardStyle"
                  [ngStyle]="{'border': element.editing ? '1px solid #F08439' : '1px solid #DFDFDF', 'background-color':element.selected ? '#e4f8f5' : '#fff'}">
                  <div style="color: #333; font-weight: bold; font-size: 18px;">{{i+1}}.
                    {{element.Drug.product_name}}
                  </div>
                  <div style="color: grey; font-size: 16px;"> {{element.dosage2}}</div>
                </div> -->

                <div class="newCardStyle"
                  [ngStyle]="{'border': element.editing ? '1px solid #F08439' : '1px solid #DFDFDF', 'background-color':element.selected ? '#e4f8f5' : '#fff'}">

                  <div (click)="onSelectMedicine(i, 4)" *ngIf="!element.selected"
                    style="border: 1px solid #ccc; background-color: #fff; border-radius: 8px; margin-left: 8px; cursor: pointer; height: 30px; width: 30px;"
                    class="largerCheckbox"></div>

                  <img (click)="onSelectMedicine(i, 4)" *ngIf="element.selected"
                    style="width:26px; color:#007d7e; margin-left: 8px; cursor: pointer;"
                    src="assets/images/checkbox_material.svg" class="largerCheckbox" />

                  <div style="width: 86%;">
                    <div [ngClass]="(false) ?  'titleActive' :'titleInactive'">{{element.Drug.product_name}}</div>
                    <div id="something" name="something" *ngIf="!element.editing"
                      [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'">
                      {{element.dosage2}} {{element.duration2}}</div>
                    <div id="something" name="something" *ngIf="!element.editing"
                      [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'" style="color: grey; font-size: 13px;">
                      {{element.instructions}}</div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div style="display: flex;flex-direction: row;justify-content: space-between;">
      <div *ngIf="!pdfUrlForViewPrescription"
        style="padding-top: 20px; color: #e9eaf0; font-size: 16px;font-weight: 600; text-decoration: underline;">
        <span style="cursor: pointer;"></span>
      </div>
      <div *ngIf="pdfUrlForViewPrescription" (click)="onClickViewPrescription()"
        style="padding-top: 20px; color: #6975d7; font-size: 16px;font-weight: 600; text-decoration: underline;">
        <span style="cursor: pointer;"> View Previous Pdf</span>
      </div>
      <div style="display: flex;justify-content: flex-end;">
        <button (click)="closeDialog()" class="btnUiForCancel">Cancel</button>
        <button (click)="saveModalValues('copyMedicines');" class="btnUiForCopyMed">Copy Selected Medicines</button>
      </div>
    </div>

  </ng-template>

  <ng-template #delete_modal>
    <h4>Delete Favourite?</h4>
    <div style="color: #555; font-size: 16px;">Are you sure you want to delete this favourite collection?</div>
    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
      <button (click)="closeDialog()" style="background-color: #eee;
                  padding: 8px 16px;
                  border-radius: 6px;
                  color: #fff;
                  font-weight: bold;
                  border: 0px;
                  margin: 4px;
                  color: #444;
                  outline: none;">Cancel</button>

      <button (click)="onClickDeleteFavMed()" style="background-color: #f44336;
              padding: 8px 16px;
              border-radius: 6px;
              color: #fff;
              font-weight: bold;
              border: 0px;
              margin: 4px;
            outline: none;">Yes, Delete</button>
    </div>
  </ng-template>


  <ng-template #incomplete_data_modal>
    <h4>Mandatory Fields</h4>
    <div style="color: #555; font-size: 16px;">Please fill all the required fields to save medicine.</div>
    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
      <button (click)="closeDialog()" style="background-color: #eee;
                  padding: 8px 16px;
                  border-radius: 6px;
                  color: #fff;
                  font-weight: bold;
                  border: 0px;
                  margin: 8px;
                  color: #444;
                  outline: none;">Ok</button>
    </div>
  </ng-template>


  <!-- Popularly Used Medicines Modal -->
  <ng-template #popular_medicines let-modal>
    <div class="modal-header" style="border-bottom: 0px; width: 100% !important;">
      <h4 class="modal-title" id="modal-basic-title">Popularly Used Medicines</h4>
      <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
        (click)="closeDialog()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <div style="flex-direction: column;" class="input-group">
            <div style="margin-top: -20px; display: flex; flex-direction: column; width: 100%;">
              <input placeholder="Search Medicines From List" type="text" aria-label="Number" matInput
                [formControl]="myControlFav" [(ngModel)]="searchMed" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px; width: 100%;
                font-size: 16px; z-index: 0;">

            </div>
            <div style="height: fit-content; overflow: scroll; padding-left: 7px; margin-top: 15px; width: 50vw; ">

              <mat-chip-list class="chipForDig" aria-label="Fish selection">
                <mat-chip class="price-filter" #ref="matChip"
                  *ngFor="let item of diagnosisCollectionArray | filter:searchMed;let i=index" (click)="toggle(ref)"
                  [value]="item" [selected]="item.selected==true">
                  {{item.name}}
                </mat-chip>

              </mat-chip-list>

              <mat-chip-list aria-label="Fish selection">
                <mat-chip class="price-filter" style="margin-top: 10px;" #ref="matChip"
                  *ngFor="let item of drugTypeList | filter:searchMed;let i=index" (click)="toggle(ref)"
                  [selected]="item.selected==true" [value]="item">
                  {{item.name}}
                </mat-chip>

              </mat-chip-list>
            </div>

            <div style="height: 42vh; overflow: scroll; margin-top: 20px;">
              <div *ngFor="let item of allMedicinesForDiagnosis | filter:searchMed; let i = index"
                (click)="selectMedicine(item, i)"
                style="display: flex; flex-direction: column; width: 100%; align-items: center; ">

                <div style="width: 700px; height: fit-content; padding-top: 5px; padding-bottom: 5px; border-radius: 8px;position: relative;
                 display: flex; align-items: center; justify-content: space-around; margin-bottom: 6px;"
                  [ngStyle]="{'border': item.editing ? '1px solid #F08439' : '1px solid #DFDFDF', 'background-color':item.selected ? '#e4f8f5' : '#fff'}">

                  <div (click)="onSelectMedicine(item.idx, 2)" *ngIf="!item.selected"
                    style="border: 1px solid #ccc; background-color: #fff; border-radius: 8px; margin-left: 8px; cursor: pointer; height: 30px; width: 30px;"
                    class="largerCheckbox"></div>

                  <img (click)="onSelectMedicine(item.idx, 2)" *ngIf="item.selected"
                    style="width:26px; color:#007d7e; margin-left: 8px; cursor: pointer;"
                    src="assets/images/checkbox_material.svg" class="largerCheckbox" />

                  <div style="width: 86%;">
                    <div [ngClass]="(false) ?  'titleActive' :'titleInactive'">{{item.name}}</div>
                    <div id="something" name="something" *ngIf="!item.editing"
                      [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'">
                      {{item.dosage2}} {{item.duration2}}</div>
                    <div id="something" name="something" *ngIf="!item.editing"
                      [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'" style="color: grey; font-size: 13px;">
                      {{item.instructions}}</div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div style="display: flex; justify-content: space-between; width: 100%;">

        <button *ngIf="!selectedFavMed.length" style="background-color: #f44336; visibility: hidden;
                    padding: 8px 16px;
                    border-radius: 6px;
                    color: #fff;
                    font-weight: bold;
                    border: 0px;
                    font-size: 18px;
                    margin: 8px;
                    outline: none;">Delete</button>

        <div style="padding-top: 16px; display: flex; justify-content: flex-end;">

          <button (click)="closeDialog()" style="background-color: #eee;
          padding: 8px 16px;
          border-radius: 6px;
          color: #fff;
          font-weight: bold;
          border: 0px;
          margin: 8px;
          font-size: 18px;
          color: #444;
          outline: none;">Cancel</button>

          <button (click)="saveModalValues('copyPopularMedicines');" style="background-color: #5D86CC;
                padding: 8px 16px;
                border-radius: 6px;
                color: #fff;
                font-weight: bold;
                border: 0px;
                font-size: 18px;
                margin: 8px 0px;
              outline: none;">Copy Selected Medicines</button>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- Favourite Medicines Modal -->
  <ng-template #previous_medicines_fav let-modal>
    <div class="modal-header" style="border-bottom: 0px; width: 50vw;">
      <h4 class="modal-title" id="modal-basic-title">Favourite Medicines</h4>
      <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
        (click)="closeDialog()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <div style="flex-direction: column;" class="input-group">
            <div *ngIf="selectedDiagnosis.length" style="color: grey; margin-top: -20px; margin-bottom: 10px;">Search by
              diagnosis:
              <span *ngFor="let diagnosis of selectedDiagnosis" (click)="selectDiagnosisFavModal(diagnosis)"
                style="padding:4px 8px; cursor: pointer; font-size: 12px; background-color: #e4f8f5; border: 1px solid #71bebc; color: #047A7B; border-radius: 100px; margin-left: 6px;">{{diagnosis}}</span>
            </div>

            <div style="margin-top: 0px; display: flex; flex-direction: column; width: 100%;">
              <input #searchFavourites placeholder="Choose Favourites" id="favinputtextbox" type="text"
                aria-label="Number" matInput [matAutocomplete]="auto" [formControl]="myControlFav"
                [(ngModel)]="selectedPreviousFavMed" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px; width: 100%;
                font-size: 16px; z-index: 0;">
              <mat-autocomplete (optionSelected)='onDropdownSelectFavMed($event.option.value)' autoActiveFirstOption
                #auto="matAutocomplete">
                <mat-option *ngFor="let option of favArrayNames | async" [value]="option"
                  [ngClass]="(option.selectedComplaints=='New Born'||option.selectedComplaints=='Well Child') ?  'activeMat' :'UnactiveMat'">
                  <span>
                    <span style="font-weight: bold; color: #333;">{{option.value}} </span><span style="color: grey;"
                      *ngIf="option.selectedDiagnosis.length">({{option.selectedDiagnosis}})

                    </span>
                  </span>

                </mat-option>
              </mat-autocomplete>
            </div>
            <div style="height: 50vh; overflow: scroll;">
              <div *ngFor="let element of selectedFavMed; let i = index"
                style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin: 10px 0px 10px 0px;">
                <!-- <div>
                  <div style="color: #333; font-weight: bold; font-size: 18px;">{{i+1}}.
                    {{element.Drug.product_name}}
                  </div>
                  <div style="color: grey; font-size: 16px;"> {{element.dosage2}}</div>
                </div> -->
                <div class="newCardStyle"
                  [ngStyle]="{'border': element.editing ? '1px solid #F08439' : '1px solid #DFDFDF', 'background-color':element.selected ? '#e4f8f5' : '#fff'}">

                  <div (click)="onSelectMedicine(i, 3)" *ngIf="!element.selected"
                    style="border: 1px solid #ccc; background-color: #fff; border-radius: 8px; margin-left: 8px; cursor: pointer; height: 30px; width: 30px;"
                    class="largerCheckbox"></div>

                  <img (click)="onSelectMedicine(i, 3)" *ngIf="element.selected"
                    style="width:26px; color:#007d7e; margin-left: 8px; cursor: pointer;"
                    src="assets/images/checkbox_material.svg" class="largerCheckbox" />

                  <div style="width: 86%;">
                    <div [ngClass]="(false) ?  'titleActive' :'titleInactive'">{{element.Drug.product_name}}</div>
                    <div id="something" name="something" *ngIf="!element.editing"
                      [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'">
                      {{element.dosage2}} {{element.duration2}}</div>
                    <div id="something" name="something" *ngIf="!element.editing"
                      [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'" style="color: grey; font-size: 13px;">
                      {{element.instructions}}</div>

                  </div>
                </div>



              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="modal-footer">
      <div style="display: flex; justify-content: space-between; width: 100%;">
        <a (click)="onClickDeleteFavMed(favArray);" style="
        color: #f44336;
                    padding: 8px 0px;
                    
                    font-weight: bold;
                    margin: 8px;
                    "> ⚠️ Delete this favourite?</a>

        <button *ngIf="!selectedFavMed.length" style="background-color: #f44336; visibility: hidden;
                    padding: 8px 16px;
                    border-radius: 6px;
                    color: #fff;
                    font-weight: bold;
                    border: 0px;
                    margin: 8px;
                    outline: none;">Delete</button>
        <div style="padding-top: 16px; display: flex; justify-content: flex-end;">

          <button (click)="closeDialog()" style="background-color: #eee;
                  padding: 8px 16px;
                  border-radius: 6px;
                  color: #fff;
                  font-weight: bold;
                  border: 0px;
                  margin: 8px;
                  font-size: 18px;
                  color: #444;
                  outline: none;">Cancel</button>

          <button (click)="saveModalValues('copyMedicinesFav');" style="background-color: #5D86CC;
                padding: 8px 16px;
                border-radius: 6px;
                color: #fff;
                font-weight: bold;
                border: 0px;
                font-size: 18px;
                margin: 8px 0px;
              outline: none;">Copy Selected Medicines </button>
        </div>
      </div>

    </div>
  </ng-template>

  <!-- Previous Medicine Modal -->
  <ng-template #showPrescription_modalPer let-modal>


    <div style="font-size:20px;font-weight: 600;">Create Prescription</div>

    <hr>


    <div style="display: flex;flex-direction: column;">





      <mat-radio-group aria-label="Select an option"
        style="margin: 0px 0px; display: flex;flex-direction: column; width: 340px;"
        *ngFor="let item of createPrescription;let i=index" (click)="selectPrescriptionValue(i)">
        <div [ngClass]="(item.selected) ?  'highlightedBoxPer' :'UnhighlightedBoxPer'">
          <div style="display: flex;flex-direction: column;">
            <mat-radio-button value="true" [checked]="item.selected" style="font-size: 16px;">&nbsp;{{item.name}}

            </mat-radio-button>
            <div>
              <div *ngIf="i == 0"
                style="color:gray;font-weight: normal; font-size: 14px; align-items: center; position: absolute;">
                Sure about diagnosis, want to refer</div>
            </div>


          </div>

        </div>

        <!-- <div style=" margin-bottom: 12px;
      padding-bottom: 0px;
      padding-top: 12px;
      padding-left: 12px;">
        <mat-radio-button value="2">&nbsp;Custom Prescription</mat-radio-button>
      </div> -->

      </mat-radio-group>

      <mat-form-field id="fourth" style="width: 100%; margin: 0px 6px;" *ngIf="customPrescription">
        <!-- <mat-label>Select Custom Prescription</mat-label> -->
        <mat-select placeholder="{{titleOfSelectPer}}" [formControl]="customPer" requireds [value]="dose" required
          (selectionChange)="onDropdownSelectFavMedicien($event.value)">
          <mat-option *ngFor="let item of favArray" [value]="item">{{item.value}}</mat-option>
        </mat-select>
        <mat-error *ngIf="customPer.errors?.required"></mat-error>
      </mat-form-field>

      <mat-form-field id="fourth" style="width: 100%; margin: 0px 6px;" *ngIf="previouslyMed">
        <!-- <mat-label>Select Custom Prescription</mat-label> -->
        <mat-select placeholder="{{titleOfpreviouslyMed}}" [formControl]="customPer" requireds [value]="dose" required
          (selectionChange)="onDropdownSelectPreviousmed($event.value)">
          <mat-option *ngFor="let item of previousMedsArr" [value]="item">{{item.value}}</mat-option>
        </mat-select>
        <mat-error *ngIf="customPer.errors?.required"></mat-error>
      </mat-form-field>



    </div>

    <!-- <div style="color: red; padding-top: 16px;">Fields marked and highlighted are compulsory*</div> -->

    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
      <button (click)="closeDialog()" style="background-color: #eee;
                  padding: 8px 16px;
                  border-radius: 6px;
                  color: #fff;
                  font-weight: bold;
                  border: 0px;
                  margin: 8px;
                  color: #444;
                  outline: none;">Cancel</button>

      <button (click)="openPrescriptions();" style="background-color:#5D86CC;
        padding: 8px 16px;
        border-radius: 6px;
        color: #fff;
        font-weight: bold;
        border: 0px;
        margin: 8px 0px;
      outline: none;">Select</button>

    </div>
  </ng-template>

  <!-- Edit Prescription Modal -->
  <!-- <ng-template #edit_prescription_modal let-modal style="width: 55vw;">
    <div class="modal-header" style="border-bottom: 0px; padding: 0px; align-items: center; width: 55vw;">
      <h4 class="modal-title" id="modal-basic-title">Edit Prescription</h4>
      <div>
        <button (click)="closeDialog()" style="background-color: #fff; 
                    padding: 8px 16px;
                    border-radius: 6px;
                    color: #fff;
                    font-weight: bold;
                    border: 1px solid #f44336;
                    color: #f44336;
                    margin: 8px;
                    outline: none;">Cancel</button>

        <button (click)="updatePrescription()" style="background-color: #5D86CC;
                padding: 8px 16px;
                border-radius: 6px;
                color: #fff;
                font-weight: bold;
                border: 0px;
                margin: 8px 0px;
              outline: none;">Save Changes</button>
      </div>
    </div>
    
  </ng-template> -->

  <!-- Add New Drug Modal -->
  <ng-template #add_new_drug_modal let-modal>
    <div class="modal-header" style="border-bottom: 0px; padding: 0px;">
      <div style="display: flex; flex-direction: column;">
        <h4 class="modal-title" id="modal-basic-title">Add New Drug</h4>
        <!-- <div style="color: #555; font-size: 16px; width: 500px;">The medicine name you have selected is not found in our
          system, you may
          need to add this medicine once in our system.</div> -->
        <!-- <div *ngIf="medicineName.length > 2" style="display: flex;
                flex-direction: row;
                background-color: #FFF3CD;
                border: 1px solid #FEEEBA;
                border-radius: 8px;
                padding: 10px;
                margin-top: 16px;
                width: 50vw;
                ">
          <div style=" width:80px; border-radius: 8px; display: flex; justify-content: center; align-items: center;">
            <img src="assets/images/warning_icon_new.svg" alt="" />
          </div>

          <div style="margin-left: 8px;">
            <div style="color: #8B6B14; font-weight: bolder; font-size: 18px;">Medicine not found</div>
            <div style="color: #8B6B14; font-size: 16px; line-height: 16px; margin-top: -4px;">The medicine name you
              have selected is not found in our system, you may need to add this medicine once in our system.</div>
          </div>
        </div> -->
        <app-alertbox *ngIf="medicineName.length > 2" [title]="'Medicine not found'"
          [subtitle]="'The medicine name you have selected is not found in our system, you may need to add this medicine once in our system.'"
          [type]="'Warning'"></app-alertbox>

      </div>
      <!--       
      <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
        (click)="dialog.closeAll()">
        <span aria-hidden="true">&times;</span>
      </button> -->
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <div style="flex-direction: column;" class="input-group">

            <div style="flex-direction: row; display: flex; width: 100%;">
              <div style="width: 50%;">
                <div style="color: grey;">Drug Name</div>
                <input [(ngModel)]="drugNameAddNewDrugs" id="searchbox"
                  (input)="onSearchChange($event.target.value, 'drugTypes')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px; 
              font-size: 16px; width: 97%;" class="form-control" placeholder="Enter Drug Name" name="drugName">
              </div>

              <div style="width: 50%;">
                <div style="color: grey;">Drug Type</div>
                <div>
                  <input placeholder="Enter Drug Type" type="text" aria-label="Number" matInput [matAutocomplete]="auto"
                    [formControl]="myControl" [(ngModel)]="drugTypeAddNewDrugs" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px; width: 91%;
                font-size: 16px; z-index: 0;">
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                    (optionSelected)="dropdownSelect('drugType',$event.option.value)">
                    <mat-option *ngFor="let option of drugTypes | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </div>
              </div>
            </div>

            <div style="flex-direction: row; display: flex; width: 100%; margin-top: 16px;">
              <div style="width: 50%;">
                <div style="color: grey;">Generic Name (Drug Composition)</div>
                <input [(ngModel)]="genericDrugNameAddNewDrugs" id="generic"
                  (input)="onSearchChange($event.target.value, 'drugTypes')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px;
              font-size: 16px; width: 97%;" class="form-control" placeholder="Enter Drug Generic Name"
                  name="genericName">
              </div>

              <div style="width: 50%;">
                <div style="color: grey;">Drug Company</div>
                <div>
                  <input [(ngModel)]="drugCompanyAddNewDrugs" id="drugCompany"
                    (input)="onSearchChange($event.target.value, 'drugTypes')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px; width: 97%;
                font-size: 16px; z-index: 0;" class="form-control" placeholder="Enter name of Drug Company"
                    name="drugCompany">
                </div>
              </div>
            </div>


          </div>

          <h5 class="modal-title" style="margin-top: 20px;" id="modal-basic-title">Set custom dosage for drug</h5>
          <div style="background-color: #F7F6F9; border-radius: 6px;">
            <div>
              <!-- <mat-form-field style="width: 52px; margin: 0px 6px;">
                <mat-label>Units</mat-label>
                <mat-select placeholder="{{Tounit}}" [formControl]="UnitFieldNewMed" [(value)]="unitForNewMed"
                  (selectionChange)="dropdownSelect('unitNewMed', $event.value)">
                  <mat-option *ngFor="let unit of units" [value]="unit">
                    {{unit}}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->

              <input class="unitInput" type="number" min="0" (input)="onUnitChange($event.target.value)"
                [(ngModel)]="unitForNewMed" name="unitfornewmed" style="border: 1px solid #949494;
                  border-radius: 6px;
                  height: 40px;
                  width: 60px;
                  font-size: 18px;
                  color: #333333;
                  font-weight: bold;
                  padding: 6px;" />

              <span style="padding: 6px;
              margin-top: 0px;
              font-size: 16px;
              width: 50px;
              font-weight: 500;
              color: #333;">{{drugTypeUnit}}</span>

              <mat-form-field style="width: 300px; margin: 0px 6px;">
                <!-- <mat-label>Frequency</mat-label> -->
                <mat-select placeholder="{{toFrequency}}" [formControl]="FrequencyFieldNewMed"
                  [value]="frequencyForNewMed" multiple
                  (selectionChange)="dropdownSelect('frequencyNewMed',$event.value)">
                  <mat-option *ngFor="let item of frequency" [value]="item">{{item}}

                  </mat-option>
                </mat-select>
              </mat-form-field>


              <mat-form-field id="third" style="width: 90px; margin: 0px 6px;">
                <!-- <mat-label>Duration</mat-label> -->
                <mat-select placeholder="{{Duration}}" [formControl]="DurationFieldNewMed" [value]="durationForNewMed"
                  (selectionChange)="dropdownSelect('durationNewMed',$event.value)">
                  <mat-option *ngFor="let day of duration" [value]="day">
                    {{day}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field id="fourth" style="width: 110px; margin: 0px 6px;">
                <!-- <mat-label>When to take</mat-label> -->
                <mat-select placeholder="{{Whentotake}}" [formControl]="WhentotakeFieldNewMed"
                  [value]="whenToTakeForNewMed" (selectionChange)="dropdownSelect('whenToTakeNewMed',$event.value)">
                  <mat-option *ngFor="let item of whenToTake" [value]="item">{{item}}</mat-option>
                </mat-select>
              </mat-form-field>

            </div>

            <input type="text" class="form-control" [(ngModel)]="instructionsForNewMed"
              (input)="updateNewMedInstructions($event.target.value)" placeholder="Instructions" style="
              width: 100%;
              border-radius: 0px;
              outline: none;    
              font-size: 16px;
              border:0px;
              padding-left: 16px;" id="fname" name="fname">



          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">

      <button (click)="closeDialog()" style="background-color: #eee;
      padding: 8px 16px;
      border-radius: 6px;
      color: #fff;
      font-weight: bold;
      font-size: 16px;
      border: 0px;
      margin: 8px 0px;
      color: #444;
      outline: none;">Cancel</button>

      <div (click)="saveModalValues('drugTypesAddNewDrug'); dialog.closeAll()" class="btn-type-filled"
        style="background-color:#5D86CC; font-size: 16px;">Add New Drug</div>
    </div>
  </ng-template>

  <!-- Medicine Instructions Modal -->
  <ng-template #medicine_instructions_modal let-modal style="width: 55vw;">
    <div class="modal-header" style="border-bottom: 0px;">
      <h4 class="modal-title" id="modal-basic-title">Instructions</h4>
      <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
        (click)="closeDialog()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <div class="input-group">
            <input id="searchbox" (input)="onSearchChange($event.target.value, 'instructions')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: -20px;
          font-size: 16px;" class="form-control" placeholder="Start typing to search" name="dp">
          </div>
          <div style="height: 80vh; overflow: scroll;">
            <div
              style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw; ">

              <div *ngFor="let item of allMedicineInstructions; let i = index" (click)="selectModalValue(i)"
                class="searchable-component-style" [ngStyle]="{'background-color': item.selected ? '#E5F6F4' : '#fff'}">
                <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px; height: 18px; width: 18px;"
                  src="assets/images/tick_mark_grey.svg" class="tick-icon" />

                <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px; height: 18px; width: 18px;"
                  src="assets/images/tick_mark_color.svg" class="tick-icon" />
                <div style="font-size: 16px;">{{item.name}}</div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div (click)="saveModalValues('medicine_instructions'); closeDialog()" class="btn-type-filled"
        style="background-color: #5D86CC; font-size: 15px;">Add Instructions</div>
    </div>
  </ng-template>

</div>


<ng-template #searchDrugResultFormatterTemplate let-r="result" let-t="term">
  <ngb-highlight style="font-weight: bold;" [result]="r.product_name.toUpperCase()" [term]="t"></ngb-highlight>
  <label *ngIf="r.product_composition && r.product_composition!=''" for="">&nbsp;( </label>
  <ngb-highlight *ngIf="r.product_composition && r.product_composition!=''" [result]="r.product_composition">
  </ngb-highlight>
  <label *ngIf="r.product_composition && r.product_composition!=''" for=""> )</label>

</ng-template>
<ng-template #viewPrescriptionModal let-modal>
  <div>
    <div class="modal-header" style="border-bottom: none;">
      <h4 class="modal-title" id="modal-basic-title">View Previous Prescription</h4>
      <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
        (click)="closeDialog();">
        <span aria-hidden="true" style="font-size: 40px;color: #000;">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div style="display: flex; height: 70vh; margin-top: 0px; width: 70vw;">
        <embed [attr.src]="prescriptionPdfURL" type="application/pdf" width="100%" height="100%">
      </div>
      <!-- <button type="submit" class=" opd-primary-btn btn-md w125 newBtnStyle"
              style="margin-left: 85%;margin-top: 5%;text-transform: capitalize;"
              (click)="onClickRequisitionShare(reportsURL);closeRequisitionModal()">Share</button> -->

    </div>

  </div>
</ng-template>


<p-toast position="top-right" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class=" p-flex p-flex-column"
      style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;top:67px ;">
      <div>
      </div>
      <div class=" p-text-center">
        <div style="font-size: 18px;
                  font-weight: bold;
                  padding: 12px 0px 0px 12px;
                  color: #034A70;">{{message.summary}}</div>
        <div style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
          {{message.detail}}</div>
      </div>
    </div>
  </ng-template>
</p-toast>


<ng-template #followup_modal>

  <div style="width: 52vw;">

    <div style="display: flex; flex-direction: row; justify-content: space-between; width: 52vw;">
      <div>
        <h4 style="color: #36404D;">Confirm Prescription</h4>
        <div style=" color: grey; font-size: 16px; margin-top: -6px;">Confirm changes before sharing prescription with
          the
          patient.</div>
      </div>

      <div *ngIf="!editFavName || patientVisitData.firstName=='favourite' && patientVisitData.lastName=='creation'">
        <button class="btn-back" (click)="toggleFavouriteBox()">
          <img style="height: 24px; width: 24px;" src="assets/images/favourite_heart_red.svg" />
          Add as Favourite</button>
      </div>
      <div *ngIf="editFavName && patientVisitData.firstName!='favourite' && patientVisitData.lastName!='creation'">
        <div style="font-size:14px; color: #4687BF;font-weight: 600;">Favourite Name : {{editFavName}}</div>
        <!-- <button class="btn-back" (click)="toggleFavouriteBox()">
          <img style="height: 24px; width: 24px;" src="assets/images/favourite_heart_red.svg" />
          Add as Favourite</button> -->
      </div>


    </div>

    <div style="padding-top: 10px; display: flex; flex-direction: column; ">

      <div>

        <div>
          <div style="margin-top: 3px; display: flex; flex-direction: row;">
            <input type="checkbox" [checked]="addFollowUpFlag" class="largerCheckbox" (change)="addFollowUp($event)">
            <div style="display: flex;flex-direction: row; font-size: 16px; font-weight: 600; margin-left: 8px;">
              Next Followup
            </div>
          </div>


          <div
            style="display: flex;flex-direction: column;  background-color: #ffffff; padding: 8px; border-radius: 8px;">
            <div style="display: flex;flex-direction: row; justify-content: space-between;">
              <mat-form-field style="width: 47%;">
                <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker"
                  (dateChange)="onDateChange($event)" [(ngModel)]="followupDateNew" placeholder="Choose a date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>

              </mat-form-field>


              <mat-form-field *ngIf="appointmentFlag==1" style="width: 47%; margin: 0px 6px;">
                <mat-label style="font-size: 16px;">Followup Types
                </mat-label>

                <mat-select [value]="followupType"
                  (selectionChange)="onDropdownSelectFollowUpTypes('item',$event.value)">
                  <mat-option style="font-size: 15px;" *ngFor="let item of  followupArray" [value]="item.name">
                    {{item.name}}
                  </mat-option>
                </mat-select>

              </mat-form-field>

              <mat-form-field *ngIf="appointmentFlag==2" style="width: 47%; margin: 0px 6px;">
                <mat-label style="font-size: 16px;">Followup Types
                </mat-label>
                <mat-select [value]="followupType" (selectionChange)="onDropdownSelectFollowUpForQ($event.value)">
                  <mat-option style="font-size: 15px;" *ngFor="let item of  followupArrayForQ" [value]="item.name">
                    {{item.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>



            <app-language-select-textbox #langFollowupMessage [width]="100" [styles]="{'width': '100%'}"
              styleForWidth="instructions" [callBack]="onInputCustomComponent" placeholder="Followup Message"
              style="padding-left: 0px; width: 100%;"></app-language-select-textbox>
            <div style="display: flex; align-items: center;">

              <mat-chip-list aria-label="Fish selection" [multiple]="true" style="margin-top:10px">
                <mat-chip class="price-filter" style="font-size: 16px; background-color: #E8F7F5;" #ref="matChip"
                  *ngFor="let item of followUpMessageForLanguage;let i=index" (click)='selectFollowUpMessage(item);'
                  [value]="item">
                  {{item.value}}
                </mat-chip>

              </mat-chip-list>

            </div>

          </div>

        </div>
        <div *ngIf="specialVaccination.length" style="margin-top: 10px; font-size: 16px; font-weight: 600;">Special Vaccination</div>
        <div  *ngIf="specialVaccination.length" style="display: flex;flex-direction: row;flex-wrap: wrap; width: 100%; margin: 15px 0px 0px 0px;">
          <mat-checkbox-group aria-labelledby="example-checkbox-group-label" class="example-section">
            <mat-checkbox class="example-margin" *ngFor="let section of specialVaccination" [value]="section" [checked]="section.administeredStatus"
              (change)="getspecialVaccination($event,section)">
              {{section.name}}</mat-checkbox>
          </mat-checkbox-group>
        </div>


        <div style="margin-top: 10px; font-size: 16px; font-weight: 600;">Customise Prescription</div>
        <div style="background-color: #ffffff; padding: 8px; border-radius: 8px; width: 100%;">

          <mat-form-field style="width: 100%;  margin-top: -18px;">
            <mat-select style="font-size: 16px;" [value]="selectedVitalsField"
              (selectionChange)="selectModalVitalFieldValue($event.value)" multiple>
              <mat-option *ngFor="let item of selectVitalsField" [value]="item.value">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field style="width: 100%; ">
            <mat-label style="font-size: 15px;">Selected Fields</mat-label>
            <mat-select [value]="selectFieldsArray" style="font-size: 16px;" multiple (openedChange)="openPanel($event)"
              (selectionChange)="selectModalFieldValue($event.value)">
              <mat-option style="font-size: 16px;" *ngFor="let item of selectFieldsArray" [value]="item">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>


          <div style="display: flex; flex-direction: row; justify-content: space-between;">
            <mat-form-field style="width: 47%;font-size: 16px;">
              <mat-label style="font-size: 15px;">Speciality/Location</mat-label>
              <mat-select [value]="templateName" (selectionChange)="onDropdownSelectTemplate('item',$event.value)">

                <mat-option style="font-size: 16px;" *ngFor="let item of selectedTemplate" [value]="item">
                  {{item}}
                </mat-option>
              </mat-select>

            </mat-form-field>

            <mat-form-field style="width: 47%;">
              <mat-label style="font-size: 15px;">Rx Language</mat-label>
              <mat-select style="font-size: 16px;" [value]="selectedLanguage.name"
                (selectionChange)="onDropdownSelectLanguage('language',$event.value)">
                <mat-option style="font-size: 16px;" *ngFor="let language of languageArray" [value]="language.name">
                  {{language.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <div style="display: flex; flex-direction: row; justify-content: space-between;">
            <div></div>
            <div style="color: #cb832d;
            font-weight: bold;
            font-style: italic;">*Patient's app preffered language is {{patientAppLanguage}}</div>
          </div>


        </div>

        <div *ngIf="favaddFavPrescriptionTextBox"
          style="background-color: #ffffff; padding: 8px; border-radius: 8px; border: 1px dashed gray; position: relative;">
          <div style="flex-direction: row; display: flex; align-items: center;">
            <img style="height: 30px; width: 30px;" src="assets/images/favourite_heart_red.svg" />

            <div style="margin-left: 8px;">
              <div style="font-size: 17px; font-weight: 600;">Do you want to add this prescription as
                Favourite ?
              </div>
              <div style="color: grey; font-size: 15px; margin-top: -4px;">(Specify name of the diagnosis e.g.
                Flu, Indigestion
                )
                and
                few words to mark it.</div>
            </div>
          </div>


          <div style="display: flex;flex-direction: row; align-items: center; padding: 0px;">
            <input style="padding: 8px; border: 1px solid #a4c2de; border-radius: 6px;" #favInput id="favInput" matInput
              [(ngModel)]="favApptName">

          </div>
        </div>

      </div>


      <div style="text-align: right;">
        <button (click)="closeDialog()" style="background-color: #eee;
                padding: 8px 16px;
                border-radius: 6px;
                color: #fff;
                font-weight: bold;
                border: 0px;
                margin: 4px;
                color: #444;
                font-size: 16px;
                outline: none;">Cancel</button>

        <button class="btn-confirm" (click)="previewPrescriptionPDF(1)" [disabled]="savePreviewButtonFlag" >Confirm and Print</button>
      </div>

    </div>

  </div>
</ng-template>

<ng-template #redirect_vaccination_modal>
  <h4>Create Vaccination Record.</h4>
  <div style="color: #555; font-size: 16px;">To create Vaccination Record of this visit</div>
  <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
    <button (click)="closeDialog()" style="background-color: #eee;
                padding: 8px 16px;
                border-radius: 6px;
                color: #fff;
                font-weight: bold;
                border: 0px;
                margin: 4px;
                color: #444;
                outline: none;">Cancel</button>

    <button (click)="gotoVaccination()" style="background-color: #5D86CC;
            padding: 8px 16px;
            border-radius: 6px;
            color: #fff;
            font-weight: bold;
            border: 0px;
            margin: 4px;
          outline: none;">Go to Vaccination</button>
  </div>
</ng-template>

<ng-template #pendingVaccination_modal>
  <div style="display: flex;flex-direction: row; justify-content: space-between;">
    <div style="font-size: 20px; font-weight: 600;">Select next vaccination due</div>
    <div>
      <mat-form-field>
        <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker"
          (dateChange)="onDateChangeForVacc($event)" [(ngModel)]="vacDate" placeholder="Choose a date" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>

      </mat-form-field>
    </div>
  </div>



  <hr *ngIf="pendingVaccination.length">
  <h5 *ngIf="pendingVaccination.length" style="color:#36404D">Pending Vaccinations</h5>
  <div *ngIf="pendingVaccination.length" style="height: 30vh; width: 55vw; overflow: scroll;">
    <div style="height:30vh; width: 55vw; overflow: scroll">
      <ng-container [ngTemplateOutlet]="renderImmunization" [ngTemplateOutletContext]="{value:pendingVaccination}">
      </ng-container>
    </div>
  </div>

  <ng-template #renderImmunization let-value="value">
    <table mat-table [dataSource]="value" style="width: 55vw; margin-top: 8px;">
      <ng-container>
        <!-- VISIT -->
        <ng-container matColumnDef="visit">
          <th mat-header-cell *matHeaderCellDef> Visit </th>
          <td mat-cell *matCellDef="let element" style="text-align: start !important; width:17%; font-weight: bold;">
            <mat-checkbox class="example-margin" (change)="getVaccData(element)"></mat-checkbox> {{element.visitName}}

          </td>
        </ng-container>

        <!-- VACCINE -->
        <ng-container matColumnDef="vaccine">
          <th mat-header-cell *matHeaderCellDef> Vaccine Name </th>
          <td mat-cell *matCellDef="let element" style="text-align: start !important; width: 17.5% !important;">
            {{element.name}}

          </td>
        </ng-container>

        <!-- RECOMMENDED DATE -->
        <ng-container matColumnDef="recommendedDate">
          <th mat-header-cell *matHeaderCellDef> Recommended Date </th>
          <td mat-cell *matCellDef="let element" style="width: 10% !important;">
            {{element.dueDate | date:"dd-MM-YYYY"}}

          </td>
        </ng-container>

        <!-- CATCHUP DATE -->
        <ng-container matColumnDef="catchupDate">
          <th mat-header-cell *matHeaderCellDef> Catchup Date </th>
          <td mat-cell *matCellDef="let element" style="width: 10% !important;">
            {{element.catchupDate}}
          </td>
        </ng-container>

        <!-- STATUS -->


        <!-- RECEIVED DATE -->


        <!-- BRAND -->


        <!-- ACTION   -->


      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true" style="background-color: #eee;"></tr>
      <tr mat-row [ngClass]="{'highlight': selectedRowIndex == row.id}" *matRowDef="let row; columns: displayedColumns;"
        (click)="selectedRows(row)"></tr>
    </table>
  </ng-template>




  <hr>
  <h5 style="color:#36404D">Upcoming Vaccinations</h5>


  <div style="height: 30vh; width: 55vw; overflow: scroll;">
    <div style="height: 30vh; width: 55vw;overflow: scroll;">
      <ng-container [ngTemplateOutlet]="renderImmunization" [ngTemplateOutletContext]="{value:upcomingVaccineList}">
      </ng-container>
    </div>
  </div>

  <ng-template #renderImmunization let-value="value">
    <table mat-table [dataSource]="value" style="width: 55vw; margin-top: 8px;">
      <ng-container>
        <!-- VISIT -->
        <ng-container matColumnDef="visit">
          <th mat-header-cell *matHeaderCellDef> Visit </th>
          <td mat-cell *matCellDef="let element" style="text-align: start !important; width:17%; font-weight: bold;">
            {{element.visitName}}

          </td>
        </ng-container>

        <!-- VACCINE -->
        <ng-container matColumnDef="vaccine">
          <th mat-header-cell *matHeaderCellDef> Vaccine Name </th>
          <td mat-cell *matCellDef="let element" style="text-align: start !important; width: 17.5% !important;"
            [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' : '#fff' }">
            {{element.name}}

          </td>
        </ng-container>

        <!-- RECOMMENDED DATE -->
        <ng-container matColumnDef="recommendedDate">
          <th mat-header-cell *matHeaderCellDef> Recommended Date </th>
          <td mat-cell *matCellDef="let element" style="width: 10% !important;"
            [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' : '#fff' }">
            {{element.dueDate | date:"dd-MM-YYYY"}}

          </td>
        </ng-container>

        <!-- CATCHUP DATE -->
        <ng-container matColumnDef="catchupDate">
          <th mat-header-cell *matHeaderCellDef> Catchup Date </th>
          <td mat-cell *matCellDef="let element" style="width: 10% !important;"
            [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' : '#fff' }">
            {{element.catchupDate}}
          </td>
        </ng-container>


        <!-- STATUS -->


        <!-- RECEIVED DATE -->


        <!-- BRAND -->


        <!-- ACTION   -->


      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true" style="background-color: #eee;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </ng-template>
  <!-- <div style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 55vw; height: 30vh; overflow: scroll;">
          <div *ngFor="let item of upcomingVaccineList; let i = index" (click)="selectModalValue(i)"
          class="searchable-component-style" [ngStyle]="{'background-color': item.selected ? '#E5F6F4' : '#fff'}">
              <img *ngIf="item.selected != true" style="margin: 0px 8px 0px 0px; height: 18px; width: 18px;"
              src="assets/images/tick_mark_grey.svg" class="tick-icon" />

            <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px; height: 18px; width: 18px;"
              src="assets/images/tick_mark_color.svg" class="tick-icon" />
            <div style="font-size: 16px;">{{item.name}}</div><br>
          
          </div>
          </div> -->
  <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
    <button (click)="closeDialog()" style="background-color: #eee;
      font-size: 16px;
                padding: 8px 16px;
                border-radius: 6px;
                color: #fff;
                font-weight: bold;
                border: 0px;
                margin: 4px;
                color: #444;
                outline: none;">Cancel</button>

    <button (click)="savePendingVaccination();closeDialog();" style="background-color: #5D86CC;
      font-size: 16px;
            padding: 8px 16px;
            border-radius: 6px;
            color: #fff;
            font-weight: bold;
            border: 0px;
            margin: 4px;
          outline: none;">Add Vaccinations</button>
  </div>
</ng-template>



<ng-template #delete_modalForPopularMed>
  <h4>Delete Medicine?</h4>
  <div style="color: #555; font-size: 16px;">Are you sure you want to delete selected medicine?</div>
  <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
      <button (click)="closeDialog()" style="background-color: #eee;
                padding: 8px 16px;
                border-radius: 6px;
                color: #fff;
                font-weight: bold;
                border: 0px;
                margin: 4px;
                color: #444;
                outline: none;">Cancel</button>

      <button (click)="deletePopularMedicine();closeDialog();" style="background-color: #f44336;
            padding: 8px 16px;
            border-radius: 6px;
            color: #fff;
            font-weight: bold;
            border: 0px;
            margin: 4px;
          outline: none;">Delete</button>
  </div>
</ng-template>